<template>
  <div>
    <v-card-title
      class="no-padding mb-2 font-weight-bold EoleBlue--text"
      style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
      >VALIDATION DES JOURS TRAVAILLÉS</v-card-title
    >
    <v-row class="mb-n10">
      <v-col cols="12" md="2">
        <v-text-field label="Collaborateur" v-model="userFilter"> </v-text-field>
      </v-col>
      <v-col cols="12" md="10" class="text-right mt-5">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon color="EoleBlue" @click="showInactive = !showInactive">
              <v-icon v-if="showInactive">mdi-eye-off</v-icon>
              <v-icon v-else>mdi-history</v-icon>
            </v-btn>
          </template>
          <span v-if="!showInactive"> Afficher les jours travaillés non validés</span>
          <span v-else> Afficher l'historique des jours travaillés </span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row v-if="collaboratorsValidation.length > 0 && !showInactive">
      <v-dialog v-model="dialogAction" right transition="slide-x-transition" close-on-content-click width="1300px">
        <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
          <v-card-text style="background-color: #f1f4f9">
            <v-row>
              <v-col cols="12" md="3" class="no-padding ml-n2">
                <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                  <v-card-title>
                    <v-img
                      :src="require('@/assets/images/logos/anavel_logo.png')"
                      max-height="150px"
                      max-width="120px"
                      alt="logo"
                      contain
                    ></v-img>
                  </v-card-title>
                  <v-card-title class="text-center justify-center muller-capitalized"
                    >Mes Services Assurance</v-card-title
                  >
                  <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                  <v-card-text class="no-padding">
                    <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class=""> </v-img>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="9" class="no-padding d-flex">
                <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                  <v-card-title class="muller-capitalized EoleBlue--text">
                    {{ selectedItem.lastname }} {{ selectedItem.firstname }} a des jours à valider ⚠️
                  </v-card-title>
                  <v-card-subtitle> Pour le mois de : {{ getMonth() }} </v-card-subtitle>
                  <v-card-text class="mt-5">
                    <v-textarea
                      v-model="selectedItem.manager_comment"
                      label="Commentaire manager"
                      outlined
                      dense
                    ></v-textarea>
                  </v-card-text>
                  <v-spacer> </v-spacer>
                  <v-card-actions class="text-center justify-center">
                    <v-btn
                      color="EoleError"
                      class="white--text"
                      @click="postRefuseAccountDaysWorkValidation(selectedItem)"
                      >Refuser</v-btn
                    >
                    <v-btn color="EoleGreen" class="white--text" @click="postDaysWorkValidation(selectedItem)"
                      >Accepter les jours travaillés</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-col cols="12" md="12">
        <v-simple-table>
          <thead>
            <tr>
              <th>Nom</th>
              <th v-for="(day, index) in daysOfLastMonth" :key="index">{{ day.format("DD MMM") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(collaborator, index) in collaboratorsValidationFilter(collaboratorsValidation)" :key="index">
              <td class="'no-padding'">{{ collaborator.lastname }} {{ collaborator.firstname }}</td>
              <td
                v-for="(day, index) in daysOfLastMonth"
                :key="index"
                :class="['custom-td-height', getLeaveClassLastMonth(collaborator, day)]"
              >
                <v-row>
                  <v-col
                    v-if="getLeaveClassLastMonth(collaborator, day) != 'working'"
                    :class="[
                      'white--text justify-center text-center',
                      getLeaveClassLastMonth(collaborator, day) ? 'mb-5' : 'mt-5',
                    ]"
                    cols="12"
                    md="12"
                    style="text-align: center; padding: 10px; border-radius: 5px"
                  >
                    <div
                      v-if="getHalfDayType(day, collaborator.id) === 0"
                      :class="['white--text']"
                      :style="getHalf(day, collaborator.id) ? 'position: relative; top: 10px' : null"
                    >
                      {{ getHolidayInformation(day, collaborator.id) }}
                    </div>
                    <div
                      v-if="getHalfDayType(day, collaborator.id) === 1"
                      :class="['white--text']"
                      :style="getHalf(day, collaborator.id) ? 'position: relative; top: 10px' : null"
                    >
                      {{ getHolidayInformation(day, collaborator.id) }}
                    </div>
                    <div
                      v-else-if="getHalfDayType(day, collaborator.id) === 2"
                      :class="['white--text']"
                      :style="getHalf(day, collaborator.id) ? 'position: relative; top: 50px' : null"
                    >
                      {{ getHolidayInformation(day, collaborator.id) }}
                    </div>
                  </v-col>
                  <v-col
                    v-else
                    :class="[
                      'EoleBlue--text justify-center text-center',
                      getLeaveClassLastMonth(collaborator, day) ? 'mb-0' : 'mt-0',
                    ]"
                    cols="12"
                    md="12"
                    style="text-align: center; padding: 10px; border-radius: 5px"
                  >
                    <div class="EoleBlue--text">JT</div>
                  </v-col>
                  <v-col
                    v-if="getHalf(day, collaborator.id)"
                    cols="12"
                    md="12"
                    :class="[getHalfDayClass(day, collaborator.id), 'EoleBlue--text']"
                    >JT</v-col
                  >

                  <!-- <v-col cols="12" md="5" v-else> {{ days }} </v-col> -->

                  <!-- <div v-if="getLeaveClass(collaborator, day)" class="white--text">{{ days }} jours</div> -->
                </v-row>
                <!-- <div v-if="getLeaveClass(collaborator, day)" class="white--text">
                    {{ days }} jours {{ getHolidayType(collaborator, day) }}
                  </div> -->
              </td>
              <td>
                <!-- {{ getWorkingId(collaborator.id) }} -->
                <v-btn icon v-bind="attrs" v-on="on" @click="openDialog(collaborator)">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
          <!-- ... -->
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" md="12">Aucun jours de travail dans l'historique</v-col>
    </v-row>
  </div>
</template>

<script>
import config from "@/views/config/config"

import moment from "moment"
import "moment-business-days"
import Holidays from "date-holidays"

const hd = new Holidays()
hd.init("FR")

moment.updateLocale("fr", {
  holidays: [
    "2023-01-01",
    "2023-04-17",
    "2023-05-01",
    "2023-05-08",
    "2023-05-25",
    "2023-06-05",
    "2023-07-14",
    "2023-08-15",
    "2023-11-01",
    "2023-11-11",
    "2023-12-25",
  ],
  holidayFormat: "YYYY-MM-DD",
  workingWeekdays: [1, 2, 3, 4, 5],
})

export default {
  name: "Calendar",
  props: {
    collaborators: {
      type: Array,
      required: true,
    },
    demand: {
      type: Array,
      required: true,
    },
    demandHistory: {
      type: Array,
      required: true,
    },
    collaboratorsValidation: {
      type: Array,
      required: true,
    },
    collaboratorsCompensatoryLeave: {
      type: Array,
      required: true,
    },
    collaboratorsRecoveryLeave: {
      type: Array,
      required: true,
    },
    agencyInformations: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      managerComment: "",
      selectedItem: {},
      dialogAction: false,
      userFilter: "",
      showInactive: false,
      collaboratorsInLeaveToday: 0,
      dialogCollaboratorsRecoveryLeave: false,
      dialogCollaboratorsCompensatoryLeave: false,
      dialogCollaboratorsValidation: false,
      editedDay: {},
      leavesLastMonth: [],
      currentYear: moment().year(),
      lastYear: moment().year() - 1,
      nextYear: moment().year() + 1,
      itemsHoliday: [
        { id: 1, type: "COP", label: "Congés payés" },

        { id: 4, type: "COEF", label: "Événement familial" },
        { id: 5, type: "MAL", label: "Maladie" },
        { id: 6, type: "CSS", label: "Congés sans solde" },
        { id: 7, type: "MP", label: "Maladie professionnelle" },
        { id: 8, type: "AT", label: "Accident du travail" },
        { id: 9, type: "MAT", label: "Maternité" },
        { id: 10, type: "PAT", label: "Paternité" },
        { id: 11, type: "ABNJ", label: "Absence non justifiée" },
      ],
      comments: "",
      dialogAction: false,
      headersHolidays: [
        {
          text: "Collaborateur",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Date de la demande",
          value: "date",
        },
        {
          text: "Période souhaitée",
          value: "period",
        },
        {
          text: "Type de congés",
          value: "type",
        },
        {
          text: "Solde à date",
          value: "days_remaining",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],
      agency: "",
      year: moment().year(), // Ajout de l'année actuelle
      totalNumberWork: 0,
      month: moment().month() + 1, // Mois actuel
      leaves: [], // Données des congés
      daysOfMonth: [], // Jours du mois
      isDataLoaded: false,
      selectedItem: {
        collaborator_comment: "",
        id: 0,
      },
      type: "",
      daysOfLastMonth: [],
      publicHolidays: [],
      lastMonthPublicHolidays: [],
      newCompensatoryLeaveBalance: 0,
      newRecoveryLeaveBalance: 0,
    }
  },
  computed: {
    displayMonth() {
      return moment()
        .month(this.month - 1)
        .format("MMMM")
    },
  },
  watch: {
    month() {
      this.updateCalendar()
    },
    collaborators: {
      deep: true,
      handler() {
        this.updateCalendar()
      },
    },
    collaboratorsValidation: {
      deep: true,
      handler() {
        this.updateCalendar()
      },
    },
  },
  methods: {
    getMonth() {
      return moment()
        .month(this.month - 1)
        .format("MMMM")
    },
    openDialog(item) {
      // Méthode pour ouvrir le v-dialog pour un élément donné
      this.selectedItem = item
      this.dialogAction = true
    },
    collaboratorsValidationFilter(collaborator) {
      return collaborator.filter(collaborator => {
        return (
          collaborator.lastname.toLowerCase().includes(this.userFilter.toLowerCase()) ||
          collaborator.firstname.toLowerCase().includes(this.userFilter.toLowerCase())
        )
      })
    },
    postNewRecoveryLeave(balance) {
      const idAgency = localStorage.getItem("agency_id")
      try {
        const formData = new FormData()

        formData.append("place", "setNewRecoveryLeaveBalance")
        formData.append("idAgency", idAgency)
        formData.append("balance", balance)

        fetch(`${config.apiUri}/agencies/${idAgency}/holidays`, {
          method: "POST",
          body: formData,
        }).then(response => {
          if (response.ok) {
            this.$toast.success(`Vous configuré le nombre de jours de RTT sur : ${balance}`, {
              position: "bottom-right",
            })

            this.$emit("update")

            this.$forceUpdate()

            this.dialogCollaboratorsRecoveryLeave = false
          } else {
            this.$toast.error("Erreur", {
              position: "bottom-right",
            })
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    verifyCollaboratorIsInLeaveToday() {
      const today = moment().format("YYYY-MM-DD")
      let length = 0

      this.leaves.forEach(leave => {
        if (moment(today).isBetween(leave.startDate, leave.endDate, "day", "[]") && leave.state === 3) {
          length++
          console.log(leave)
        }
      })

      return length
    },
    postNewCompensatoryLeave(balance) {
      const idAgency = localStorage.getItem("agency_id")
      try {
        const formData = new FormData()

        formData.append("place", "setNewCompensatoryLeaveBalance")
        formData.append("idAgency", idAgency)
        formData.append("balance", balance)

        fetch(`${config.apiUri}/agencies/${idAgency}/holidays`, {
          method: "POST",
          body: formData,
        }).then(response => {
          if (response.ok) {
            this.$toast.success(`Vous configuré le nombre de jours forfait sur : ${balance}`, {
              position: "bottom-right",
            })

            this.$emit("update")

            this.$forceUpdate()

            this.dialogCollaboratorsCompensatoryLeave = false
          } else {
            this.$toast.error("Erreur", {
              position: "bottom-right",
            })
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    getLastMonthPublicHolidaysFrance(year) {
      this.lastMonthPublicHolidays = []

      const frenchHolidays = hd.getHolidays(year)

      frenchHolidays.forEach(holiday => {
        holiday.date = moment(holiday.date).format("YYYY-MM-DD")

        this.lastMonthPublicHolidays.push(holiday.date)
      })
    },
    getPublicHolidaysFrance(year) {
      this.publicHolidays = []

      const frenchHolidays = hd.getHolidays(year)

      frenchHolidays.forEach(holiday => {
        holiday.date = moment(holiday.date).format("YYYY-MM-DD")

        this.publicHolidays.push(holiday.date)
      })
    },
    postRefuseAccountDaysWorkValidation(selectedItem) {
      this.getWorkingId(selectedItem.id)

      console.log(this.getWorkingId(selectedItem.id))

      const idAgency = localStorage.getItem("agency_id")
      try {
        const formData = new FormData()

        formData.append("place", "postRefuseDaysWorkValidation")
        formData.append("idAccount", selectedItem.id)
        formData.append("idWorkingDay", selectedItem.worked_days[0].id)
        formData.append("comment", this.selectedItem.manager_comment)

        fetch(`${config.apiUri}/agencies/${idAgency}/holidays`, {
          method: "POST",
          body: formData,
        }).then(response => {
          if (response.ok) {
            this.$toast.success("Vous avez modifié la demande de congés", {
              position: "bottom-right",
            })

            this.$emit("update")

            this.dialogCollaboratorsValidation = false
            this.dialogAction = false
          } else {
            this.$toast.error("Erreur", {
              position: "bottom-right",
            })
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    postDaysWorkValidation(selectedItem) {
      this.getWorkingId(selectedItem.id)

      console.log(this.getWorkingId(selectedItem.id))
      const idAgency = localStorage.getItem("agency_id")
      try {
        const formData = new FormData()

        formData.append("place", "postDaysWorkValidation")
        formData.append("idAccount", selectedItem.id)
        formData.append("idWorkingDay", selectedItem.worked_days[0].id)
        formData.append("comment", this.selectedItem.manager_comment)

        fetch(`${config.apiUri}/agencies/${idAgency}/holidays`, {
          method: "POST",
          body: formData,
        }).then(response => {
          if (response.ok) {
            this.$toast.success("Vous avez modifié la demande de congés", {
              position: "bottom-right",
            })

            this.$emit("update")

            this.dialogCollaboratorsValidation = false
            this.dialogAction = false
          } else {
            this.$toast.error("Erreur", {
              position: "bottom-right",
            })
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    getWorkingId(id) {
      const leave = this.leavesLastMonth.find(leave => leave.collaboratorId === id)

      this.editedDay = leave
    },
    getHalfDayType(day, collaboratorId) {
      const leave = this.leavesLastMonth.find(
        leave =>
          leave.collaboratorId === collaboratorId && moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      )

      if (!leave) return false

      return leave.halfDayType
    },
    getHolidayInformation(day, id) {
      // const leave = this.leavesLastMonth.find(leave =>
      //   moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      // )
      // if (!leave) {
      //   return "working"
      // }

      // return leave.type

      const leave = this.leavesLastMonth.find(
        leave => leave.collaboratorId === id && moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      )

      if (!leave) {
        return "working"
      }

      return leave.type
    },
    getHolidayTypeLastMonth(collaborator, day) {
      const leave = this.leavesLastMonth.find(
        leave =>
          leave.collaboratorId === collaborator.id &&
          moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      )

      return leave.type
    },
    getHolidayType(collaborator, day) {
      const leave = this.leaves.find(
        leave =>
          leave.collaboratorId === collaborator.id &&
          moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      )

      return leave.type
    },
    openDialog(item) {
      // Méthode pour ouvrir le v-dialog pour un élément donné
      this.selectedItem = item
      this.dialogAction = true
    },
    async putHoliday(id, answer) {
      const idAgency = localStorage.getItem("agency_id")
      try {
        const formData = new FormData()

        formData.append("idHoliday", id)
        answer === 2 ? formData.append("place", "refuse") : formData.append("place", "accept")
        formData.append("comments", this.comments)

        fetch(`${config.apiUri}/agencies/${idAgency}/holidays`, {
          method: "POST",
          body: formData,
        }).then(response => {
          if (response.ok) {
            this.$toast.success("Vous avez modifié la demande de congés", {
              position: "bottom-right",
            })

            this.$emit("update")

            this.dialogAction = false

            this.comments = ""

            this.collaboratorsInLeaveToday = this.verifyCollaboratorIsInLeaveToday()
          } else {
            this.$toast.error("Erreur", {
              position: "bottom-right",
            })
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    previousMonth() {
      if (this.month === 1) {
        // Si le mois actuel est janvier, passez à décembre de l'année précédente
        this.month = 12
        this.year -= 1
      } else {
        this.month -= 1
      }
      this.updateCalendar()
    },
    nextMonth() {
      if (this.month === 12) {
        // Si le mois actuel est décembre, passez à janvier de l'année suivante
        this.month = 1
        this.year += 1
      } else {
        this.month += 1
      }
      this.updateCalendar()
    },

    updateCalendar() {
      this.generateDaysOfMonth()
      this.processLeaves()
      this.isDataLoaded = true
    },
    generateDaysOfMonth() {
      this.getPublicHolidaysFrance(this.year)
      this.totalNumberWork = 0
      this.daysOfMonth = []
      let startOfMonth = moment([this.year, this.month - 1]).startOf("month")
      let endOfMonth = moment([this.year, this.month - 1]).endOf("month")

      while (startOfMonth.isSameOrBefore(endOfMonth)) {
        if (startOfMonth.isBusinessDay() && !this.publicHolidays.includes(startOfMonth.format("YYYY-MM-DD"))) {
          this.daysOfMonth.push(startOfMonth.clone())
          this.totalNumberWork++
        }
        startOfMonth.add(1, "days")
      }
    },
    generateDaysOfLastMonth() {
      this.getLastMonthPublicHolidaysFrance(this.year)
      this.daysOfLastMonth = []
      if (this.month === 1) {
        this.month = 12
        this.year -= 1
      } else {
        this.month -= 1
      }

      let lastMonth = this.month - 1
      console.log(lastMonth)
      let startOfMonth = moment([this.year, lastMonth]).startOf("month")
      let endOfMonth = moment([this.year, lastMonth]).endOf("month")

      while (startOfMonth.isSameOrBefore(endOfMonth)) {
        if (startOfMonth.isBusinessDay() && !this.lastMonthPublicHolidays.includes(startOfMonth.format("YYYY-MM-DD"))) {
          this.daysOfLastMonth.push(startOfMonth.clone())
        }
        startOfMonth.add(1, "days")
      }
    },
    processLeaves() {
      this.leaves = this.collaborators.flatMap(collaborator =>
        collaborator.holidays
          .filter(holiday => {
            let startDate = moment(holiday.start_date)
            let endDate = moment(holiday.end_date)

            return startDate.month() === this.month - 1 || endDate.month() === this.month - 1
          })
          .map(holiday => ({
            collaboratorId: collaborator.id,
            startDate: moment(holiday.start_date).format("YYYY-MM-DD"),
            endDate: moment(holiday.end_date).format("YYYY-MM-DD"),
            days: holiday.number_of_days,
            state: holiday.state,
            isHalfDay: holiday.is_half_day,
            halfDayType: holiday.half_day_type,
            type: holiday.type,
          })),
      )
    },
    processLeavesLastMonth() {
      this.leavesLastMonth = this.collaboratorsValidation.flatMap(collaborator =>
        collaborator.worked_days.flatMap(workedDay => {
          console.log(collaborator)

          console.log(workedDay)
          // Convertir la chaîne JSON en tableau d'objets
          let daysArray = workedDay.days

          // Filtrer et mapper les jours travaillés
          return daysArray
            .filter(holiday => {
              let startDate = moment(holiday.start_date)
              let endDate = moment(holiday.end_date)

              return startDate.month() === this.month - 1 || endDate.month() === this.month - 1
            })
            .map(holiday => ({
              collaboratorId: collaborator.id,
              startDate: moment(holiday.start_date).format("YYYY-MM-DD"),
              endDate: moment(holiday.end_date).format("YYYY-MM-DD"),
              days: holiday.number_of_days,
              state: holiday.state,
              isHalfDay: holiday.is_half_day,
              halfDayType: holiday.half_day_type,
              type: holiday.type,
              idWorkingDay: holiday.idWorkingDay,
            }))
        }),
      )
    },
    getHalfDayClass(day, collaboratorId) {
      const leave = this.leavesLastMonth.find(
        leave =>
          leave.collaboratorId === collaboratorId && moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      )

      if (!leave) return ""

      if (leave.halfDayType === 2) {
        return "morning"
      } else if (leave.halfDayType === 1) {
        return "afternoon"
      }

      return halfDayClass
    },
    getHalf(day, collaboratorId) {
      const leave = this.leavesLastMonth.find(
        leave =>
          leave.collaboratorId === collaboratorId && moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      )

      if (!leave) return false

      return leave.isHalfDay
    },
    getLeaveClassLastMonth(collaborator, day) {
      const leave = this.leavesLastMonth.find(
        leave =>
          leave.collaboratorId === collaborator.id &&
          moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      )

      if (leave) {
        this.days = leave.days

        // Si c'est un demi-jour le matin

        if (leave.isHalfDay === 1) {
          if (leave.halfDayType === 1) {
            if (leave.state === 3) {
              return "leave-half-morning-validated"
            } else {
              return "leave-half-morning-pending"
            }
          }

          // Si c'est un demi-jour l'après-midi
          if (leave.halfDayType === 2) {
            if (leave.state === 3) {
              return "leave-half-afternoon-validated"
            } else {
              return "leave-half-afternoon-pending"
            }
          }
        }

        // Sinon, retournez les classes normales en fonction de 'state'
        if (moment(day).isSame(leave.startDate, "day") && leave.state === 1) {
          return "leave-start-nok"
        }
        if (moment(day).isSame(leave.endDate, "day") && leave.state === 1) {
          return "leave-end-nok"
        }
        if (moment(day).isSame(leave.startDate, "day") && leave.state === 3) {
          return "leave-start"
        }
        if (moment(day).isSame(leave.endDate, "day") && leave.state === 3) {
          return "leave-end"
        }
        if (leave.state === 3) {
          return "leave-middle"
        }
        if (leave.state === 1) {
          return "leave-middle-nok"
        }
      }
      if (!leave) return "working"
    },

    getLeaveClass(collaborator, day) {
      const leave = this.leaves.find(
        leave =>
          leave.collaboratorId === collaborator.id &&
          moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      )

      if (leave) {
        this.days = leave.days

        // Si c'est un demi-jour le matin

        if (leave.isHalfDay === 1) {
          if (leave.halfDayType === 1) {
            if (leave.state === 3) {
              return "leave-half-morning-validated"
            } else {
              return "leave-half-morning-pending"
            }
          }

          // Si c'est un demi-jour l'après-midi
          if (leave.halfDayType === 2) {
            if (leave.state === 3) {
              return "leave-half-afternoon-validated"
            } else {
              return "leave-half-afternoon-pending"
            }
          }
        }

        // Sinon, retournez les classes normales en fonction de 'state'
        if (moment(day).isSame(leave.startDate, "day") && leave.state === 1) {
          return "leave-start-nok"
        }
        if (moment(day).isSame(leave.endDate, "day") && leave.state === 1) {
          return "leave-end-nok"
        }
        if (moment(day).isSame(leave.startDate, "day") && leave.state === 3) {
          return "leave-start"
        }
        if (moment(day).isSame(leave.endDate, "day") && leave.state === 3) {
          return "leave-end"
        }
        if (leave.state === 3) {
          return "leave-middle"
        }
        if (leave.state === 1) {
          return "leave-middle-nok"
        }
      }
      return ""
    },
  },

  created() {
    this.updateCalendar()

    this.agency = localStorage.getItem("agency")

    this.generateDaysOfLastMonth()
    this.processLeavesLastMonth()

    this.collaboratorsInLeaveToday = this.verifyCollaboratorIsInLeaveToday()
  },
}
</script>

<style>
.morning {
  position: relative;
  bottom: 50px;
}
.afternoon {
  position: relative;
  top: 0px;
  padding-top: 10px;
}
.custom-td-height {
  height: 72px !important;
  width: 50px !important;
}
/* Style pour la moitié supérieure d'une cellule de congé demi-journée le matin - Validé */
.leave-half-morning-validated {
  background: linear-gradient(to bottom, #001f47 50%, transparent 50%);
  content: "AM";
}

.leave-half-morning-validated .row {
  padding-bottom: 60px;
}

/* Style pour la moitié supérieure d'une cellule de congé demi-journée le matin - En attente */
.leave-half-morning-pending {
  background: linear-gradient(to bottom, #fcc03c 50%, transparent 50%);
  content: "AM";
}

.leave-half-morning-pending .row {
  padding-bottom: 0px;
}

/* Style pour la moitié inférieure d'une cellule de congé demi-journée l'après-midi - Validé */
.leave-half-afternoon-validated {
  background: linear-gradient(to top, #001f47 50%, transparent 50%);
}

.leave-half-afternoon-validated .row .col-12 {
  padding-top: 15px;
}

/* Style pour la moitié inférieure d'une cellule de congé demi-journée l'après-midi - En attente */
.leave-half-afternoon-pending {
  background: linear-gradient(to top, #fcc03c 50%, transparent 50%);
  content: "PM";
}

.leave-half-afternoon-pending .row {
  padding-top: 0px;
}

.leave-start-nok .row .col-12 {
  padding-top: 0px;
}

.leave-middle-nok .row .col-12 {
  padding-top: 0px;
}

.leave-end-nok .row .col-12 {
  padding-top: 0px;
}

.leave-start .row .col-12 {
  padding-top: 0px;
}

.leave-middle .row .col-12 {
  padding-top: 0px;
}

.leave-end .row .col-12 {
  padding-top: 0px;
}

.leave-half-morning-pending .row .col-12 {
  padding-top: 0px;
}

.leave-half-morning-validated .row .col-12 {
  padding-top: 0px;
}

.calendar {
  font-family: "Roboto", sans-serif;
  max-width: 100%;
  /* margin: auto; */
  /* overflow-x: auto; */
}

table {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
}

th,
td {
  border: 1px solid #e0e0e0;
  padding: 8px 16px;
  text-align: center;
}

thead th {
  background-color: #f5f5f5;
  color: #757575;
}

tbody tr:nth-child(odd) {
  background-color: #fafafa;
}

tbody tr:nth-child(even) {
  background-color: #ffffff;
}

.on-leave {
  color: #f44336; /* Couleur rouge pour les jours de congé */
}

th:first-child,
td:first-child {
  text-align: left;
}

.leave-start-nok,
.leave-middle-nok,
.leave-end-nok {
  background-color: #fcc03c; /* Couleur pour les congés */
}

.leave-start-nok {
  border-right: none; /* Supprime la bordure droite */
}

.leave-end-nok {
  border-left: none; /* Supprime la bordure gauche */
}

.leave-middle-nok {
  border-left: none; /* Supprime les bordures gauche et droite */
  border-right: none;
}

.leave-single-nok {
}

/* ... Autres styles ... */

/* Style de base pour les cellules de congé */
.leave-start,
.leave-middle,
.leave-end {
  background-color: #001f47; /* Couleur pour les congés */
}

/* Bordures arrondies pour le début et la fin */
.leave-start {
  border-right: none; /* Supprime la bordure droite */
}

.leave-end {
  border-left: none; /* Supprime la bordure gauche */
}

/* Ajustements pour les cellules du milieu */
.leave-middle {
  border-left: none; /* Supprime les bordures gauche et droite */
  border-right: none;
}

/* Pour gérer les cas où le congé commence et se termine le même jour */
.leave-single {
}

/* Style pour la dernière cellule de congé si elle est suivie d'une cellule non-congé */
.leave-end + td {
  border-left: 1px solid #e0e0e0; /* Remet la bordure gauche */
}

/* Style pour la première cellule normale après une série de congés */
td:not(.leave-middle):not(.leave-start):not(.leave-end) {
  border-left: 1px solid #e0e0e0; /* Assure que la cellule a une bordure gauche */
}
</style>
