<template>
  <v-container fluid>
    <custome-loader v-if="loadingFiles"></custome-loader>
    <loading
      transition="fade"
      :active.sync="waitTimeout"
      :can-cancel="false"
      :is-full-page="false"
      loader="spinner"
      color="#efb639"
      :opacity="1"
    >
    </loading>
    <v-dialog v-model="dialogDelete" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5"> <v-icon>mdi-delete</v-icon> Supression du compte </span>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-container>
            <h3>Êtes-vous sûr de vouloir supprimer le compte : {{ editedItem.completeName }} ?</h3>
            <p>
              En supprimant ce compte, vous consentez à la suppression des données à caractère personnelle lié à ce
              compte. Vous consentez également à ne plus pouvoir consulter :
            </p>
            <ul>
              <li>Les statistiques</li>
              <li>Les données enregistrées (Nom, prénom, ip...)</li>
              <li>Les données bancaires</li>
            </ul>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="EoleYellow" text @click="closeDelete">Annuler</v-btn>
          <v-btn class="white--text" color="EoleError" @click="deleteItemConfirm(editedItem.id)">
            Confirmer la suppression</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12" md="3" class="">
        <v-card flat outlined>
          <v-card-title class="EoleBlueLighten no-padding">
            <v-col cols="12" md="10" class="text-body-1">Liste des viviers</v-col>
            <v-col cols="12" md="2">
              <v-dialog v-model="dialog" max-width="1000px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="EoleBlue" outlined v-bind="attrs" v-on="on" large>
                    <v-icon> mdi-account-multiple-plus </v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>
                  <v-stepper v-model="stepperAccount" flat>
                    <v-stepper-items>
                      <el-steps :active="stepperAccount" finish-status="success" simple>
                        <el-step title="Étape 1"></el-step>
                        <el-step title="Étape 2"></el-step>
                        <el-step title="Étape 3"></el-step>
                        <el-step title="Étape 3"></el-step>
                      </el-steps>

                      <!-- <v-stepper-header>
                        <v-stepper-step :key="`1-step`" :complete="stepperAccount > 1" :step="1" editable>
                          Informations
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step :key="`2-step`" :complete="stepperAccount > 2" :step="2" editable>
                          Informations familliale
                        </v-stepper-step>

                        <v-divider></v-divider>
                        <v-stepper-step :key="`3-step`" :complete="stepperAccount > 3" :step="3" editable>
                          Informations contractuelle
                        </v-stepper-step>

                        <v-divider></v-divider>
                        <v-stepper-step :key="`4-step`" :complete="stepperAccount > 4" :step="4" editable>
                          Complément d'information
                        </v-stepper-step>
                      </v-stepper-header> -->
                      <v-stepper-content step="0" class="no-padding">
                        <v-card-text class="mt-5">
                          <v-form ref="form" v-model="validityFormPassword" lazy-validation>
                            <v-row>
                              <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                  dense
                                  prepend-inner-icon="mdi-account"
                                  outlined
                                  :rules="basicRules"
                                  :error-messages="lastnameErrorMessage"
                                  v-model="editedItem.lastname"
                                  label="Nom collaborateur"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                  dense
                                  prepend-inner-icon="mdi-account"
                                  outlined
                                  :rules="basicRules"
                                  :error-messages="firstnameErrorMessage"
                                  v-model="editedItem.firstname"
                                  label="Prénom collaborateur"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="6" md="6">
                                <v-select
                                  dense
                                  prepend-inner-icon="mdi-cogs"
                                  outlined
                                  value="editedItem.statut"
                                  v-model="editedItem.statut"
                                  :items="states"
                                  label="Statut"
                                ></v-select>
                              </v-col>
                              <v-col cols="12" sm="6" md="6">
                                <v-select
                                  dense
                                  prepend-inner-icon="mdi-cogs"
                                  outlined
                                  :items="services"
                                  item-text="name"
                                  item-value="id_service"
                                  label="Service"
                                  v-model="editedItem.idService"
                                ></v-select>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                  dense
                                  prepend-inner-icon="mdi-account"
                                  outlined
                                  v-model="editedItem.street"
                                  label="Adresse"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="6">
                                <v-autocomplete
                                  messages="Entrez un code postal"
                                  :search-input.sync="searchCity"
                                  :items="cityItems"
                                  outlined
                                  v-model="editedItem.city"
                                  :value="editedItem.zip"
                                  label="Ville"
                                  clearable
                                  hide-details
                                  hide-selected
                                  dense
                                  no-filter
                                >
                                  <template v-slot:no-data>
                                    <v-list-item>
                                      <v-list-item-title> Entrez un code postal </v-list-item-title>
                                    </v-list-item>
                                  </template>

                                  <template v-slot:item="{ item }">
                                    <v-list-item-content>
                                      <v-list-item-title>{{ item }}</v-list-item-title>
                                    </v-list-item-content>
                                  </template></v-autocomplete
                                >
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                  dense
                                  prepend-inner-icon="mdi-gmail"
                                  outlined
                                  :rules="emailRules"
                                  :error-messages="emailErrorMessage"
                                  v-model="editedItem.email"
                                  label="Adresse électronique"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                  dense
                                  prepend-inner-icon="mdi-phone"
                                  outlined
                                  :rules="phoneNumberRules"
                                  :error-messages="phoneNumberErrorMessage"
                                  v-model="editedItem.phoneNumber"
                                  label="N° de contact"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-card-text>
                      </v-stepper-content>

                      <v-stepper-content step="1" class="no-padding">
                        <v-card-title>
                          <span class="text-h5">Informations familliales</span>
                        </v-card-title>

                        <v-card-text>
                          <v-form ref="form">
                            <v-row>
                              <v-col cols="12" sm="6" md="6">
                                <v-select
                                  dense
                                  prepend-inner-icon="mdi-cogs"
                                  outlined
                                  v-model="editedItem.idMaritalStatus"
                                  :items="[
                                    {
                                      id: 1,
                                      designation: 'Célibataire',
                                    },
                                    {
                                      id: 2,
                                      designation: 'En concubinage',
                                    },
                                    {
                                      id: 3,
                                      designation: 'Marié(e)',
                                    },
                                    {
                                      id: 4,
                                      designation: 'Pacsé(e)',
                                    },
                                    {
                                      id: 5,
                                      designation: 'Divorcé(e)',
                                    },
                                    {
                                      id: 6,
                                      designation: 'Veuf(ve)',
                                    },
                                  ]"
                                  item-text="designation"
                                  item-value="id"
                                  label="Situation familiale"
                                ></v-select>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-card-text>

                        <v-card-text class="no-padding mb-10">
                          <v-form>
                            <v-simple-table dense class="no-padding">
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th class="text-left">Nature du lien</th>
                                    <th class="text-left">Nom de famille</th>
                                    <th class="text-left">Prénom</th>
                                    <th class="text-left">Créer fiche client</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(familyMember, index) in editedItem.familyMembers" v-bind:key="index">
                                    <td class="text-left" style="width: 50%">
                                      <v-select
                                        class="mt-6"
                                        dense
                                        :id="`input-family-${index}`"
                                        outlined
                                        v-model="familyMember.id_account_family_link"
                                        :items="[
                                          {
                                            id: 1,
                                            designation: 'Conjoint(e)',
                                          },
                                          {
                                            id: 2,
                                            designation: 'Enfant',
                                          },
                                        ]"
                                        item-text="designation"
                                        item-value="id"
                                      ></v-select>
                                    </td>
                                    <td class="text-left">
                                      <v-text-field
                                        ref="newTextField"
                                        outlined
                                        class="mt-6"
                                        dense
                                        v-model="familyMember.lastname"
                                        single-line
                                      ></v-text-field>
                                    </td>
                                    <td class="text-left">
                                      <v-text-field
                                        ref="newTextField"
                                        outlined
                                        class="mt-6"
                                        dense
                                        v-model="familyMember.firstname"
                                        single-line
                                      ></v-text-field>
                                    </td>
                                    <td>
                                      <v-switch inset v-model="familyMember.createClient"> </v-switch>
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                            <v-row>
                              <v-col cols="12" md="4">
                                <v-btn
                                  style="text-transform: none"
                                  elevation="0"
                                  @click="addLine(editedItem.familyMembers)"
                                >
                                  <v-icon left>mdi-plus-circle</v-icon> Ajouter un lien familliale
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-card-text>
                      </v-stepper-content>

                      <v-stepper-content step="2" class="no-padding">
                        <v-card-title>
                          <span class="text-h5">Contrat de travail</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-form ref="form" v-model="validityFormPassword" lazy-validation>
                              <v-row>
                                <v-col cols="12" sm="6" md="6">
                                  <v-select
                                    dense
                                    prepend-inner-icon="mdi-cogs"
                                    outlined
                                    v-model="editedItem.idTypeContract"
                                    :items="typeContracts"
                                    label="Type de contrat"
                                    item-text="designation"
                                    item-value="id"
                                  >
                                  </v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-select
                                    dense
                                    prepend-inner-icon="mdi-cogs"
                                    outlined
                                    :items="typeEmployments"
                                    item-text="designation"
                                    item-value="id"
                                    label="Type d'emploi"
                                    v-model="editedItem.idTypeEmployment"
                                  >
                                  </v-select>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="6" md="6">
                                  <v-select
                                    dense
                                    prepend-inner-icon="mdi-cogs"
                                    outlined
                                    v-model="editedItem.idTypeJob"
                                    :items="typeJobs"
                                    item-text="designation"
                                    item-value="id"
                                    label="Poste"
                                  >
                                  </v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-select
                                    dense
                                    prepend-inner-icon="mdi-cogs"
                                    outlined
                                    :items="typeQualifications"
                                    item-text="designation"
                                    item-value="id"
                                    label="Classification du poste"
                                    v-model="editedItem.idTypeQualification"
                                  ></v-select>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-container>
                        </v-card-text>
                      </v-stepper-content>

                      <v-stepper-content step="3" class="no-padding">
                        <v-card-title>
                          <span class="text-h5">Informations complémentaires</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-form ref="form" v-model="validityFormPassword" lazy-validation>
                              <v-row>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    dense
                                    prepend-inner-icon="mdi-phone"
                                    outlined
                                    type="date"
                                    v-model="editedItem.entryDate"
                                    label="Date d'entrée dans l'entreprise"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6"> </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    dense
                                    outlined
                                    v-show="editedIndex === -1"
                                    v-model="password"
                                    :error-messages="passwordErrorMessage"
                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    :rules="[passwordRules.required, passwordRules.min]"
                                    :type="showPassword ? 'text' : 'password'"
                                    label="Mot de passe"
                                    counter="12"
                                    @click:append="showPassword = !showPassword"
                                  >
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    dense
                                    outlined
                                    v-show="editedIndex === -1"
                                    v-model="passwordValidation"
                                    :error-messages="passwordErrorMessage"
                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    :rules="[passwordRules.required, passwordRules.min]"
                                    :type="showPassword ? 'text' : 'password'"
                                    label=" Validation du mot de passe"
                                    hint="Vos deux mots de passe doivent correspondre"
                                    counter="12"
                                    @click:append="showPassword = !showPassword"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-container>
                        </v-card-text>
                      </v-stepper-content>
                    </v-stepper-items>
                  </v-stepper>
                  <v-card-actions>
                    <v-btn v-if="stepperAccount > 0" text @click="stepperAccount--">retour</v-btn>
                    <v-spacer></v-spacer>

                    <v-btn v-if="stepperAccount != 3" text @click="stepperAccount++">suivant</v-btn>
                    <v-btn
                      v-if="stepperAccount === 3"
                      class="white--text"
                      color="EoleBlue"
                      @click="editedIndex === -1 ? validateFormPassword(editedItem.id) : save(editedItem.id)"
                    >
                      {{ editedIndex === -1 ? "Ajouter le nouveau collaborateur" : "Modifier" }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-card-title>
          <v-card-text class="no-padding">
            <v-treeview
              dense
              :active.sync="active"
              :items="items"
              open-on-click
              :open.sync="open"
              activatable
              color="EoleBlue"
              transition
              selected-color="EoleYellow"
            >
              <template v-slot:prepend="{ item }">
                <v-badge
                  class="my-2 mr-2"
                  v-if="item.id && !item.children"
                  bordered
                  bottom
                  :color="item.dbl === 1 ? 'EoleError' : 'EoleGreen'"
                  :icon="item.dbl === 1 ? 'mdi-block-helper' : 'mdi-check'"
                  offset-x="15"
                  offset-y="15"
                >
                  <v-avatar
                    size="40"
                    :color="item.avatarUrl === null ? item.color : null"
                    style="border: 1px solid !important"
                  >
                    <v-img :src="item.avatarUrl" v-if="item.avatarUrl != null"> </v-img>
                    <span v-else class="white--text">{{ item.firstname[0] + item.lastname[0] }}</span>
                  </v-avatar>
                </v-badge>
                {{ item.completeName }}
              </template>
            </v-treeview>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end no-padding align-center mt-4 mr-2 EoleBlue--text">
            <p class="text-caption">{{ users.length + usersDisabled.length }} utilisateurs trouvés</p>
          </v-card-actions>
        </v-card>
      </v-col>

      <loading
        transition="fade"
        :active.sync="loadingFiles"
        :can-cancel="false"
        :is-full-page="false"
        loader="spinner"
        color="#efb639"
        :opacity="1"
      >
      </loading>
      <v-col cols="12" md="9" class="">
        <v-scroll-y-transition mode="out-in">
          <v-row v-if="!selected" align="center" class="text-left">
            <v-col>
              <v-alert outlined type="info" color="EoleBlue" prominent icon="mdi-account" text dense
                >Veuillez selectionner un collaborateur à afficher dans cette fenêtre.
              </v-alert>
            </v-col>
          </v-row>
          <v-card v-else :key="selected.id" class="mx-auto" flat style="background-color: transparent !important">
            <v-card outlined class="text-center">
              <v-card-text class="profilBanner" style="height: 100px">
                <v-row>
                  <v-col cols="12" md="6" class="text-left"> </v-col>
                  <v-col cols="12" md="6" class="text-right">
                    <!-- <v-menu left right transition="slide-x-transition" close-on-content-click>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" style="background-color: white">
                          <i class="fa-solid fa-file-signature fa-icone fa-2x" style=""></i>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item @click="editItem(selected)" link>
                          <v-list-item-title>
                            <v-icon left>mdi-account-edit</v-icon>Modifier le profil
                          </v-list-item-title>
                        </v-list-item>

                        <v-dialog
                          v-if="selected.dbl === 0"
                          width="600px"
                          transition="dialog-bottom-transition"
                          class="hide-scrollbar"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-list-item v-bind="attrs" v-on="on" class="EoleBlue--text" link>
                              <v-list-item-title>
                                <v-icon color="EoleBlue" left> mdi-account-lock </v-icon>

                                Désactiver le profil
                              </v-list-item-title>
                            </v-list-item>
                          </template>
                          <v-card>
                            <v-card-title>Désactivation de l'utilisateur</v-card-title>
                            <v-card-text class="pa-5">
                              <v-form ref="form" lazy-validation>
                                <v-row>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      prepend-inner-icon="mdi-calendar"
                                      outlined
                                      dense
                                      v-model="desactivateData.dateEnd"
                                      label="Date de la sortie"
                                      type="date"
                                    >
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-select
                                      dense
                                      prepend-inner-icon="mdi-cogs"
                                      outlined
                                      v-model="desactivateData.idAccountLeaveReason"
                                      :items="reasonLeave"
                                      item-text="designation"
                                      item-value="id"
                                      label="Motif de la sortie"
                                    ></v-select>
                                  </v-col>
                                </v-row>
                                <v-row v-if="desactivateData.idAccountLeaveReason === 5">
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      prepend-inner-icon="mdi-calendar"
                                      outlined
                                      dense
                                      v-model="desactivateData.dateApproval"
                                      label="Date demande homologation"
                                      type="date"
                                    >
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      prepend-inner-icon="mdi-calendar"
                                      outlined
                                      dense
                                      v-model="desactivateData.dateAgreementApproval"
                                      label="Date accord homologation"
                                      type="date"
                                    >
                                    </v-text-field>
                                  </v-col>
                                </v-row>
                              </v-form>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                class="white--text"
                                color="EoleError"
                                @click="disabled({ id: selected.id, status: 1 })"
                                >Désactiver</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-dialog>

                        <v-list-item
                          v-else
                          class="EoleGreen--text"
                          @click="disabled({ id: selected.id, status: 0 })"
                          link
                        >
                          <v-list-item-title>
                            <v-icon color="EoleGreen" left> mdi-account-lock-open </v-icon>

                            Réactiver le profil
                          </v-list-item-title>
                        </v-list-item>

                        <v-list-item @click="sendEmailLostPassword(selected.email)" link>
                          <v-list-item-title>
                            <v-icon left>mdi-history</v-icon>Réinitialiser le mot de passe
                          </v-list-item-title>
                        </v-list-item>
                        <v-divider class="my-2"></v-divider>
                        <v-list-item class="EoleError--text" link @click="deleteItem(selected)">
                          <v-list-item-title>
                            <v-icon left color="EoleError">mdi-account-remove</v-icon>Supprimer le profil
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu> -->
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="profilCandidateBadge">
                <v-dialog max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar v-bind="attrs" v-on="on" ref="avatar" size="96" :color="selected.color">
                      <i class="fa-solid fa-file-signature fa-3x white--text ml-3" style=""></i>
                    </v-avatar>
                  </template>

                  <v-card>
                    <v-card-title>Rédiger la promesse d'embauche</v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <v-select
                            dense
                            prepend-inner-icon="mdi-cogs"
                            outlined
                            :items="typeContracts"
                            label="Type de contrat"
                            item-text="designation"
                            item-value="id"
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-select
                            dense
                            prepend-inner-icon="mdi-cogs"
                            outlined
                            :items="typeEmployments"
                            item-text="designation"
                            item-value="id"
                            label="Type d'emploi"
                            v-model="editedItem.idTypeEmployment"
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field outlined label="Date du dernier entretien" dense type="date"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field outlined label="Nombre de jours de repos" dense></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <v-select
                            dense
                            prepend-inner-icon="mdi-cogs"
                            outlined
                            label="Site d'embauche"
                            item-text="designation"
                            item-value="id"
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field outlined label="Date de démarrage du contrat" dense type="date"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <v-select
                            dense
                            prepend-inner-icon="mdi-cogs"
                            outlined
                            v-model="editedItem.idTypeJob"
                            :items="typeJobs"
                            item-text="designation"
                            item-value="id"
                            label="Poste"
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-select
                            dense
                            prepend-inner-icon="mdi-cogs"
                            outlined
                            :items="typeQualifications"
                            item-text="designation"
                            item-value="id"
                            label="Classification du poste"
                            v-model="editedItem.idTypeQualification"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field label="Rémunération brut (en) €" outlined dense></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field label="En lettre" outlined dense></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- Période 1,2,3 checkbox | euros, lettres  -->
                      <v-row v-for="i in 3" v-bind:key="i">
                        <v-col cols="12" sm="6" md="1">
                          <v-checkbox label=""></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <v-text-field outlined :label="`Période ${i}`" dense></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field label="Rémunération brut (en) €" outlined dense></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field label="En lettre" outlined dense></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-switch label="Période d'essai" inset></v-switch>
                          <v-switch label="Renouvelable" inset></v-switch>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="white--text"
                        color="EoleBlue"
                        @click="
                          editedIndex = -1
                          dialog = false
                        "
                      >
                        Annuler
                      </v-btn>
                      <v-btn class="white--text" color="EoleGreen" @click="save">Prévisualiser</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-card-text>

              <v-card-text class="mt-16">
                <v-row>
                  <v-col cols="12" md="6" class="text-left">
                    <h3 class="text-h5">
                      Anne Avel
                      <v-tooltip v-model="showEmail" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="white--text ml-2"
                            fab
                            color="EoleBlue"
                            :href="`mailto:${selected.email}`"
                            v-bind="attrs"
                            v-on="on"
                            icon
                          >
                            <i class="fa-solid fa-envelope fa-icone"></i
                          ></v-btn>
                        </template>
                        <span>{{ selected.email }}</span>
                      </v-tooltip>

                      <v-tooltip v-model="showPhone" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="white--text"
                            fab
                            color="EoleBlue"
                            :href="`tel:${selected.phoneNumber}`"
                            v-bind="attrs"
                            v-on="on"
                            icon
                          >
                            <i class="fa-solid fa-phone-volume fa-icone"></i
                          ></v-btn>
                        </template>
                        <span>{{ selected.phoneNumber }}</span>
                      </v-tooltip>

                      <v-tooltip v-model="showMap" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="white--text"
                            fab
                            color="EoleBlue"
                            :href="`tel:${selected.phoneNumber}`"
                            v-bind="attrs"
                            v-on="on"
                            icon
                          >
                            <i class="fa-solid fa-location-dot fa-icone"></i
                          ></v-btn>
                        </template>
                        <span>65 avenue de la libération</span>
                      </v-tooltip>
                    </h3>
                  </v-col>
                  <!-- <v-col cols="12" md="6" class="text-right">
                    <v-chip small v-if="selected.lastConnection === '0000-00-00 00:00:00'" color="EoleError">
                      <v-icon small class="mr-1"> mdi-close-circle </v-icon>
                      Jamais connecté
                    </v-chip>
                    <v-chip v-else> Connecté {{ selected.lastConnection }} </v-chip>
                  </v-col> -->
                </v-row>
                <v-row>
                  <v-col cols="12" md="5">
                    <v-card outlined class="rounded-xl" min-height="160">
                      <v-card-text class="pa-5"> pdf du cv </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="7" class="d-flex">
                    <v-card class="flex-fill flex d-flex flex-column rounded-xl" outlined flat>
                      <v-card-text class="h-full">
                        <v-row class="justify-center text-center text-caption h-full mt-1" align="center">
                          <v-btn color="EoleError" class="white--text" fab icon x-large>
                            <v-icon>mdi-close-thick</v-icon></v-btn
                          >
                          <v-btn color="EoleGreen" class="white--text" fab icon> <v-icon>mdi-check</v-icon></v-btn>
                          <v-btn color="EoleBlue" fab icon>
                            <i class="fa-solid fa-volume-high EoleBlue--text"></i>
                          </v-btn>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card>
        </v-scroll-y-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue"
import Toast from "vue-toastification"
// Import the CSS or use your own!
import "vue-toastification/dist/index.css"
import config from "@/views/config/config"
import appbar from "@/layouts/Content.vue"
import emailjs from "@emailjs/browser"
import { init } from "@emailjs/browser"
init("user_gUunL4KW2ImPaGngWHYhP")
// Import component
import Loading from "vue-loading-overlay"
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css"
import CustomeLoader from "@/layouts/components/LoaderCustom.vue"

import moment from "moment"
moment.locale("fr")
import FileSaver from "file-saver"
import JSZip from "jszip"
import { saveAs } from "file-saver"

import OfficeCandidate from "./OfficeCandidate.vue"

Vue.use(Toast, {
  maxToasts: 2,
  newestOnTop: true,
})
import imageViewer from "vue-image-viewer"

import * as XLSX from "xlsx"

Vue.use(imageViewer)

import draggable from "vuedraggable"

const pause = ms => new Promise(resolve => setTimeout(resolve, ms))

export default {
  data: () => ({
    headersHoliday: [
      {
        text: "",
        value: "name",
      },
      {
        text: moment().subtract(1, "year").format("YYYY"),
        value: "lastYear",
      },
      {
        text: moment().format("YYYY"),
        value: "currentYear",
      },
    ],
    nowFormat: moment().format("Do MMMM YYYY"),
    dialogUploadDocument: false,
    searchCity: null,
    cityItems: [],
    stepperAccount: 0,
    expandedCost: [],
    now: moment().format("MMM YYYY"),
    loadingActionCampain: false,
    loadingActionZip: false,
    headersCosts: [
      {
        text: "Catégorie de frais",
        align: "start",
        sortable: true,
        value: "designation",
      },
      {
        text: "Informations complémentaires",
        value: "informations",
      },
      {
        text: "Montant (en €)",
        value: "price",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    desactivateData: {
      idAccountLeaveReason: null,
      dateApproval: null,
      dateAgreementApproval: null,
      dateEnd: null,
    },
    test: [
      {
        name: "Complétude des données",
      },
      {
        name: "information personnelle",
      },
      {
        name: "information familliale",
      },
    ],
    reasonLeave: [
      {
        id: 1,
        designation: "Licenciement",
      },
      {
        id: 2,
        designation: "Licenciement économique",
      },
      {
        id: 3,
        designation: "Licenciement pour faute",
      },
      {
        id: 4,
        designation: "Licenciement pour faute lourde",
      },
      {
        id: 5,
        designation: "Rupture conventionnelle",
      },
      {
        id: 6,
        designation: "Démission",
      },
      {
        id: 7,
        designation: "Fin de contrat",
      },
    ],
    expanded: [],
    singleExpand: true,
    page: 1,
    pageCount: 0,
    categoriesHeaders: [
      {
        text: "Catégorie",
        align: "start",
        sortable: true,
        value: "designation",
      },
      {
        text: "Conformité",
        value: "files.length",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    trainingHeaders: [
      {
        text: "Année",
        align: "start",
        sortable: true,
        value: "year",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    categoriesInformationHeaders: [
      {
        text: "",
        align: "start",
        sortable: true,
        value: "name",
      },

      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    fileCategories: [],
    documentPutId: null,
    documentPutName: null,
    dialogUploadPut: false,
    fileUrl: null,
    fileUrlPut: null,
    fileSelected: true,
    document: {
      name: null,
      data: {
        name: null,
        type: null,
      },
      categorie: null,
    },
    documentPut: {
      name: null,
      data: null,
      categorie: null,
      fileId: null,
    },
    dialogUpload: false,
    pdf: null,
    avatarUrlMini: null,
    avatarUrl: null,
    imgError: false,
    editedItemContract: {
      idTypeContract: null,
      idTypeEmployment: null,
      idTypeJob: null,
      idTypeQualification: null,
    },
    dialogAddContractToAccount: false,
    typeEmployments: [],
    typeJobs: [],
    typeContracts: [],
    typeQualifications: [],
    services: [],
    showPhone: false,
    showEmail: false,
    missingData: [],
    showInformations: false,
    showHR: false,
    showInformatical: false,
    showAnnual: false,
    tab: null,
    HRfilesAvailableCount: 0,
    HRfilesNotAvailableCount: 0,
    HRfiles: [
      {
        id: 0,
        name: "Pièce d'identité en cours de validité",
        available: false,
        loading: false,
      },
      {
        id: 1,
        name: "Relevé d'identité bancaire",
        available: false,
        loading: false,
      },
      {
        id: 2,
        name: "Attestation de sécurité sociale",
        available: true,
        loading: false,
      },
      {
        id: 3,
        name: "Décision unilatérale de l'employeur santé",
        available: false,
        loading: false,
      },
      {
        id: 4,
        name: "Décision unilatérale de l'employeur prévoyance",
        available: false,
        loading: false,
      },
      {
        id: 5,
        name: "Notice d'information santé",
        available: false,
        loading: false,
      },
      {
        id: 6,
        name: "Notice d'information prévoyance",
        available: false,
        loading: false,
      },
      {
        id: 7,
        name: "Contrat de travail",
        available: true,
        loading: false,
      },

      {
        id: 8,
        name: "Attestation d'honorabilité",
        available: false,
        loading: false,
      },
      {
        id: 9,
        name: "Formations",
        available: false,
        loading: false,
      },
      {
        id: 10,
        name: "Charte informatique",
        available: false,
        loading: false,
      },
    ],
    imagesViews: [],
    itemsInformatical: [
      {
        id: 0,
        name: "Souris",
        quantity: 4,
      },
      {
        id: 1,
        name: "Ordinateur fixe",
        quantity: 1,
      },
      {
        id: 2,
        name: "Ordinateur portable",
        quantity: 1,
      },
      {
        id: 3,
        name: "Écran",
        quantity: 1,
      },
      {
        id: 4,
        name: "Imprimante",
        quantity: 1,
      },
      {
        id: 5,
        name: "Téléphone fixe",
        quantity: 1,
      },
      {
        id: 6,
        name: "Téléphone portable",
        quantity: 1,
      },
      {
        id: 7,
        name: "Casque",
        quantity: 1,
      },
      {
        id: 8,
        name: "Clé USB",
        quantity: 1,
      },
    ],
    itemInformaticalAttribute: [],
    usersDisabledCount: 0,
    usersEnabledCount: 0,
    usersUnavailableCount: 0,
    usersUnavailable: [],
    score: 0,
    fileCount: 3,
    fileMax: 4,
    saveFile: false,
    fab: false,
    valueHRfiles: 75,
    agencyName: null,
    load: false,
    ipError: false,
    ip: "",
    ipLocation: "",
    /* test purpose*/
    active: [],
    avatar: null,
    open: [],
    users: [],
    usersDisabled: [],
    fallback:
      "https://images.unsplash.com/photo-1560391243-e6431f0fa114?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1567&q=80",
    /* end test purpose */
    passwordValidation: null,
    lastnameErrorMessage: "",
    firstnameErrorMessage: "",
    emailErrorMessage: "",
    phoneNumberErrorMessage: "",
    validityFormPassword: true,
    password: "",
    showPassword: false,
    passwordValidationMessage: null,
    passwordErrorMessage: "",
    basicRules: [v => !!v || "Ce champ est requis"],
    phoneNumberRules: [
      v => !!v || "N° de téléphone requis",
      v => v.length === 10 || "Un numéro de téléphone Français est composé de 10 chiffres",
    ],
    passwordRules: {
      required: value => !!value || "Mot de passe requis.",
      min: v => v.length >= 12 || "Minimum 12 caractères",
    },
    emailRules: [
      v => !!v || "Une adresse électronique est requise",
      v => /.+@.+\..+/.test(v) || "L'adresse électronique doit être valide : example@gmail.com",
    ],
    ipInfo: [],
    snackbar: {
      text: "",
      color: "",
      icon: "",
      enable: false,
    },
    waitTimeout: false,
    date: null,
    time: null,
    page: 1,
    pageCount: 0,
    loading: false,
    fetch: false,
    states: ["administrateur", "utilisateur"],
    dialog: false,
    dialogDelete: false,
    dialogIp: false,
    headers: [
      {
        text: "",
        value: "phoneNumber",
        sortable: false,
      },
      {
        text: "COLLABORATEUR",
        align: "start",
        sortable: false,
        value: "completeName",
      },
      {
        text: "QUALITÉ",
        value: "statut",
      },
      {
        text: "EMAIL",
        value: "email",
      },
      {
        text: "DERNIÈRE CONNEXION",
        value: "lastConnection",
      },
      {
        text: "ADRESSE IP",
        value: "lastIPConnected",
      },
      {
        text: "ACTIONS",
        value: "actions",
        sortable: false,
      },
    ],

    editedIndex: -1,
    editedItem: {
      id: null,
      firstname: "",
      lastname: "",
      lastConnection: "",
      lastIPConnected: "",
      statut: "collaborateur",
      email: "",
      phoneNumber: "",
      serviceChoose: "",
      idTypeContract: null,
      idTypeEmployment: null,
      idTypeJob: null,
      idTypeQualification: null,
      familyMembers: [],
    },
    defaultItem: {
      id: null,
      firstname: "",
      lastname: "",
      lastConnection: "",
      lastIPConnected: "",
      statut: "",
      email: "",
      phoneNumber: "",
      serviceChoose: "",
      idTypeContract: null,
      idTypeEmployment: null,
      idTypeJob: null,
      idTypeQualification: null,
    },
    loadingFiles: false,
    loadedFiles: false,
    loadingUserData: false,
    familyHeaders: [
      {
        text: "Lien de parenté",
        value: "designation",
      },
      {
        text: "Nom",
        value: "lastname",
      },
      {
        text: "Prénom",
        value: "firstname",
      },
    ],
    dialogUploadFile: false,
    fileUrl: null,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nouveau collaborateur"
        : "Modification pour " + this.editedItem.lastname + " " + this.editedItem.firstname
    },
    items() {
      return [
        {
          id: 1,
          name: `A contacter`,
          children: this.usersDisabled,
          open: false,
        },
        {
          id: 1,
          name: `En cours de recrutement`,
          children: this.usersDisabled,
          open: false,
        },
        {
          id: 2,
          name: `Mon vivier actif`,
          children: this.users,
          open: false,
        },
        {
          id: 3,
          name: `Candidatures refusés`,
          children: this.usersDisabled,
          open: false,
        },
      ]
    },
    selected() {
      this.imagesViews = []
      if (!this.active.length) return undefined

      const id = this.active[0]

      //get firstname of user selected

      if (!this.loadingFiles) {
        const user = this.users.find(user => user.id === id)
        const userDisable = this.usersDisabled.find(user => user.id === id)
        if (user) {
          this.getImg(
            this.users.find(user => user.id === id).lastname,
            this.users.find(user => user.id === id).firstname,
          )
          user.categories.forEach(category => {
            category.files.forEach(file => {
              if (file.extension === "png" || file.extension === "jpg") {
                this.imagesViews.push({
                  name: file.name,
                  url: `http://${file.url}`,
                })
              }
            })
          })
        } else if (userDisable) {
          this.getImg(
            this.usersDisabled.find(user => user.id === id).lastname,
            this.usersDisabled.find(user => user.id === id).firstname,
          )

          userDisable.categories.forEach(category => {
            category.files.forEach(file => {
              if (file.extension === "png" || file.extension === "jpg") {
                this.imagesViews.push({
                  name: file.name,
                  url: `http://${file.url}`,
                })
              }
            })
          })
        } else {
          // Handle the case where the user with the specified 'id' was not found
          console.error(`User with id ${id} not found.`)
        }

        this.$imageViewer.images(this.imagesViews)
      }
      return this.users.find(user => user.id === id) || this.usersDisabled.find(user => user.id === id)
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    searchCity(val) {
      //verify if is number
      if (/^\d{5}$/.test(val)) {
        try {
          fetch("https://geo.api.gouv.fr/communes?codePostal=" + val)
            .then(res => res.clone().json())
            .then(res => {
              this.cityItems = res.map(item => item.nom)
              this.editedItem.zip = val
            })
            .catch(err => {
              console.log(err)
            })

          this.successSelectCity = true
          this.successSelectCityMessage = `Nous avons trouvé ${data.length} villes ou communes ayant comme code postal ${val}`
        } catch (e) {
          this.errorSelectCity = true
          this.errorSelectCityMessage = "Une erreur est survenue"
        }
      } else {
        this.errorSelectCity = true
        this.errorSelectCityMessage = "Le code postal doit être composé de 5 chiffres"
      }
    },
  },

  async created() {
    document.addEventListener("contextmenu", event => event.preventDefault())
    this.initialize()
    this.fetchUsers()
      .then(() => {})
      .finally(() => {
        this.open = [1]
      })
    this.fetchType()
    this.agencyName = localStorage.getItem("agency")
    this.HRfilesAvailableCount = this.HRfiles.filter(file => file.available === true).length
    this.fetchServices()
  },
  components: {
    appbar,
    Loading,
    CustomeLoader,
    draggable,
  },

  methods: {
    async saveNewHolidayData(id, remaining, idEmploymentType) {
      console.log("save")
      console.log(remaining, idEmploymentType)

      let remainPaid = remaining[0]
      let remainRecoveryWorking
      let remainCompensatory

      idEmploymentType == 2 ? (remainCompensatory = remaining[1]) : (remainRecoveryWorking = remaining[1])

      console.log(remainPaid, remainRecoveryWorking, remainCompensatory)

      try {
        const formData = new FormData()
        formData.append("id_account", id)
        formData.append("id_employment_type", idEmploymentType)
        formData.append("remain_paid_leave", remainPaid.currentYear)
        formData.append("remain_paid_leave_last_year", remainPaid.lastYear)
        idEmploymentType == 2 ? formData.append("compensatory_package_leave", remainCompensatory.currentYear) : null
        idEmploymentType == 2
          ? formData.append("compensatory_package_leave_last_year", remainCompensatory.lastYear)
          : null
        idEmploymentType != 2 ? formData.append("recovery_working_time_leave", remainRecoveryWorking.currentYear) : null
        idEmploymentType != 2
          ? formData.append("recovery_working_time_leave_last_year", remainRecoveryWorking.lastYear)
          : null
        formData.append("place", "setNewBalance")
        fetch(`${config.apiUri}/accounts/${id}/holidays`, {
          method: "POST",
          body: formData,
        }).then(response => {
          if (response.ok) {
            this.$toast.success("VOK T", {
              timeout: 2000,
              position: "bottom-right",
            })
          } else {
            this.$toast.error("Erreur lors de la sauvegarde des questions de l'entretien")
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    cancelNewHolidayData() {
      this.snack = true
      this.snackColor = "error"
      this.snackText = "Canceled"
    },
    openNewHolidayData() {
      this.snack = true
      this.snackColor = "info"
      this.snackText = "Dialog opened"
    },
    closeNewHolidayData() {
      console.log("Dialog closed")
    },
    removeMaintenanceQuestion(array, index) {
      array.splice(index, 1)
    },
    async postMaintenance(maintenance) {
      const idAccount = this.selected.id
      try {
        const formData = new FormData()
        formData.append("idAccount", idAccount)
        formData.append("maintenance", JSON.stringify(maintenance))
        formData.append("place", "addMaintenance")
        fetch(`${config.apiUri}/accounts/${idAccount}`, {
          method: "POST",
          body: formData,
        }).then(response => {
          if (response.ok) {
            this.$toast.success("Vous avez sauvegardé les questions de l'entretien", {
              timeout: 2000,
              position: "bottom-right",
            })
          } else {
            this.$toast.error("Erreur lors de la sauvegarde des questions de l'entretien")
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    addMaintenanceQuestion(array) {
      array.push({
        question: "",
        answer_manager: "",
        answer_collaborator: "",
      })
    },
    async deleteFileTraining(training, index) {
      const idAccount = this.selected.id
      training.files.splice(index, 1)

      try {
        const formData = new FormData()
        formData.append("idAccount", idAccount)
        formData.append("idTraining", training.id)
        formData.append("files", JSON.stringify(training.files))
        formData.append("place", "deleteFileTraining")
        fetch(`${config.apiUri}/accounts/${idAccount}`, {
          method: "POST",

          body: formData,
        }).then(response => {
          if (response.ok) {
            this.$toast.success("Document retiré avec succès")
            this.initialize()
            this.fetchUsers()
          } else {
            this.$toast.error("Erreur lors de la supression du document")
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    uploadNewTraining(training) {
      try {
        const input = document.createElement("input")
        input.type = "file"
        input.accept =
          "image/png, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        input.addEventListener("change", event => {
          this.document.data = event.target.files[0]
          this.fileUrl = URL.createObjectURL(this.document.data)
          this.dialogUploadDocument = true
          // this.uploadFile(event, type, area, subType)
        })
        input.click()
      } catch (err) {
        console.log(err)
      }
    },
    addLine(array) {
      array.push({
        maritalStatus: null,
        firstname: null,
        lastname: null,
        createClient: true,
      })

      this.$nextTick(() => {
        const inputElement = document.getElementById("input-family-" + (array.length - 1))
        if (inputElement) {
          inputElement.focus()
        }
      })
    },
    removeUrlPrefix(url) {
      // replace the ged url to : C:\xampp\htdocs\documents\

      // after all add http://api.eole.local/api/php/pages/download.php?path= before the url
      return url.replace(`${config.ged}`, `${config.apiUri}/php/pages/download.php?path=C:/xampp/htdocs/documents/`)
    },
    async generateAndDownloadZip() {
      this.loadingActionZip = true
      // Créez une instance JSZip
      const zip = new JSZip()

      // Liste de liens vers les fichiers PDF avec les répertoires souhaités
      let pdfLinks = [
        // Ajoutez ici d'autres liens vers les PDF avec les répertoires souhaités
      ]

      this.users.forEach(user => {
        let csvData = []

        // Générez un fichier XLSX en mémoire
        let wb = XLSX.utils.book_new()

        if (user.costs.length > 0) {
          let total = 0
          user.costs.forEach(cost => {
            csvData.push([cost.designation, cost.price])
            cost.documents.forEach(document => {
              pdfLinks.push({
                // replace all "/" to "\"
                url: this.removeUrlPrefix(document.url),
                pathOnServer: this.removeUrlPrefix(document.url),
                folder: `${user.firstname} ${user.lastname}`,
              })
            })
            total += parseInt(cost.price)
          })
          csvData.push(["Total", total])
          let ws = XLSX.utils.aoa_to_sheet(csvData)
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1")
          let xlsxBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
          })
          zip.file(
            `${user.firstname} ${user.lastname}/compte_frais-${user.firstname} ${user.lastname}.xlsx`,
            xlsxBuffer,
          )
        }
      })

      // Ajoutez le fichier CSV à l'archive ZIP

      // Fonction pour télécharger un fichier PDF avec fetch et l'ajouter à l'archive ZIP dans le répertoire spécifié
      const downloadAndAddToZip = async ({ url, folder }) => {
        const response = await fetch(url)
        const pdfBlob = await response.blob()
        const fileName = url.split("/").pop()
        zip.file(`${folder}/${fileName}`, pdfBlob) // Crée un répertoire et ajoute le fichier dedans
        // ajouter un fichier dans ce repertoire
      }

      // Téléchargez chaque fichier PDF et ajoutez-le à l'archive avec le répertoire correspondant
      await Promise.all(pdfLinks.map(downloadAndAddToZip))

      // Générez l'archive au format blob
      const zipBlob = await zip.generateAsync({
        type: "blob",
      })

      // Utilisez file-saver pour télécharger l'archive
      saveAs(zipBlob, "documents.zip")
      this.loadingActionZip = false
    },

    async fakeAction() {
      this.loadingActionCampain = true

      for (const user of this.users) {
        await new Promise(resolve => setTimeout(resolve, 1100)) // Pause de 4,5 secondes

        try {
          await emailjs.send("service_5p49t7p", "template_p1eamen", {
            to_name: user.firstname,
            to_email: user.email,
          })
        } catch (e) {
          console.log(e)
        }
      }
      this.loadingActionCampain = false
    },
    // async fetchCostByUserId() {
    //   this.users.forEach(async user => {
    //     try {
    //       const response = await fetch(`${config.apiUri}/accounts/${user.id}/costs`, {
    //         method: "GET",
    //       })
    //       const data = await response.json()

    //       user.costs = data.costs
    //     } catch (e) {
    //       console.log(e)
    //     }
    //   })
    // },
    imageView(documentUrl) {
      let index = this.imagesViews.findIndex(image => image.url === `http://${documentUrl}`)

      this.$imageViewer.index(index)
      this.$imageViewer.show()
    },
    dialogUploadPutClick(fileId, fileName) {
      this.dialogUploadPut = true
      this.documentPut.fileId = fileId
      this.documentPut.name = fileName
    },
    // async downloadFile(path) {
    //   try {
    //     const response = await fetch(`http://${path}`, { method: "GET", mode: "no-cors" })
    //     const fileBlob = await response.blob()
    //     const file = new File([fileBlob], "nomDuFichier.png", { type: "image/png" })
    //     FileSaver.saveAs(file)
    //   } catch (err) {
    //     console.error(err)
    //   }
    // },
    async downloadFile(path, documentName, extension) {
      let blobConstruct = null
      let headers = new Headers()

      headers.append("Content-Type", "image/jpeg")
      headers.append("Accept", "image/jpeg")
      headers.append("Origin", "*")

      try {
        const response = await fetch(`${config.apiUri}/php/pages/download.php?path=${path}`, {
          method: "GET",
          headers: headers,
        })

        blobConstruct = await response.blob()

        FileSaver.saveAs(blobConstruct, `${documentName}.${extension}`)
      } catch (e) {
        console.log(e)
      }
    },

    replaceSpacesWithUnderscores() {
      this.document.name = this.document.name.replace(/ /g, "_")
    },
    replaceSpacesWithUnderscoresPut() {
      this.documentPut.name = this.documentPut.name.replace(/ /g, "_")
    },
    renderPdf(document) {
      pdfjs.getDocument(document).then(pdf => {
        pdf.getPage(1).then(page => {
          const scale = 0.5
          const viewport = page.getViewport({
            scale: scale,
          })
          const canvas = this.$refs.canvas
          const context = canvas.getContext("2d")
          canvas.height = viewport.height
          canvas.width = viewport.width
          page.render({
            canvasContext: context,
            viewport: viewport,
          })
        })
      })
    },

    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes"

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
    onDropPut(e, fileId, documentName) {
      e.preventDefault()
      this.documentPut.data = e.dataTransfer.files[0]

      if (this.documentPut.fileId === null && this.documentPut.name === null) {
        this.documentPut.fileId = fileId
        this.documentPut.name = documentName.replace(/ /g, "_")
      }

      if (this.documentPut.fileId != null && this.documentPut.name != null) {
        this.documentPut.name = this.documentPut.name.replace(/ /g, "_")

        let realSize = this.formatBytes(this.documentPut.data.size)

        if (this.documentPut.data.size > 1000000) {
          this.$toast.error(
            `Votre fichier est trop volumineux (${realSize}). \n Veuillez choisir un fichier de moins de 1 Mo.`,
            {
              position: "bottom-right",
              timeout: 3000,
            },
          )
          this.documentPut = {
            name: null,
            data: null,
            categorie: null,
            fileId: null,
          }
        } else {
          switch (this.documentPut.data.type) {
            case "application/pdf":
            case "image/png":
            case "image/jpeg":
              this.fileSelected = true
              this.fileUrlPut = URL.createObjectURL(this.documentPut.data)
              this.dialogUploadPut = true

              break
            default:
              this.documentPut = {
                name: null,
                data: null,
                categorie: null,
                fileId: null,
              }
              this.fileSelected = false
              this.$toast.error(
                `Votre fichier n'est pas au bon format. \n Veuillez choisir un fichier au format .pdf, .png ou .jpeg.`,
                {
                  position: "bottom-right",
                  timeout: 3000,
                },
              )
          }
        }
      }
    },

    onDrop(e) {
      e.preventDefault()
      this.document.data = e.dataTransfer.files[0]
      let realSize = this.formatBytes(this.document.data.size)

      if (this.document.data.size > 1000000) {
        this.$toast.error(
          `Votre fichier est trop volumineux (${realSize}). \n Veuillez choisir un fichier de moins de 1 Mo.`,
          {
            position: "bottom-right",
            timeout: 3000,
          },
        )
        this.document = {
          name: null,
          data: null,
          categorie: null,
        }
      } else {
        switch (this.document.data.type) {
          case "application/pdf":
            this.fileSelected = true
            // this.renderPdf(this.document.data)

            // view pdf in iframe

            this.fileUrl = URL.createObjectURL(this.document.data)
            this.dialogUpload = true

            break
          case "image/png":
          case "image/jpeg":
            this.fileSelected = true
            this.fileUrl = URL.createObjectURL(this.document.data)
            this.dialogUpload = true

            break
          default:
            this.fileSelected = false
            this.$toast.error(
              `Votre fichier n'est pas au bon format. \n Veuillez choisir un fichier au format .pdf, .png ou .jpeg.`,
              {
                position: "bottom-right",
                timeout: 3000,
              },
            )
        }
      }
    },
    async sendEmailLostPassword(email) {
      let response
      try {
        response = await fetch(`${config.apiUri}/php/pages/accounts.php?email=${email}`, {
          method: "GET",
        })

        const data = await response.json()

        if (data.code === 1) {
          this.$toast.success("Vous allez recevoir un email pour changer votre mot de passe d'ici quelques instants.")
          try {
            emailjs.send("service_5p49t7p", "template_v6w11ri", {
              to_name: "test",
              //from_name: email,
              to_email: data.account.email,
              link: `${config.appUri}/auth/login?id=${data.account.id}&token=${data.account.token}`,
            })
          } catch (error) {
            console.log(error)
          }
        } else {
          this.$toast.error("Cet email n'est lié à aucun compte. Veuillez réessayer.")
        }
      } catch (err) {
        console.log(err)
      }
    },
    async uploadPutFile(username, id, fileId, categorieId) {
      const formData = new FormData()
      formData.append("document", this.documentPut.data)
      formData.append("userId", id)
      formData.append("documentName", this.documentPut.name)
      formData.append("username", username.toLowerCase().replace(" ", ""))
      formData.append("fileId", fileId)
      formData.append("method", "PUT")
      formData.append("categorieId", categorieId)
      formData.append("agencyName", localStorage.getItem("agency").toLowerCase())
      formData.append("place", "account")

      try {
        const res = await fetch(`${config.apiUri}/upload`, {
          method: "POST",
          body: formData,
        })
        if (res.ok) {
          this.initialize()
          this.fetchUsers()
          this.dialogUploadPut = false
        } else {
          alert("Erreur lors de l'upload du PDF")
        }
      } catch (err) {
        console.error(err)
        alert("Erreur lors de l'upload du PDF")
      }
    },

    async postAccountTraining(training) {
      let agencyName = localStorage.getItem("agency").toLowerCase().replace(" ", "-")
      let username = this.selected.completeName.toLowerCase().replace(" ", "-")
      let extension = this.document.data.name.split(".").pop()

      training.files.push({
        name: this.document.name,
        url: `${config.ged}/${agencyName}/users/${username}/trainings/${this.document.name}.${extension}`,
        type: this.document.data.type,
        extension: extension,
        hour: this.document.time_passed.split(":")[0],
        minute: this.document.time_passed.split(":")[1],
      })

      let idAccount = this.selected.id
      try {
        const formData = new FormData()
        formData.append("idAccount", idAccount)
        formData.append("idTraining", training.id)
        formData.append("files", JSON.stringify(training.files))
        formData.append("place", "putTraining")
        fetch(`${config.apiUri}/accounts/${idAccount}`, {
          method: "POST",

          body: formData,
        }).then(response => {
          if (response.ok) {
            this.uploadTraining(this.selected.completeName)
            this.$toast.success("Formation ajoutée avec succès")
            this.initialize()
            this.fetchUsers()
          } else {
            this.$toast.error("Erreur lors de l'ajout de la formation")
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    async uploadTraining(username) {
      const formData = new FormData()
      formData.append("document", this.document.data)
      formData.append("documentName", this.document.name)
      formData.append("username", username.toLowerCase().replace(" ", "-"))
      formData.append("agencyName", localStorage.getItem("agency").toLowerCase())
      formData.append("place", "addTraining")

      try {
        const res = await fetch(`${config.apiUri}/upload`, {
          method: "POST",
          body: formData,
        })
        if (res.ok) {
          this.initialize()
          this.fetchUsers()
          this.dialogUpload = false

          this.document = {
            name: "",
            data: {
              name: null,
              type: null,
            },
            categorie: null,
          }
        } else {
          alert("Erreur lors de l'upload du PDF")
        }
      } catch (err) {
        console.error(err)
        alert("Erreur lors de l'upload du PDF")
      }
    },
    onImgError() {
      this.imgError = true
    },

    async uploadPDF(username, id, categorieId) {
      const formData = new FormData()
      formData.append("document", this.document.data)
      formData.append("userId", id)
      formData.append("documentName", this.document.name)
      formData.append("username", username.toLowerCase().replace(" ", ""))
      formData.append("categorieId", categorieId)
      formData.append("agencyName", localStorage.getItem("agency").toLowerCase())
      formData.append("place", "account")

      try {
        const res = await fetch(`${config.apiUri}/upload`, {
          method: "POST",
          body: formData,
        })
        if (res.ok) {
          this.initialize()
          this.fetchUsers()
          this.dialogUpload = false

          this.document = {
            name: "",
            data: null,
            categorie: null,
          }
        } else {
          alert("Erreur lors de l'upload du PDF")
        }
      } catch (err) {
        console.error(err)
        alert("Erreur lors de l'upload du PDF")
      }
    },
    onImgError() {
      this.imgError = true
    },

    // get image in folder asset for avatar and display error if not found
    getImg(lastname, firstname) {
      this.imgError = false

      try {
        let name = `${lastname.toLowerCase()}-${firstname.toLowerCase()}`
        let agency = localStorage.getItem("agency").toLowerCase().replace(/ /g, "_")
        let pictureProfilPng = `${config.ged}/${agency}/users/${name}/profil.png`

        let pictureProfilJpg = `${config.ged}/${agency}/users/${name}/profil.jpg`

        let img = new Image()
        img.onload = () => {
          this.avatarUrl = img.src
        }
        img.onerror = () => {
          this.imgError = true
          this.avatarUrl = null
        }

        // Test pour l'image .png
        img.src = pictureProfilPng

        // Si l'image .png n'est pas disponible, tester pour l'image .jpg
        setTimeout(() => {
          if (this.avatarUrl == null) {
            img.src = pictureProfilJpg
          }
        }, 500)
      } catch (error) {
        this.imgError = true
        this.avatarUrl == null
      }
    },
    async getImg2(lastname, firstname) {
      try {
        const name = `${lastname.toLowerCase().replace(" ", "")}-${firstname.toLowerCase()}`
        const agency = localStorage.getItem("agency").toLowerCase().replace(/ /g, "_")
        const pictureProfilPng = `${config.ged}/${agency}/users/${name}/profil.png`
        const pictureProfilJpg = `${config.ged}/${agency}/users/${name}/profil.jpg`

        // Create a Promise to handle image loading
        const loadImage = src => {
          return new Promise((resolve, reject) => {
            const img = new Image()
            img.onload = () => resolve(img.src)
            img.onerror = () => reject(null) // Failed to load image
            img.src = src
          })
        }

        // Attempt to load the PNG image
        let result = await loadImage(pictureProfilPng)

        // If the PNG image fails to load, try the JPG image
        if (!result) {
          result = await loadImage(pictureProfilJpg)
        }

        return result // Return the URL or null if no image is found
      } catch (error) {
        console.error("Error fetching avatar:", error)
        return null // Return null in case of any errors
      }
    },
    insertFile(documentId) {
      this.HRfiles.forEach(file => {
        if (file.id === documentId) {
          file.loading = true
          setTimeout(() => {
            file.loading = false
            file.available = !file.available
            this.HRfilesAvailableCount = this.HRfiles.filter(file => file.available === true).length
          }, 3000)
        }
      })
    },

    async disabled(data) {
      let headers = new Headers()

      headers.append("Content-Type", "application/json charset=UTF-8")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")
      try {
        let response
        if (data.status === 1) {
          if (this.desactivateData.idAccountLeaveReason === 5) {
            response = await fetch(
              `${config.apiUri}/php/pages/accounts.php?changeStatus=true&idAccount=${data.id}&disabled=${data.status}&dateEnd=${this.desactivateData.dateEnd}&idAccountLeaveReason=${this.desactivateData.idAccountLeaveReason}&dateApproval=${this.desactivateData.dateApproval}&dateAgreementApproval=${this.desactivateData.dateAgreementApproval}`,
              {
                mode: "cors",
                method: "PUT",
                headers: headers,
              },
            )
          } else {
            response = await fetch(
              `${config.apiUri}/php/pages/accounts.php?changeStatus=true&idAccount=${data.id}&disabled=${data.status}&dateEnd=${this.desactivateData.dateEnd}&idAccountLeaveReason=${this.desactivateData.idAccountLeaveReason}`,
              {
                mode: "cors",
                method: "PUT",
                headers: headers,
              },
            )
          }
        } else {
          response = await fetch(
            `${config.apiUri}/php/pages/accounts.php?changeStatus=true&idAccount=${data.id}&disabled=${data.status}`,
            {
              mode: "cors",
              method: "PUT",
              headers: headers,
            },
          )
        }

        if (data.status === 0) {
          this.$toast.success(`Le compte à bien été activé.`, {
            position: "bottom-right",
            timeout: 3000,
          })
        } else {
          this.$toast.success(`Le compte à bien été désactivé.`, {
            position: "bottom-right",
            timeout: 3000,
          })
        }
        this.initialize()
        this.fetchUsers()
      } catch (e) {
        console.log(e)
        this.ipLocation = `Une erreur est survenue.`
      }
    },
    copyClipboardIp() {
      let copyText = document.getElementById("ipInformation")

      try {
        copyText.select()
        navigator.clipboard.writeText(copyText.value)

        this.$toast.success(`Le texte a été copié dans votre presse-papier.`, {
          position: "bottom-right",
          timeout: 3000,
        })
      } catch (e) {
        this.$toast.error(`Impossible de copier le texte dans votre presse-papier.`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async fetchType() {
      let headers = new Headers()

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      await pause(1500)

      try {
        let response = await fetch(`${config.apiUri}/type_contract`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const data = await response.json()

        if (data.code === 1) {
          data.type_employments.forEach(employment => {
            this.typeEmployments.push(employment)
          })
          data.type_contracts.forEach(contract => {
            this.typeContracts.push(contract)
          })
          data.type_jobs.forEach(job => {
            this.typeJobs.push(job)
          })
          data.type_qualifications.forEach(qualification => {
            this.typeQualifications.push(qualification)
          })
        }
      } catch (e) {
        console.log(e)
        this.$toast.error("Impossible d'accéder aux données lié aux comptes.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async fetchServices() {
      this.services = []
      this.nodeChart = []
      this.fetchServicesState = true
      let headers = new Headers()
      let idAgency = localStorage.getItem("agency_id")

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      await pause(1500)

      try {
        let response

        response = await fetch(`${config.apiUri}/agencies/${idAgency}/services`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        if (data.code === 1) {
          data.services.forEach(element => {
            this.services.push({
              id_service: element.id_service_manager,
              name: element.name,
            })
          })
        }
      } catch (e) {
        console.log(e)
        this.$toast.error("Impossible d'accéder aux données lié aux services.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },

    async fetchFileCategories() {
      let categories = []
      let headers = new Headers()
      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      await pause(1500)

      try {
        let response = await fetch(`${config.apiUri}/filecategories`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const data = await response.json()

        data.categories.forEach(categorie => {
          categories.push(categorie)
        })
      } catch (e) {
        console.log(e)
        this.$toast.error("Impossible d'accéder aux données lié aux comptes.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }

      return categories
    },

    async fetchFilesByCategorieId(userId, categorieId) {
      let files = []

      let headers = new Headers()
      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      await pause(1500)

      try {
        let response = await fetch(`${config.apiUri}/accounts/${userId}/files/${categorieId}`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const data = await response.json()

        data.forEach(file => {
          files.push(file)
        })
      } catch (e) {
        console.log(e)
        this.$toast.error("Impossible d'accéder aux données lié aux comptes.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }

      return files
    },

    async fetchFiles(userId) {
      let files = []

      let headers = new Headers()
      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      await pause(1500)

      try {
        let response = await fetch(`${config.apiUri}/accounts/${userId}/files`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const data = await response.json()

        data.forEach(file => {
          files.push(file)
        })
      } catch (e) {
        console.log(e)
        this.$toast.error("Impossible d'accéder aux données lié aux comptes.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }

      return files
    },
    countNullFiles(files) {
      let nullFile = 0

      files.forEach(file => {
        if (file.url === null) {
          nullFile++
        }
      })

      return nullFile
    },
    countHR(files, selected) {
      let length = files.length
      let fileAccept = 0

      files.forEach(file => {
        if (file.url != null) {
          fileAccept++
        }
      })

      let result = (fileAccept / length) * 100
      selected.hrFilesComplet = Math.round(result, 2)

      return Math.round(result, 2)
    },
    async fetchIp(ip) {
      this.load = true

      this.ipError = false
      this.ipInfo = []
      const token = "d94b113c642ae8"
      let headers = new Headers()

      headers.append("Content-Type", "application/json charset=UTF-8")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      try {
        let response = await fetch(`https://ipinfo.io/${ip}?token=${token}`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const data = await response.json()

        this.ipInfo = JSON.stringify(data, null, "\t")

        this.ip = data.ip

        if (!!data.city) {
          this.ipLocation = `${data.city}, ${data.region} (${data.country})`
        } else {
          this.ipError = true
          this.ipLocation = "Adresse non géolocalisable"
        }
        this.load = false
      } catch (e) {
        console.log(e)
        this.ipLocation = `Position inconnu`
      }
    },
    validateFormPassword(id) {
      if (this.password.length >= 12 && this.password === this.passwordValidation) {
        if (this.$refs.form.validate()) {
          this.save(id)
        }
      } else {
        this.passwordErrorMessage = "Les mots de passes doivent correspondre"
      }
    },
    getCurrentDate() {
      var today = new Date()
      var date = today.getDate() + "/" + (today.getMonth() + 1) + "/" + today.getFullYear()
      var time = today.getHours() + ":" + today.getMinutes()
      this.date = date
      this.time = time
    },
    async fetchUsers() {
      this.usersUnavailableCount = 0
      this.usersEnabledCount = 0
      this.usersDisabledCount = 0
      this.loading = true
      this.waitTimeout = true
      this.loadingFiles = true
      this.loadingUserData = true
      const now = moment().format("YYYY-MM-DD")

      await pause(1500)

      try {
        let headers = new Headers()
        let userId = localStorage.getItem("user_id")

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        let response
        if (localStorage.getItem("master_account_linked") != null) {
          let masterAccountId = localStorage.getItem("master_account_linked")
          response = await fetch(`${config.apiUri}/users/${masterAccountId}/account`, {
            mode: "cors",
            method: "GET",
            headers: headers,
          })
        } else {
          response = await fetch(`${config.apiUri}/users/${userId}/account`, {
            mode: "cors",
            method: "GET",
            headers: headers,
          })
        }

        const data = await response.json()

        this.getCurrentDate()
        localStorage.setItem("update_time", this.date + " " + this.time)

        // Créez un tableau de promesses pour récupérer les avatars de manière asynchrone
        const avatarPromises = data.map(async element => {
          const avatarUrl = await this.getImg2(element.lastname, element.firstname)
          return {
            element,
            avatarUrl,
          }
        })

        // Utilisez Promise.all pour attendre que toutes les promesses d'avatar soient résolues
        const avatarResults = await Promise.all(avatarPromises)

        avatarResults.forEach(({ element, avatarUrl }) => {
          moment(now).diff(moment(element.entry_date).format("YYYY-MM-DD"), "days") >= 6
            ? (element.career = true)
            : (element.career = false)

          element.current_month_costs.forEach(cost => {
            cost.documents.length === 0 ? (cost.documents = []) : (cost.documents = JSON.parse(cost.documents))
            cost.data != null ? (cost.data = JSON.parse(cost.data)) : (cost.data = null)
          })
          element.child = 0
          element.family.forEach(member => {
            if (member.id_account_family_link === 2) {
              element.child++
            }
          })
          let remainingArray = []

          if (element.remaining.length > 0) {
            remainingArray = [
              {
                name: "Congés payés",
                lastYear: element.remaining[0].remain_paid_leave_last_year,
                currentYear: element.remaining[0].remain_paid_leave,
              },
            ]
          }

          if (element.contract.length > 0) {
            if (element.contract[0].employment_type_id === 2) {
              remainingArray.push({
                name: "JFC",
                lastYear: element.remaining[0].compensatory_package_leave_last_year,
                currentYear: element.remaining[0].compensatory_package_leave,
              })
            } else {
              remainingArray.push({
                name: "RTT",
                lastYear: element.remaining[0].recovery_working_time_leave_last_year,
                currentYear: element.remaining[0].recovery_working_time_leave,
              })
            }
          }
          // element.training.forEach(training => {
          //   training.total_hours = moment("00:00", "HH:mm")
          //   training.files === null ? (training.files = []) : (training.files = JSON.parse(training.files))
          //   training.files.forEach(file => {
          //     training.total_hours.add(parseInt(file.hour), "hours")
          //     console.log(parseInt(file.hour))
          //     // convert "01" to "1"

          //     training.total_hours.add(parseInt(file.minute), "minutes")
          //     training.total_hours_display = moment(training.total_hours).format("HH:mm")
          //   })
          //   // training.time_missing = moment(mandatoryHours.hours())
          //   //   .subtract(training.time_passed, "hours")
          //   //   .add(5, "hours")
          //   //   .format("HH:mm")
          // })
          // console.log(element.maintenance)
          // console.log("elem", element.maintenance.information)

          if (parseInt(element.disabled) === 0) {
            this.users.push({
              id: element.id,
              firstname: "An",
              lastname: "Avel",
              lastConnection: moment(element.connection_date).calendar(), // Aujourd’hui à 11:46
              lastIPConnected: element.last_ip,
              statut: element.grade,
              email: element.email,
              phoneNumber: element.phone_number,
              completeName: `${element.lastname} ${element.firstname}`,
              agency: element.agency,
              // color: "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0"),
              color: "#001F47",
              initial: element.firstname[0] + element.lastname[0],
              dbl: parseInt(element.disabled),
              services: element.services,
              contract: element.contract,
              serviceChoose: element.services.length > 0 ? element.services[0].id_service_manager : 0,
              idTypeContract: element.contract.length > 0 ? element.contract[0].contract_type_id : 0,
              idTypeEmployment: element.contract.length > 0 ? element.contract[0].employment_type_id : 0,
              idTypeJob: element.contract.length > 0 ? element.contract[0].job_type_id : 0,
              idTypeQualification: element.contract.length > 0 ? element.contract[0].qualification_type_id : 0,
              street: element.street,
              city: element.city,
              zip: element.zip,
              costs: element.current_month_costs,
              avatarUrl: avatarUrl,
              entryDate: element.entry_date,
              entryDateDisplay: moment(element.entry_date).format("Do MMMM YYYY"),
              idLeaveReason: parseInt(element.id_leave_reason),
              leaveReason: element.leave_reason,
              endAgreementApprovalDate: element.end_agreement_approval_date,
              endAgreementApplicationDate: element.end_agreement_application_date,
              endDate: element.end_date,
              endDateDisplay: moment(element.end_date).format("Do MMMM YYYY"),
              familyMembers: element.family,
              childCount: element.child,
              marital_category: element.marital_category,
              idMaritalStatus: element.id_marital_category,
              idService: element.services.length > 0 ? element.services[0].id_service_manager : null,
              training: element.training,
              remaining: remainingArray,
              maintenance:
                element.maintenance.information != undefined ? JSON.parse(element.maintenance.information) : [],
            })
          } else {
            this.usersDisabled.push({
              id: element.id,
              firstname: element.firstname,
              lastname: element.lastname,
              lastConnection: moment(element.connection_date).calendar(), // Aujourd’hui à 11:46
              lastIPConnected: element.last_ip,
              statut: element.grade,
              email: element.email,
              phoneNumber: element.phone_number,
              completeName: `${element.lastname} ${element.firstname}`,
              agency: element.agency,
              // color: "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0"),
              color: "#001F47",
              initial: element.firstname[0] + element.lastname[0],
              dbl: parseInt(element.disabled),
              services: element.services,
              contract: element.contract,
              serviceChoose: element.services.length > 0 ? element.services[0].id_service_manager : 0,
              idTypeContract: element.contract.length > 0 ? element.contract[0].contract_type_id : 0,
              idTypeEmployment: element.contract.length > 0 ? element.contract[0].employment_type_id : 0,
              idTypeJob: element.contract.length > 0 ? element.contract[0].job_type_id : 0,
              idTypeQualification: element.contract.length > 0 ? element.contract[0].qualification_type_id : 0,
              street: element.street,
              city: element.city,
              zip: element.zip,
              costs: element.current_month_costs,
              avatarUrl: avatarUrl,
              entryDate: element.entry_date,
              entryDateDisplay: moment(element.entry_date).format("Do MMMM YYYY"),
              idLeaveReason: parseInt(element.id_leave_reason),
              leaveReason: element.leave_reason,
              endAgreementApprovalDate: element.end_agreement_approval_date,
              endAgreementApplicationDate: element.end_agreement_application_date,
              endDate: element.end_date,
              endDateDisplay: moment(element.end_date).format("Do MMMM YYYY"),
              familyMembers: element.family,
              childCount: element.child,
              marital_category: element.marital_category,
              idMaritalStatus: element.id_marital_category,
              idService: element.services.length > 0 ? element.services[0].id_service_manager : null,
              training: element.training,
              remaining: element.remaining[0],

              maintenance:
                element.maintenance.information != undefined ? JSON.parse(element.maintenance.information) : [],
            })
          }
          if (parseInt(element.available) === 0 && parseInt(element.disabled) === 0) {
            this.usersUnavailableCount++
            this.usersUnavailable.push({
              id: element.id,
              firstname: element.firstname,
              lastname: element.lastname,
            })
          }
          parseInt(element.disabled) === 0 ? this.usersEnabledCount++ : this.usersDisabledCount++
        })
        // this.fetchFileCategories()

        if (this.users.length > 0) {
          this.users.forEach(element => {
            this.fetchFileCategories()
              .then(categories => {
                categories.forEach(categorie => {
                  categorie.files = this.fetchFilesByCategorieId(element.id, categorie.id).then(files => {
                    categorie.files = files
                  })
                })
                element.categories = categories
              })
              .finally(() => {
                pause(4000).then(() => {
                  this.loadedFiles = true
                  this.loadingFiles = false
                  this.loading = false
                })
              })
              .catch(e => {
                console.log(e)
              })

            this.loading = false
            this.fetch = true
            this.waitTimeout = false

            // element.files = this.fetchFiles(element.id).then(files => {
            //   element.files = files
            //   element.hrFilesComplet = this.countHR(element.files)
            // })
            this.usersDisabled.forEach(element => {
              this.fetchFileCategories()
                .then(categories => {
                  categories.forEach(categorie => {
                    categorie.files = this.fetchFilesByCategorieId(element.id, categorie.id).then(files => {
                      categorie.files = files
                    })
                  })
                  element.categories = categories
                })
                .finally(() => {
                  pause(4000).then(() => {
                    this.loadedFiles = true
                    this.loadingFiles = false
                    this.loading = false
                  })
                })
                .catch(e => {
                  console.log(e)
                })
            })
          })
        } else {
          this.waitTimeout = false

          this.loadingFiles = false
        }

        this.open = [1]

        // this.loading = false
        // this.fetch = true
        // this.waitTimeout = false

        // this.fetchServices()

        // await pause(3000)

        // this.loadedFiles = true
        // this.loadingFiles = false
        // this.fetchCostByUserId()
      } catch (e) {
        console.log(e)
        this.loading = false
        this.fetch = false
        this.$toast.error("Impossible d'accéder aux données de l'équipe.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },

    getLength() {
      return this.users.length
    },
    initialize() {
      this.users = []
      this.usersDisabled = []
    },

    editItem(item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm(id) {
      this.users.splice(this.editedIndex, 1)

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        fetch(`${config.apiUri}/accounts/${id}`, {
          mode: "cors",
          method: "DELETE",
          headers: headers,
        })
          .then(res => res.json()) // or res.json()
          .then(() => {
            this.$toast.info(`Le compte a correctement été supprimé.`, {
              position: "bottom-right",
              timeout: 3000,
            })
          })
      } catch (e) {
        this.$toast.error(`Une erreur est survenue.\nImpossible de supprimer le compte`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }

      this.closeDelete()
      this.initialize()

      this.fetchUsers()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    async put(idUser, data) {
      const formData = new FormData()
      formData.append("place", "putAccount")
      formData.append("id", idUser)
      formData.append("lastname", data.lastname)
      formData.append("firstname", data.firstname)
      formData.append("idState", 2)
      formData.append("email", data.email)
      formData.append("phoneNumber", data.phoneNumber)
      formData.append("idServiceManager", data.idServiceManager)
      formData.append("idTypeContract", data.idTypeContract)
      formData.append("idTypeJob", data.idTypeJob)
      formData.append("idTypeEmployment", data.idTypeEmployment)
      formData.append("idTypeQualification", data.idTypeQualification)
      formData.append("entryDate", data.entryDate)
      formData.append("idService", data.idService)
      formData.append("street", data.street)
      formData.append("city", data.city)
      formData.append("zip", data.zip)
      formData.append("idMaritalStatus", data.idMaritalStatus)

      data.familyMembers.length > 0 ? formData.append("familyMembers", JSON.stringify(data.familyMembers)) : null

      try {
        const response = await fetch(`${config.apiUri}/php/pages/accounts.php`, {
          mode: "cors",
          method: "POST",
          body: formData,
        })
        this.$toast.info(
          `Vous venez de modifier le compte ${data.lastname.toUpperCase()} ${data.firstname.toUpperCase()} avec succès.`,
          {
            position: "bottom-right",
            timeout: 3000,
          },
        )
        this.initialize()

        this.fetchUsers()
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible de modifier le compte`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },

    sendmail(data) {
      try {
        emailjs.send("service_5p49t7p", "template_w0zk5rq", {
          to_name: data.lastname + " " + data.firstname,
          //from_name: email,
          to_email: data.email,
        })
      } catch (error) {
        console.log(error)
      }
    },

    async postContract(data, idAccount) {
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json charset=UTF-8")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `${config.apiUri}/type_contract?createContract=true&id=${idAccount}&idTypeContract=${data.idTypeContract}&idTypeJob=${data.idTypeJob}&idTypeEmployment=${data.idTypeEmployment}&idTypeQualification=${data.idTypeQualification}`,
          {
            mode: "cors",
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
          },
        )
        this.initialize()
        this.fetchUsers()
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible de configurer le contrat de travail du compte.`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },

    async post(data) {
      try {
        let agencyId = localStorage.getItem("agency_id")
        const formData = new FormData()
        formData.append("place", "addAccount")
        formData.append("lastname", data.lastname)
        formData.append("firstname", data.firstname)
        formData.append("idState", 2)
        formData.append("email", data.email)
        formData.append("phoneNumber", data.phoneNumber)
        formData.append("idMasterAccount", data.idMasterAccount)
        formData.append("password", data.password)
        formData.append("agencyId", agencyId)
        formData.append("idServiceManager", data.idServiceManager)
        formData.append("idTypeContract", data.idTypeContract)
        formData.append("idTypeJob", data.idTypeJob)
        formData.append("idTypeEmployment", data.idTypeEmployment)
        formData.append("idTypeQualification", data.idTypeQualification)
        formData.append("entryDate", data.entryDate)
        formData.append("idService", data.idService)
        formData.append("street", data.street)
        formData.append("city", data.city)
        formData.append("zip", data.zip)
        formData.append("idMaritalStatus", data.idMaritalStatus)

        data.familyMembers.length > 0 ? formData.append("familyMembers", JSON.stringify(data.familyMembers)) : null

        const response = await fetch(`${config.apiUri}/php/pages/accounts.php`, {
          mode: "cors",
          method: "POST",
          body: formData,
        })

        this.sendmail(data)

        this.$toast.success(
          `Vous avez ajouté un nouveau compte.\n${data.lastname.toUpperCase()} ${data.firstname.toUpperCase()} peut désormais se connecter.`,
          {
            position: "bottom-right",
            timeout: 3000,
          },
        )

        this.initialize()
        this.fetchUsers()
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible d'ajouter un nouveau compte`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },

    save(idUser) {
      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], this.editedItem)
        this.put(idUser, {
          lastname: this.editedItem.lastname,
          firstname: this.editedItem.firstname,
          grade: this.editedItem.statut,
          email: this.editedItem.email,
          phoneNumber: this.editedItem.phoneNumber,
          idServiceManager: this.editedItem.serviceChoose,
          idTypeContract: this.editedItem.idTypeContract,
          idTypeJob: this.editedItem.idTypeJob,
          idTypeEmployment: this.editedItem.idTypeEmployment,
          idTypeQualification: this.editedItem.idTypeQualification,
          familyMembers: this.editedItem.familyMembers,
          entryDate: this.editedItem.entryDate,
          idService: this.editedItem.idService,
          street: this.editedItem.street,
          city: this.editedItem.city,
          zip: this.editedItem.zip,
          idMaritalStatus: this.editedItem.idMaritalStatus,
        })
      } else {
        this.users.push(this.editedItem)
        this.post({
          lastname: this.editedItem.lastname,
          firstname: this.editedItem.firstname,
          email: this.editedItem.email,
          phoneNumber: this.editedItem.phoneNumber,
          idMasterAccount:
            localStorage.getItem("linked") != null
              ? localStorage.getItem("master_account_linked")
              : localStorage.getItem("user_id"),
          password: this.password,
          idServiceManager: this.editedItem.serviceChoose,
          idTypeContract: this.editedItem.idTypeContract,
          idTypeJob: this.editedItem.idTypeJob,
          idTypeEmployment: this.editedItem.idTypeEmployment,
          idTypeQualification: this.editedItem.idTypeQualification,
          familyMembers: this.editedItem.familyMembers,
          entryDate: this.editedItem.entryDate,
          idService: this.editedItem.idService,
          street: this.editedItem.street,
          city: this.editedItem.city,
          zip: this.editedItem.zip,
          idMaritalStatus: this.editedItem.idMaritalStatus,
        })
      }
      this.close()
    },

    upRH() {
      this.valueHRfiles += 10

      if (this.valueHRfiles > 100) {
        this.valueHRfiles = 0
      }
    },
  },
}
</script>
<style lang="scss">
.fa-icone {
  font-size: 1.2rem;
}

.highlighted:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
}

.highlighted:hover:before {
  visibility: visible;
}

.image__zoom-icon {
  // position icon in middle of image
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
}

.drop-zone {
  /* styles de base */
}

.drop-zone:hover {
  /* styles lorsque la souris est sur la zone de dépôt */
  background-color: #f0f0f0;
  border: 2px dashed #333;
}

.drop-zone.file-selected:hover {
  /* styles lorsque l'utilisateur a déjà sélectionné un fichier et qu'il s'apprête à le déposer */
  background-color: #f0f0f0;
  border: 2px dashed #333;
}

.drop-zone-error {
  /* styles de base */
}

.drop-zone-error:hover {
  /* styles lorsque la souris est sur la zone de dépôt */
  background-color: #f0f0f0;
  border: 2px dashed #c1272d;
}

.drop-zone-error.file-selected:hover {
  /* styles lorsque l'utilisateur a déjà sélectionné un fichier et qu'il s'apprête à le déposer */
  background-color: #f0f0f0;
  border: 2px dashed #333;
}

// if media screen is more than 1250px width then apply the following styles
@media screen and (min-width: 2000px) {
  .profilCandidateBadge {
    position: absolute;
    top: 150px;
    z-index: 0;
    width: 0%;
    position: absolute;
    right: 120px;
  }
}

@media screen and (min-width: 1500px) {
  .profilCandidateBadge {
    position: absolute;
    top: 45px;
    z-index: 0;
    width: 0%;
    position: absolute;
    right: 120px;
  }
}

@media screen and (max-width: 1500px) {
  .profilCandidateBadge {
    position: absolute;
    top: 150px;
    z-index: 0;
    width: 0%;
    position: absolute;
    right: 120px;
  }
}

.profilBanner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  height: 100%;
  width: 100%;
}

.no-padding {
  padding: 0 !important;
}

.v-treeview:hover {
  cursor: pointer;
}

.v-progress-circular {
  font-size: 12px;
}

// hide button in list
.no-bullets {
  list-style: none;
  padding: 0;
  margin: 0;
}

.item-explain {
  -webkit-line-clamp: 4 !important;
}
</style>
