<template>
  <v-container fluid>
    <!-- <custome-loader v-if="loadingFiles"></custome-loader> -->
    <loading
      transition="fade"
      :active.sync="waitTimeout"
      :can-cancel="false"
      :is-full-page="false"
      loader="spinner"
      color="#efb639"
      :opacity="1"
    >
    </loading>
    <v-dialog v-model="dialogDelete" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5"> <v-icon>mdi-delete</v-icon> Supression du compte </span>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-container>
            <h3>Êtes-vous sûr de vouloir supprimer le compte : {{ editedItem.completeName }} ?</h3>
            <p>
              En supprimant ce compte, vous consentez à la suppression des données à caractère personnelle lié à ce
              compte. Vous consentez également à ne plus pouvoir consulter :
            </p>
            <ul>
              <li>Les statistiques</li>
              <li>Les données enregistrées (Nom, prénom, ip...)</li>
              <li>Les données bancaires</li>
            </ul>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="EoleYellow" text @click="closeDelete">Annuler</v-btn>
          <v-btn class="white--text" color="EoleError" @click="deleteItemConfirm(editedItem.id)">
            Confirmer la suppression</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row class="mb-5 text-center my-5">
      <v-col class="d-flex" cols="12" md="3">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card
              class="flex d-flex flex-column rounded-xl"
              elevation="3"
              link
              v-bind="attrs"
              v-on="on"
              outlined
              @click="showCandidate = false"
            >
              <v-card-title class="text-center justify-center">Personnel de l'entreprise</v-card-title>
              <v-row v-if="usersEnabledCount > 0" align="center">
                <v-card-text>
                  <v-icon size="48" color="EoleGreen">mdi-check-circle</v-icon>
                  <h2>{{ usersEnabledCount }}</h2>
                </v-card-text>
              </v-row>
              <v-row v-else align="center">
                <v-card-text>
                  <p>
                    Vous n'avez pour le moment pas d'utilisateur lié à votre compte. Vous pouvez en ajouter un
                    ci-dessous.
                  </p>
                </v-card-text>
              </v-row>
            </v-card>
          </template>
          <span
            >Les utilisateurs actifs ont encore les droits d'accès à la plateforme. Ils peuvent utiliser tous les
            outils.</span
          >
        </v-tooltip>
      </v-col>

      <v-col class="d-flex" cols="12" md="3">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-card class="flex d-flex flex-column rounded-xl" elevation="3" link v-bind="attrs" v-on="on" outlined>
              <v-card-title class="text-center justify-center">Utilisateurs indisponible</v-card-title>
              <v-row v-if="usersUnavailableCount > 0" align="center">
                <v-card-text>
                  <v-avatar size="56px" color="EoleError white--text">
                    <i class="fa-solid fa-user-large-slash"></i>
                  </v-avatar>
                  <h2>{{ usersUnavailableCount }}</h2>
                </v-card-text>
              </v-row>
              <v-row v-else align="center">
                <v-card-text>
                  <p>Il n'y a pas d'utilisateur désactivé.</p>
                </v-card-text>
              </v-row>
            </v-card>
          </template>
          <span>
            <ul>
              <li v-for="(user, index) in usersUnavailable" v-bind:key="index">
                {{ user.lastname }} {{ user.firstname }}
              </li>
            </ul>
          </span>
        </v-tooltip>
      </v-col>
      <v-col class="d-flex" cols="12" md="3">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card
              class="flex d-flex flex-column rounded-xl"
              elevation="3"
              link
              v-bind="attrs"
              v-on="on"
              outlined
              @click="showCandidate = true"
            >
              <v-card-title class="text-center justify-center">Vivier</v-card-title>

              <v-row align="center">
                <v-card-text>
                  <i class="fa-solid fa-user-secret fa-3x EoleBlue--text"></i>
                  <h2>50</h2>
                </v-card-text>
              </v-row>
            </v-card>
          </template>
          <span
            >Les utilisateurs désactivés se verront bloqué leurs accès à la plateforme. Vous aurez toutefois encore
            accès à leurs données personnelles.
          </span>
        </v-tooltip>
      </v-col>
      <v-col class="d-flex" cols="12" md="3">
        <v-dialog v-model="dialog" max-width="1200px">
          <template v-slot:activator="{ on, attrs }">
            <v-card v-bind="attrs" v-on="on" outlined flat elevation="3" class="flex d-flex flex-column rounded-xl">
              <v-card-title></v-card-title>
              <v-row align="center"
                ><v-card-text>
                  <v-icon size="48" color="EoleBlue">mdi-plus-circle</v-icon>
                  <p class="text-h6">Ajouter un collaborateur</p>
                </v-card-text>
              </v-row>
            </v-card>
            <!-- <v-btn id="btn-add-account" icon color="EoleBlue" v-bind="attrs" v-on="on" large>
              <v-icon> mdi-account-multiple-plus </v-icon>
            </v-btn> -->
          </template>
          <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
            <v-card-text style="background-color: #f1f4f9">
              <v-row>
                <v-col cols="12" md="3" class="no-padding ml-n2">
                  <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                    <v-card-title>
                      <v-img
                        :src="require('@/assets/images/logos/anavel_logo.png')"
                        max-height="150px"
                        max-width="120px"
                        alt="logo"
                        contain
                      ></v-img>
                    </v-card-title>
                    <v-card-title class="text-center justify-center muller-capitalized"
                      >Mes Services Assurance</v-card-title
                    >
                    <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                    <v-card-text class="no-padding">
                      <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class="">
                      </v-img>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="9" class="no-padding d-flex">
                  <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                    <v-card-title>
                      <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>
                    <v-stepper v-model="stepperAccount" flat>
                      <v-stepper-items>
                        <el-steps :active="stepperAccount" finish-status="success" simple>
                          <el-step title="Étape 1"></el-step>
                          <el-step title="Étape 2"></el-step>
                          <el-step title="Étape 3"></el-step>
                          <el-step title="Étape 4"></el-step>
                        </el-steps>

                        <v-stepper-content step="0" class="no-padding">
                          <v-card-title>
                            <span class="text-h5">Informations professionnelles</span>
                          </v-card-title>
                          <v-card-text class="mt-5">
                            <v-form ref="form" v-model="validityFormPassword" lazy-validation>
                              <v-row>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    id="new-account-lastname"
                                    dense
                                    prepend-inner-icon="mdi-account"
                                    outlined
                                    :rules="basicRules"
                                    :error-messages="lastnameErrorMessage"
                                    v-model="editedItem.lastname"
                                    label="Nom utilisateur"
                                  >
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    id="new-account-firstname"
                                    dense
                                    prepend-inner-icon="mdi-account"
                                    outlined
                                    :rules="basicRules"
                                    :error-messages="firstnameErrorMessage"
                                    v-model="editedItem.firstname"
                                    label="Prénom utilisateur"
                                  >
                                  </v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="6" md="6">
                                  <v-select
                                    id="new-account-status"
                                    dense
                                    prepend-inner-icon="mdi-cogs"
                                    outlined
                                    value="editedItem.statut"
                                    v-model="editedItem.statut"
                                    :items="states"
                                    item-text="name"
                                    item-value="id"
                                    label="Type d'utilisateur"
                                  ></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-select
                                    id="new-account-service"
                                    dense
                                    prepend-inner-icon="mdi-cogs"
                                    outlined
                                    :items="services"
                                    item-text="name"
                                    item-value="id_service"
                                    label="Service"
                                    v-model="editedItem.idService"
                                  ></v-select>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="6" md="6">
                                  <v-select
                                    v-if="editedIndex === -1"
                                    outlined
                                    dense
                                    label="Lieu de travail principal"
                                    :items="getActiveAgencies"
                                    item-value="id"
                                    item-text="city"
                                    v-model="searchEtablishment"
                                  ></v-select>
                                  <v-select
                                    v-else
                                    outlined
                                    dense
                                    label="Lieu de travail principal"
                                    :items="getActiveAgencies"
                                    item-value="id"
                                    item-text="city"
                                    v-model="editedItem.etablishment"
                                    @change="getAgency"
                                  ></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    id="new-account-street"
                                    dense
                                    prepend-inner-icon="mdi-map-marker"
                                    outlined
                                    v-model="editedItem.mainWorkplaceStreet"
                                    label="Adresse"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    id="new-account-street"
                                    dense
                                    prepend-inner-icon="mdi-mailbox"
                                    outlined
                                    v-model="editedItem.mainWorkplaceZip"
                                    label="Code postal"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    id="new-account-street"
                                    dense
                                    prepend-inner-icon="mdi-city"
                                    outlined
                                    v-model="editedItem.mainWorkplaceCity"
                                    label="Ville"
                                  ></v-text-field>

                                  <!-- <v-autocomplete
                                    id="new-account-city"
                                    messages="Entrez un code postal"
                                    :search-input.sync="searchCityProfessionnal"
                                    :items="cityItemsProfessionnal"
                                    outlined
                                    v-model="editedItem.mainWorkplaceCity"
                                    :value="editedItem.mainWorkplaceZip"
                                    label="Ville"
                                    clearable
                                    hide-details
                                    hide-selected
                                    dense
                                    no-filter
                                  >
                                    <template v-slot:no-data>
                                      <v-list-item>
                                        <v-list-item-title> Entrez un code postal </v-list-item-title>
                                      </v-list-item>
                                    </template>

                                    <template v-slot:item="{ item }">
                                      <v-list-item-content>
                                        <v-list-item-title>{{ item }}</v-list-item-title>
                                      </v-list-item-content>
                                    </template></v-autocomplete
                                  > -->
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    id="new-account-email"
                                    dense
                                    prepend-inner-icon="mdi-email"
                                    outlined
                                    :rules="emailRules"
                                    :error-messages="emailErrorMessage"
                                    v-model="editedItem.email"
                                    label="Mail professionnel"
                                  >
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    id="new-account-phone"
                                    dense
                                    prepend-inner-icon="mdi-phone"
                                    outlined
                                    :rules="phoneNumberRules"
                                    :error-messages="phoneNumberErrorMessage"
                                    v-model="editedItem.phoneNumberProfessional"
                                    label="N° professionnel"
                                  >
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-card-text>
                        </v-stepper-content>
                        <v-stepper-content step="1" class="no-padding">
                          <v-card-title>
                            <span class="text-h5">Informations personnelles</span>
                          </v-card-title>

                          <v-card-text>
                            <v-form ref="form">
                              <v-row>
                                <v-col cols="12" md="4">
                                  <v-text-field
                                    id="new-account-street"
                                    dense
                                    prepend-inner-icon="mdi-map-marker"
                                    outlined
                                    v-model="editedItem.street"
                                    label="Adresse personnelle"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-text-field
                                    id="new-account-street"
                                    dense
                                    prepend-inner-icon="mdi-mailbox"
                                    outlined
                                    v-model="editedItem.zip"
                                    label="Code postal personnel"
                                  ></v-text-field>
                                  <!-- <v-autocomplete
                                    id="new-account-city"
                                    messages="Entrez un code postal"
                                    :search-input.sync="searchCity"
                                    :items="cityItems"
                                    outlined
                                    v-model="editedItem.city"
                                    :value="editedItem.zip"
                                    label="Ville"
                                    clearable
                                    hide-details
                                    hide-selected
                                    dense
                                    no-filter
                                  >
                                    <template v-slot:no-data>
                                      <v-list-item>
                                        <v-list-item-title> Entrez un code postal </v-list-item-title>
                                      </v-list-item>
                                    </template>

                                    <template v-slot:item="{ item }">
                                      <v-list-item-content>
                                        <v-list-item-title>{{ item }}</v-list-item-title>
                                      </v-list-item-content>
                                    </template></v-autocomplete
                                  > -->
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-text-field
                                    id="new-account-street"
                                    dense
                                    prepend-inner-icon="mdi-city"
                                    outlined
                                    v-model="editedItem.city"
                                    label="Ville"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    id="new-account-email"
                                    dense
                                    prepend-inner-icon="mdi-email"
                                    outlined
                                    :rules="emailRules"
                                    :error-messages="emailErrorMessage"
                                    v-model="editedItem.personnalEmail"
                                    label="Mail personnel"
                                  >
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    id="new-account-phone"
                                    dense
                                    prepend-inner-icon="mdi-phone"
                                    outlined
                                    :rules="phoneNumberRules"
                                    :error-messages="phoneNumberErrorMessage"
                                    v-model="editedItem.personnalPhoneNumber"
                                    label="N° personnel"
                                  >
                                  </v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="6" md="6">
                                  <v-select
                                    dense
                                    prepend-inner-icon="mdi-human-male-female-child"
                                    outlined
                                    v-model="editedItem.idMaritalStatus"
                                    :items="[
                                      {
                                        id: 1,
                                        designation: 'Célibataire',
                                      },

                                      {
                                        id: 2,
                                        designation: 'En concubinage',
                                      },
                                      {
                                        id: 3,
                                        designation: 'Marié(e)',
                                      },
                                      {
                                        id: 4,
                                        designation: 'Pacsé(e)',
                                      },
                                      {
                                        id: 5,
                                        designation: 'Divorcé(e)',
                                      },
                                      {
                                        id: 6,
                                        designation: 'Veuf(ve)',
                                      },
                                    ]"
                                    item-text="designation"
                                    item-value="id"
                                    label="Situation familiale"
                                  ></v-select>
                                </v-col>
                                <v-col cols="12" md="6" class="mt-n3">
                                  <v-switch
                                    inset
                                    v-model="editedItem.hasChildren"
                                    label="Avez-vous des enfants ?"
                                  ></v-switch>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-card-text>

                          <v-card-text
                            class="no-padding mb-10"
                            v-if="
                              editedItem.hasChildren ||
                              editedItem.idMaritalStatus === 3 ||
                              editedItem.idMaritalStatus === 2 ||
                              editedItem.idMaritalStatus === 4
                            "
                          >
                            <v-form>
                              <v-simple-table dense class="no-padding">
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-center">Nature du lien</th>
                                      <th class="text-center">Nom de famille</th>
                                      <th class="text-center">Prénom</th>
                                      <th class="text-center">Contact d'urgence</th>
                                      <th class="text-center">Téléphone</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(familyMember, index) in editedItem.familyMembers" v-bind:key="index">
                                      <td class="text-left">
                                        <v-select
                                          class="mt-6"
                                          dense
                                          :id="`input-family-${index}`"
                                          outlined
                                          v-model="familyMember.id_account_family_link"
                                          :items="familyOptions"
                                          item-text="designation"
                                          item-value="id"
                                        ></v-select>
                                      </td>
                                      <td class="text-left">
                                        <v-text-field
                                          ref="newTextField"
                                          outlined
                                          class="mt-6"
                                          dense
                                          v-model="familyMember.lastname"
                                          single-line
                                        ></v-text-field>
                                      </td>

                                      <td class="text-left">
                                        <v-text-field
                                          ref="newTextField"
                                          outlined
                                          class="mt-6"
                                          dense
                                          v-model="familyMember.firstname"
                                          single-line
                                        ></v-text-field>
                                      </td>

                                      <td class="text-center justify-center">
                                        <v-switch class="ml-7" inset v-model="familyMember.is_emergency"> </v-switch>
                                      </td>
                                      <td class="text-left">
                                        <v-text-field
                                          v-if="familyMember.is_emergency"
                                          ref="newTextField"
                                          outlined
                                          class="mt-6"
                                          dense
                                          v-model="familyMember.phone_number"
                                          single-line
                                        ></v-text-field>
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                              <v-row>
                                <v-col cols="12" md="4">
                                  <v-btn
                                    style="text-transform: none"
                                    elevation="0"
                                    @click="addLine(editedItem.familyMembers)"
                                  >
                                    <v-icon left>mdi-plus-circle</v-icon> Ajouter un membre à la famille
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-card-text>
                        </v-stepper-content>
                        <!-- <v-stepper-content step="2" class="no-padding">
                          <v-card-title>
                            <span class="text-h5">Informations familliales</span>
                          </v-card-title>

                          <v-card-text>
                            <v-form ref="form">
                              <v-row>
                                <v-col cols="12" sm="6" md="6">
                                  <v-select
                                    dense
                                    prepend-inner-icon="mdi-cogs"
                                    outlined
                                    v-model="editedItem.idMaritalStatus"
                                    :items="[
                                      {
                                        id: 1,
                                        designation: 'Célibataire',
                                      },
                                      {
                                        id: 2,
                                        designation: 'En concubinage',
                                      },
                                      {
                                        id: 3,
                                        designation: 'Marié(e)',
                                      },
                                      {
                                        id: 4,
                                        designation: 'Pacsé(e)',
                                      },
                                      {
                                        id: 5,
                                        designation: 'Divorcé(e)',
                                      },
                                      {
                                        id: 6,
                                        designation: 'Veuf(ve)',
                                      },
                                    ]"
                                    item-text="designation"
                                    item-value="id"
                                    label="Situation familiale"
                                  ></v-select>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-card-text>

                          <v-card-text class="no-padding mb-10">
                            <v-form>
                              <v-simple-table dense class="no-padding">
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-left">Nature du lien</th>
                                      <th class="text-left">Nom de famille</th>
                                      <th class="text-left">Prénom</th>
                                      <th class="text-left">Créer fiche client</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(familyMember, index) in editedItem.familyMembers" v-bind:key="index">
                                      <td class="text-left" style="width: 50%">
                                        <v-select
                                          class="mt-6"
                                          dense
                                          :id="`input-family-${index}`"
                                          outlined
                                          v-model="familyMember.id_account_family_link"
                                          :items="[
                                            {
                                              id: 1,
                                              designation: 'Conjoint(e)',
                                            },
                                            {
                                              id: 2,
                                              designation: 'Enfant',
                                            },
                                          ]"
                                          item-text="designation"
                                          item-value="id"
                                        ></v-select>
                                      </td>
                                      <td class="text-left">
                                        <v-text-field
                                          ref="newTextField"
                                          outlined
                                          class="mt-6"
                                          dense
                                          v-model="familyMember.lastname"
                                          single-line
                                        ></v-text-field>
                                      </td>
                                      <td class="text-left">
                                        <v-text-field
                                          ref="newTextField"
                                          outlined
                                          class="mt-6"
                                          dense
                                          v-model="familyMember.firstname"
                                          single-line
                                        ></v-text-field>
                                      </td>
                                      <td>
                                        <v-switch inset v-model="familyMember.createClient"> </v-switch>
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                              <v-row>
                                <v-col cols="12" md="4">
                                  <v-btn
                                    style="text-transform: none"
                                    elevation="0"
                                    @click="addLine(editedItem.familyMembers)"
                                  >
                                    <v-icon left>mdi-plus-circle</v-icon> Ajouter un lien familliale
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-card-text>
                        </v-stepper-content> -->

                        <v-stepper-content step="2" class="no-padding">
                          <v-card-title>
                            <span class="text-h5">Contrat de travail</span>
                          </v-card-title>

                          <v-card-text>
                            <v-container>
                              <v-form ref="form" v-model="validityFormPassword" lazy-validation>
                                <v-row>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-select
                                      id="select-type-contract"
                                      dense
                                      prepend-inner-icon="mdi-cogs"
                                      outlined
                                      v-model="editedItem.idTypeContract"
                                      :items="typeContracts"
                                      label="Type de contrat"
                                      item-text="designation"
                                      item-value="id"
                                    >
                                      <template v-slot:item="{ item }">
                                        <v-list-item-content :id="`item-type-contract-${item.id}`">
                                          <v-list-item-title>{{ item.designation }}</v-list-item-title>
                                        </v-list-item-content>
                                      </template>
                                    </v-select>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-select
                                      id="select-type-employment"
                                      dense
                                      prepend-inner-icon="mdi-cogs"
                                      outlined
                                      :items="typeEmployments"
                                      item-text="designation"
                                      item-value="id"
                                      label="Type d'emploi"
                                      v-model="editedItem.idTypeEmployment"
                                      ><template v-slot:item="{ item }">
                                        <v-list-item-content :id="`item-type-employment-${item.id}`">
                                          <v-list-item-title>{{ item.designation }}</v-list-item-title>
                                        </v-list-item-content>
                                      </template>
                                    </v-select>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-select
                                      id="select-type-job"
                                      dense
                                      prepend-inner-icon="mdi-cogs"
                                      outlined
                                      v-model="editedItem.idTypeJob"
                                      :items="typeJobs"
                                      item-text="designation"
                                      item-value="id"
                                      label="Poste"
                                      ><template v-slot:item="{ item }">
                                        <v-list-item-content :id="`item-type-job-${item.id}`">
                                          <v-list-item-title>{{ item.designation }}</v-list-item-title>
                                        </v-list-item-content>
                                      </template>
                                    </v-select>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-select
                                      id="select-type-convention"
                                      dense
                                      prepend-inner-icon="mdi-cogs"
                                      outlined
                                      :items="[
                                        {
                                          id: 1,
                                          designation: `Convention collective des agents généraux d'assurance`,
                                        },
                                        {
                                          id: 2,
                                          designation: `Convention collective du courtage d'assurance`,
                                        },
                                        {
                                          id: 3,
                                          designation: `Non concerné`,
                                        },
                                      ]"
                                      item-text="designation"
                                      item-value="id"
                                      label="Convention"
                                      v-model="editedItem.idCollectiveAgreement"
                                    >
                                      <template v-slot:item="{ item }">
                                        <v-list-item-content :id="`item-type-qualification-${item.id}`">
                                          <v-list-item-title>{{ item.designation }}</v-list-item-title>
                                        </v-list-item-content>
                                      </template></v-select
                                    >
                                  </v-col>
                                </v-row>
                                <v-row v-if="editedItem.idCollectiveAgreement === 1">
                                  <v-col cols="12" sm="6" md="6">
                                    <v-select
                                      id="select-type-qualification"
                                      dense
                                      prepend-inner-icon="mdi-cogs"
                                      outlined
                                      :items="typeQualifications"
                                      item-text="designation"
                                      item-value="id"
                                      label="Classification du poste"
                                      v-model="editedItem.idTypeQualification"
                                    >
                                      <template v-slot:item="{ item }">
                                        <v-list-item-content :id="`item-type-qualification-${item.id}`">
                                          <v-list-item-title>{{ item.designation }}</v-list-item-title>
                                        </v-list-item-content>
                                      </template></v-select
                                    >
                                  </v-col>
                                </v-row>
                              </v-form>
                            </v-container>
                          </v-card-text>
                        </v-stepper-content>

                        <v-stepper-content step="3" class="no-padding">
                          <v-card-title>
                            <span class="text-h5">Informations complémentaires</span>
                          </v-card-title>

                          <v-card-text>
                            <v-container>
                              <v-form ref="form" v-model="validityFormPassword" lazy-validation>
                                <v-row>
                                  <v-col cols="12" sm="12" md="12">
                                    <v-switch
                                      inset
                                      label="Possède un véhicule"
                                      v-model="editedItem.ownVehicle"
                                    ></v-switch>
                                  </v-col>
                                </v-row>
                                <v-row v-if="editedItem.ownVehicle">
                                  <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                      dense
                                      prepend-inner-icon="mdi-car"
                                      outlined
                                      v-model="editedItem.vehicleBrand"
                                      label="Marque du véhicule"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                      dense
                                      prepend-inner-icon="mdi-car"
                                      outlined
                                      v-model="editedItem.vehicleModel"
                                      label="Modèle du véhicule"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                      dense
                                      prepend-inner-icon="mdi-car"
                                      outlined
                                      v-model="editedItem.vehiclePower"
                                      hint="Rubrique P.6 de la carte grise"
                                      label="Puissance fiscal"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      id="input-date-entry"
                                      dense
                                      prepend-inner-icon="mdi-phone"
                                      outlined
                                      type="date"
                                      v-model="editedItem.entryDate"
                                      label="Date d'entrée dans l'entreprise"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6"> </v-col>
                                </v-row>
                                <v-row v-if="editedIndex === -1">
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      id="input-password"
                                      dense
                                      outlined
                                      v-show="editedIndex === -1"
                                      v-model="password"
                                      :error-messages="passwordErrorMessage"
                                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                      :rules="[passwordRules.required, passwordRules.min]"
                                      :type="showPassword ? 'text' : 'password'"
                                      label="Mot de passe"
                                      counter="12"
                                      @click:append="showPassword = !showPassword"
                                    >
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      id="input-password-validation"
                                      dense
                                      outlined
                                      v-show="editedIndex === -1"
                                      v-model="passwordValidation"
                                      :error-messages="passwordErrorMessage"
                                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                      :rules="[passwordRules.required, passwordRules.min]"
                                      :type="showPassword ? 'text' : 'password'"
                                      label=" Validation du mot de passe"
                                      hint="Vos deux mots de passe doivent correspondre"
                                      counter="12"
                                      @click:append="showPassword = !showPassword"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row class="mt-n5">
                                  <v-col cols="12" sm="12" md="12">
                                    <v-switch inset label="Télétravail" v-model="editedItem.isTelework"></v-switch>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="12" class="mt-n10">
                                    <v-switch
                                      inset
                                      label="Affilié(e) à la mutuelle d'entreprise"
                                      v-model="editedItem.isAffiliatedMutual"
                                    ></v-switch>
                                  </v-col>
                                </v-row>
                              </v-form>
                            </v-container>
                          </v-card-text>
                        </v-stepper-content>
                      </v-stepper-items>
                    </v-stepper>
                    <v-spacer></v-spacer>
                    <v-card-actions>
                      <v-btn id="btn-last" v-if="stepperAccount > 0" text @click="stepperAccount--">retour</v-btn>
                      <v-spacer></v-spacer>

                      <v-btn id="btn-next" v-if="stepperAccount != 3" text @click="stepperAccount++">suivant</v-btn>
                      <v-btn
                        id="btn-validate"
                        v-if="stepperAccount === 3"
                        text
                        @click="editedIndex === -1 ? validateFormPassword(editedItem.id) : save(editedItem.id)"
                      >
                        {{ editedIndex === -1 ? "Terminer" : "Terminer" }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-row v-if="!showCandidate">
      <v-col cols="12" md="3" class="">
        <v-card flat outlined>
          <v-card-text class="mb-n5">
            <v-text-field
              outlined
              label="Rechercher un collaborateur"
              dense
              v-model="filterCollaborator"
            ></v-text-field>
          </v-card-text>
          <v-card-text class="no-padding">
            <v-treeview
              dense
              :active.sync="active"
              :items="filterItems(users)"
              open-on-click
              :open.sync="open"
              activatable
              color="EoleYellow"
              transition
              selected-color="EoleYellow"
            >
              <template v-slot:prepend="{ item }">
                <v-spacer></v-spacer>
                <v-badge
                  dot
                  :color="item.dbl === 1 ? 'EoleError' : 'EoleGreen'"
                  value="5"
                  class="my-2 mr-2"
                  v-if="item.id && !item.children"
                  bordered
                  bottom
                  offset-x="10"
                  offset-y="10"
                >
                  <v-avatar size="40" :color="item.avatarUrl === null ? 'EoleBlue' : null" class="elevation-7">
                    <v-img :src="item.avatarUrl" v-if="item.avatarUrl != null"> </v-img>
                    <span v-else class="white--text">{{ item.firstname[0] + item.lastname[0] }}</span>
                    <!-- <v-icon v-else-if="item.id && !item.children" size="36">mdi-account</v-icon> -->
                  </v-avatar>
                </v-badge>

                <!-- <v-avatar
                    v-else-if="item.id && !item.children"
                    size="40"
                    :color="item.avatarUrl === null ? item.color : null"
                    style="border: 1px solid !important"
                  >
                    <v-img :src="item.avatarUrl" v-if="item.avatarUrl != null"> </v-img>
                    <span v-else class="white--text">{{ item.firstname[0] + item.lastname[0] }}</span>
                  </v-avatar> -->
                {{ item.completeName }}
                <!-- <v-badge
                  class="my-2 mr-2"
                  v-if="item.id && !item.children"
                  bordered
                  bottom
                  :color="item.dbl === 1 ? 'EoleError' : 'EoleGreen'"
                  offset-x="15"
                  offset-y="15"
                  ><template v-slot:badge>
                    <i v-if="item.dbl === 0" class="fa-solid fa-check"></i>
                    <i v-else class="fa-solid fa-ban"></i>
                  </template>
                  <v-avatar size="40" :color="item.avatarUrl === null ? '' : ''" style="border: 1px solid !important">
                    <v-img :src="item.avatarUrl" v-if="item.avatarUrl != null"> </v-img>
                    <span v-else class="white--text">{{ item.firstname[0] + item.lastname[0] }}</span>
                  </v-avatar>
                </v-badge>
                {{ item.completeName }} -->
              </template>
            </v-treeview>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end no-padding align-center mt-4 mr-2 EoleBlue--text">
            <p class="text-caption">{{ users.length + usersDisabled.length }} utilisateurs trouvés</p>
          </v-card-actions>
        </v-card>
      </v-col>

      <!-- <loading
        transition="fade"
        :active.sync="loadingFiles"
        :can-cancel="false"
        :is-full-page="false"
        loader="spinner"
        color="#efb639"
        :opacity="1"
      >
      </loading> -->
      <v-col cols="12" md="9" class="">
        <v-scroll-y-transition mode="out-in">
          <v-row v-if="!selected" align="center" class="text-left">
            <!-- <v-col>
              <v-alert outlined type="info" color="EoleBlue" prominent icon="mdi-account" text dense
                >Veuillez selectionner un collaborateur à afficher dans cette fenêtre.
              </v-alert>
            </v-col> -->
          </v-row>
          <v-card v-else :key="selected.id" class="mx-auto" flat style="background-color: transparent !important">
            <v-card outlined class="text-center">
              <v-card-text class="profilBannerAccount" style="height: 100px">
                <v-row>
                  <v-col cols="12" md="6" class="text-left"> </v-col>
                  <v-col cols="12" md="6" class="text-right"> </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="profilSeparator">
                <v-divider></v-divider>
              </v-card-text>
              <v-card-text class="profilBadge">
                <v-badge
                  bordered
                  bottom
                  :color="selected.dbl === 0 ? 'EoleGreen' : 'EoleError'"
                  offset-x="30"
                  offset-y="20"
                >
                  <template v-slot:badge>
                    <i v-if="selected.dbl === 0" class="fa-solid fa-check"></i>
                    <i v-else class="fa-solid fa-ban"></i>
                  </template>
                  <v-avatar v-if="avatarUrl != null" ref="avatar" size="96" class="elevation-7">
                    <v-img :src="avatarUrl"> </v-img>
                  </v-avatar>
                  <v-avatar ref="avatar" size="96" :color="selected.color" v-else>
                    <span class="white--text text-h3">{{ selected.firstname[0] + selected.lastname[0] }}</span>
                  </v-avatar>
                </v-badge>
              </v-card-text>
              <v-card-text class="profilTabs mb-15">
                <v-row>
                  <v-col cols="12" md="9">
                    <v-tabs
                      color="EoleYellow"
                      class="text-left mt-5"
                      v-model="tab"
                      style="box-shadow: none !important"
                      background-color="transparent"
                      :show-arrows="false"
                    >
                      <v-tab href="#tab-1"> Informations </v-tab>

                      <v-tab href="#tab-2"> Documents RH </v-tab>

                      <v-tab href="#tab-3"> informatique </v-tab>
                    </v-tabs>
                  </v-col>
                  <v-col cols="12" md="3" class="mt-6">
                    <v-menu left right transition="slide-x-transition" close-on-content-click>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" style="background-color: white">
                          <i class="fa-solid fa-ellipsis-vertical"></i>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          @click="selected.dbl === 0 ? editItem(selected) : editedItemDisabled(selected)"
                          link
                        >
                          <v-list-item-title>
                            <v-icon left>mdi-account-edit</v-icon>Modifier le profil
                          </v-list-item-title>
                        </v-list-item>

                        <v-dialog
                          v-if="selected.dbl === 0"
                          width="600px"
                          transition="dialog-bottom-transition"
                          class="hide-scrollbar"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-list-item v-bind="attrs" v-on="on" class="EoleBlue--text" link>
                              <v-list-item-title>
                                <v-icon color="EoleBlue" left> mdi-account-lock </v-icon>

                                Désactiver le profil
                              </v-list-item-title>
                            </v-list-item>
                          </template>
                          <v-card>
                            <v-card-title>Désactivation de l'utilisateur</v-card-title>
                            <v-card-text class="pa-5">
                              <v-form ref="form" lazy-validation>
                                <v-row>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      prepend-inner-icon="mdi-calendar"
                                      outlined
                                      dense
                                      v-model="desactivateData.dateEnd"
                                      label="Date de la sortie"
                                      type="date"
                                    >
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-select
                                      dense
                                      prepend-inner-icon="mdi-cogs"
                                      outlined
                                      v-model="desactivateData.idAccountLeaveReason"
                                      :items="reasonLeave"
                                      item-text="designation"
                                      item-value="id"
                                      label="Motif de la sortie"
                                    ></v-select>
                                  </v-col>
                                </v-row>
                                <v-row v-if="desactivateData.idAccountLeaveReason === 5">
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      prepend-inner-icon="mdi-calendar"
                                      outlined
                                      dense
                                      v-model="desactivateData.dateApproval"
                                      label="Date demande homologation"
                                      type="date"
                                    >
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      prepend-inner-icon="mdi-calendar"
                                      outlined
                                      dense
                                      v-model="desactivateData.dateAgreementApproval"
                                      label="Date accord homologation"
                                      type="date"
                                    >
                                    </v-text-field>
                                  </v-col>
                                </v-row>
                              </v-form>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                class="white--text"
                                color="EoleError"
                                @click="disabled({ id: selected.id, status: 1 })"
                                >Désactiver</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-dialog>

                        <v-list-item
                          v-else
                          class="EoleGreen--text"
                          @click="disabled({ id: selected.id, status: 0 })"
                          link
                        >
                          <v-list-item-title>
                            <v-icon color="EoleGreen" left> mdi-account-lock-open </v-icon>

                            Réactiver le profil
                          </v-list-item-title>
                        </v-list-item>

                        <v-list-item @click="sendEmailLostPassword(selected.email)" link>
                          <v-list-item-title>
                            <v-icon left>mdi-history</v-icon>Réinitialiser le mot de passe
                          </v-list-item-title>
                        </v-list-item>
                        <v-divider class="my-2"></v-divider>
                        <v-list-item class="EoleError--text" link @click="deleteItem(selected)">
                          <v-list-item-title>
                            <v-icon left color="EoleError">mdi-account-remove</v-icon>Supprimer le profil
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-text class="mt-16">
                <v-row class="row-name">
                  <v-col cols="12" md="6" class="text-left">
                    <h3 class="text-h5 mb-n15 ml-5">
                      {{ selected.completeName }}
                      <v-tooltip v-model="showEmail" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="white--text ml-2"
                            fab
                            color="EoleBlue"
                            :href="`mailto:${selected.email}`"
                            v-bind="attrs"
                            v-on="on"
                            icon
                          >
                            <i class="fa-solid fa-envelope fa-icone"></i
                          ></v-btn>
                        </template>
                        <span>{{ selected.email }}</span>
                      </v-tooltip>

                      <v-tooltip v-model="showPhone" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="white--text"
                            fab
                            color="EoleBlue"
                            :href="`tel:${selected.phoneNumberProfessional}`"
                            v-bind="attrs"
                            v-on="on"
                            icon
                          >
                            <i class="fa-solid fa-phone-volume fa-icone"></i
                          ></v-btn>
                        </template>
                        <span>{{ selected.phoneNumberProfessional }}</span>
                      </v-tooltip>
                    </h3>
                  </v-col>
                  <v-col cols="12" md="6" class="text-right">
                    <v-chip small v-if="selected.lastConnection === '0000-00-00 00:00:00'" color="EoleError">
                      <v-icon small class="mr-1"> mdi-close-circle </v-icon>
                      Jamais connecté
                    </v-chip>
                    <v-chip v-else> Connecté {{ selected.lastConnection }} </v-chip>
                  </v-col>
                </v-row>
              </v-card-text>
              <!-- <v-divider class=""></v-divider> -->

              <v-card-text>
                <v-tabs-items v-model="tab" style="background-color: transparent !important">
                  <v-tab-item :value="'tab-1'">
                    <v-row class="mb-5">
                      <v-row class="mt-n2 pa-5">
                        <v-col cols="12" md="5" class="d-flex w-full flex flex-column">
                          <v-card outlined class="rounded-xl" min-height="250px">
                            <v-card-text class="pa-5" v-if="selected.contract.length > 0">
                              <v-row v-if="selected.services.length > 0">
                                <v-col cols="12" md="12" class="text-left">
                                  Service :
                                  <strong> {{ selected.services.map(service => service.name).join(", ") }}</strong>
                                </v-col>
                              </v-row>
                              <v-row class="mt-n5" v-if="selected.services.length > 0 && selected.is_manager === false">
                                <v-col cols="12" md="12" class="text-left">
                                  Responsable hiérarchique :
                                  <strong>
                                    {{
                                      selected.services
                                        .map(service =>
                                          service.manager.map(
                                            manager => `${manager.lastname_manager} ${manager.firstname_manager}`,
                                          ),
                                        )
                                        .join(", ")
                                    }}
                                  </strong>
                                </v-col>
                              </v-row>

                              <v-row class="mt-n5" v-if="selected.services.length > 0 && selected.is_manager === true">
                                <v-col cols="12" md="12" class="text-left">
                                  Responsable des services :
                                  <strong> {{ selected.services.map(service => service.name).join(", ") }}</strong>
                                </v-col>
                              </v-row>

                              <v-row class="mt-n5">
                                <v-col cols="12" md="12" class="text-left" v-if="selected.contract.length > 0">
                                  Poste :
                                  <strong>
                                    {{ selected.contract[0].job_type_name }}
                                    <span v-if="selected.contract[0].qualification_type_id != 8"
                                      >(classe {{ selected.contract[0].qualification_type_name }})</span
                                    >
                                  </strong>
                                </v-col>
                              </v-row>
                              <v-row class="mt-n5">
                                <v-col cols="12" md="12" class="text-left" v-if="selected.contract.length > 0">
                                  Durée du travail :
                                  <strong>{{ selected.contract[0].employment_type_name }}</strong>
                                </v-col>
                              </v-row>
                              <v-row class="mt-n5">
                                <v-col cols="12" md="12" class="text-left" v-if="selected.contract.length > 0">
                                  Type de contrat : <strong> {{ selected.contract[0].contract_type_name }} </strong>
                                  <br />
                                </v-col>
                              </v-row>
                              <v-row class="mt-n5">
                                <v-col cols="12" md="12" class="text-left" v-if="selected.contract.length > 0">
                                  Date d'entrée : <strong> {{ selected.entryDateDisplay }} </strong> <br />
                                </v-col>
                              </v-row>
                              <v-row class="mt-n5">
                                <v-col cols="12" md="12" class="text-left" v-if="selected.contract.length > 0">
                                  Lieu de travail principal :
                                  <strong>
                                    {{ selected.mainWorkplaceCity }}
                                  </strong>
                                  <br />
                                </v-col>
                              </v-row>
                              <v-row class="mt-n5">
                                <v-col cols="12" md="12" class="text-left" v-if="selected.contract.length > 0">
                                  Affilié(e) mutuelle entreprise :
                                  <v-icon color="EoleGreen" v-if="selected.isAffiliatedMutual">mdi-check</v-icon>
                                  <v-icon color="EoleError" v-else>mdi-close-thick</v-icon>

                                  <br />
                                </v-col>
                              </v-row>
                              <v-row class="mt-n5">
                                <v-col cols="12" md="12" class="text-left" v-if="selected.contract.length > 0">
                                  Télétravail :
                                  <v-icon color="EoleGreen" v-if="selected.isTelework">mdi-check</v-icon>
                                  <v-icon color="EoleError" v-else>mdi-close-thick</v-icon>
                                  <br />
                                </v-col>
                              </v-row>
                              <v-row v-if="selected.dbl === 1" class="mt-n5">
                                <v-col cols="12" md="12" class="text-left" v-if="selected.contract.length > 0">
                                  Date de sortie : <strong> {{ selected.endDateDisplay }} </strong> <br />
                                </v-col>
                              </v-row>
                              <v-row v-if="selected.dbl === 1" class="mt-n5">
                                <v-col cols="12" md="12" class="text-left" v-if="selected.contract.length > 0">
                                  Motif de sortie : <strong> {{ selected.leaveReason }} </strong> <br />
                                </v-col>
                              </v-row>
                              <v-row v-if="selected.dbl === 1 && selected.idLeaveReason === 5" class="mt-n5">
                                <v-col cols="12" md="12" class="text-left" v-if="selected.contract.length > 0">
                                  Date de demande d'homologation :
                                  <strong> {{ selected.endAgreementApprovalDate }} </strong>
                                  <br />
                                </v-col>
                              </v-row>
                              <v-row v-if="selected.dbl === 1 && selected.idLeaveReason === 5" class="mt-n5">
                                <v-col cols="12" md="12" class="text-left" v-if="selected.contract.length > 0">
                                  Date d'accord d'homologation :
                                  <strong> {{ selected.endAgreementApplicationDate }} </strong> <br />
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-card-text v-else class="mb-n5 mt-5 no-padding">
                              <v-alert dense prominent type="warning" color="EoleYellow" text outlined>
                                <v-row align="center">
                                  <v-col class="grow">
                                    Vous ne pouvez pas voir les informations en lien avec le contrat de travail.
                                    <br />Pour commencer merci de configurer le contrat du collaborateur
                                  </v-col>
                                  <v-col class="shrink">
                                    <v-dialog v-model="dialogAddContractToAccount" max-width="800px">
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="EoleYellow" outlined v-bind="attrs" v-on="on" large>
                                          Configurer
                                        </v-btn>
                                      </template>

                                      <v-card>
                                        <v-card-title>
                                          <span class="text-h5"
                                            >Configurer le contrat de {{ selected.completeName }}</span
                                          >
                                        </v-card-title>

                                        <v-card-text>
                                          <v-container>
                                            <v-form ref="form" v-model="validityFormPassword" lazy-validation>
                                              <v-row>
                                                <v-col cols="12" sm="6" md="6">
                                                  <v-select
                                                    prepend-inner-icon="mdi-cogs"
                                                    outlined
                                                    v-model="editedItemContract.idTypeContract"
                                                    :items="typeContracts"
                                                    label="Type de contrat"
                                                    item-text="designation"
                                                    item-value="id"
                                                  ></v-select>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="6">
                                                  <v-select
                                                    prepend-inner-icon="mdi-cogs"
                                                    outlined
                                                    :items="typeEmployments"
                                                    item-text="designation"
                                                    item-value="id"
                                                    label="Type d'emploi"
                                                    v-model="editedItemContract.idTypeEmployment"
                                                  >
                                                  </v-select>
                                                </v-col>
                                              </v-row>
                                              <v-row>
                                                <v-col cols="12" sm="6" md="6">
                                                  <v-select
                                                    prepend-inner-icon="mdi-cogs"
                                                    outlined
                                                    v-model="editedItemContract.idTypeJob"
                                                    :items="typeJobs"
                                                    item-text="designation"
                                                    item-value="id"
                                                    label="Poste"
                                                  ></v-select>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="6">
                                                  <v-select
                                                    prepend-inner-icon="mdi-cogs"
                                                    outlined
                                                    :items="typeQualifications"
                                                    item-text="designation"
                                                    item-value="id"
                                                    label="Classification du poste"
                                                    v-model="editedItemContract.idTypeQualification"
                                                  >
                                                  </v-select>
                                                </v-col>
                                              </v-row>
                                            </v-form>
                                          </v-container>
                                        </v-card-text>

                                        <v-card-actions>
                                          <v-spacer></v-spacer>
                                          <v-btn color="warning" text @click="dialogAddContractToAccount = false"
                                            >Annuler</v-btn
                                          >
                                          <v-btn
                                            class="white--text"
                                            color="EoleGreen"
                                            @click="postContract(editedItemContract, selected.id)"
                                          >
                                            Terminer</v-btn
                                          >
                                        </v-card-actions>
                                      </v-card>
                                    </v-dialog>
                                  </v-col>
                                </v-row>
                              </v-alert>
                            </v-card-text>
                          </v-card>
                        </v-col>

                        <v-col cols="12" md="7" class="d-flex">
                          <v-row>
                            <v-col class="d-flex" cols="12" md="4" sm="6">
                              <v-dialog max-width="300" v-model="dialogRemainingLastYear">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-card class="flex flex-column rounded-xl" v-bind="attrs" v-on="on" flat outlined>
                                    <v-card-subtitle class="text-center"> Solde COP N-1 </v-card-subtitle>

                                    <div class="d-flex flex-column flex-grow-1">
                                      <v-card-text class="text-center flex-grow-1">
                                        <h2 class="font-weight-bold" style="font-size: 2rem">
                                          {{ selected.remaining[0].lastYear }}
                                        </h2>
                                        <small v-if="selected.deductions != null"
                                          >Demande en cours :
                                          {{ selected.deductions.deducted_from_last_year }}
                                          jours</small
                                        >
                                      </v-card-text>
                                    </div>
                                    <v-card-subtitle class="text-center"
                                      >01-06-{{ lastYear }} au 31-05-{{ currentYear }}</v-card-subtitle
                                    >
                                  </v-card>
                                </template>
                                <v-card>
                                  <v-card-subtitle>Enregistrez votre nouvelle valeur</v-card-subtitle>
                                  <v-card-text>
                                    <v-text-field
                                      outlined
                                      dense
                                      v-model="selected.remaining[0].lastYear"
                                      @keyup.enter="
                                        () => {
                                          saveNewHolidayData(
                                            selected.id,
                                            selected.remaining,
                                            selected.contract[0].employment_type_id,
                                          )
                                          dialogRemainingLastYear = false
                                        }
                                      "
                                    ></v-text-field>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      elevation="0"
                                      @click="
                                        () => {
                                          saveNewHolidayData(
                                            selected.id,
                                            selected.remaining,
                                            selected.contract[0].employment_type_id,
                                          )
                                          dialogRemainingLastYear = false
                                        }
                                      "
                                      >Enregistrer</v-btn
                                    >
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </v-col>
                            <v-col class="d-flex" cols="12" md="4" sm="6">
                              <v-dialog max-width="300" v-model="dialogCompensatoryCurrentYear">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-card
                                    v-if="parseInt(selected.contract[0].employment_type_id) != 2"
                                    class="flex flex-column rounded-xl"
                                    v-bind="attrs"
                                    v-on="on"
                                    flat
                                    outlined
                                  >
                                    <v-card-subtitle class="text-center"> Solde RTT </v-card-subtitle>
                                    <div class="d-flex flex-column flex-grow-1">
                                      <v-card-text class="text-center flex-grow-1">
                                        <h2 class="font-weight-bold" style="font-size: 2rem">
                                          {{ selected.remaining[1].currentYear }}
                                        </h2>
                                        <small v-if="selected.deductions != null"
                                          >Demande en cours : {{ selected.deductions.deducted_recovery }} jours</small
                                        >
                                      </v-card-text>
                                    </div>
                                    <v-card-subtitle class="text-center"
                                      >01-01-{{ currentYear }} au 31-12-{{ currentYear }}</v-card-subtitle
                                    >
                                  </v-card>
                                  <v-card
                                    v-else
                                    class="flex flex-column rounded-xl"
                                    v-bind="attrs"
                                    v-on="on"
                                    flat
                                    outlined
                                  >
                                    <v-card-subtitle class="text-center"> Solde JFC </v-card-subtitle>
                                    <div class="d-flex flex-column flex-grow-1">
                                      <v-card-text class="text-center flex-grow-1">
                                        <h2 class="font-weight-bold" style="font-size: 2rem">
                                          {{ selected.remaining[1].currentYear }}
                                        </h2>
                                        <small v-if="selected.deductions != null"
                                          >Demande en cours :
                                          {{ selected.deductions.deducted_compensatory }} jours</small
                                        >
                                      </v-card-text>
                                    </div>
                                    <v-card-subtitle class="text-center"
                                      >01-01-{{ currentYear }} au 31-12-{{ currentYear }}</v-card-subtitle
                                    >
                                  </v-card>
                                </template>
                                <v-card>
                                  <v-card-subtitle>Enregistrez votre nouvelle valeur</v-card-subtitle>
                                  <v-card-text>
                                    <v-text-field
                                      outlined
                                      dense
                                      v-model="selected.remaining[1].currentYear"
                                      @keyup.enter="
                                        () => {
                                          saveNewHolidayData(
                                            selected.id,
                                            selected.remaining,
                                            selected.contract[0].employment_type_id,
                                          )
                                          dialogCompensatoryCurrentYear = false
                                        }
                                      "
                                    ></v-text-field>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      elevation="0"
                                      @click="
                                        () => {
                                          saveNewHolidayData(
                                            selected.id,
                                            selected.remaining,
                                            selected.contract[0].employment_type_id,
                                          )
                                          dialogCompensatoryCurrentYear = false
                                        }
                                      "
                                      >Enregistrer</v-btn
                                    >
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </v-col>

                            <v-col class="d-flex" cols="12" md="4" sm="6">
                              <v-dialog max-width="300" v-model="dialogRemainingCurrentYear">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-card class="flex flex-column rounded-xl" v-bind="attrs" v-on="on" flat outlined>
                                    <v-card-subtitle class="text-center"> Solde COP N </v-card-subtitle>
                                    <div class="d-flex flex-column flex-grow-1">
                                      <v-card-text class="text-center flex-grow-1">
                                        <h2 class="font-weight-bold" style="font-size: 2rem">
                                          {{ selected.remaining[0].currentYear }}
                                        </h2>
                                        <small v-if="selected.deductions != null"
                                          >Demande en cours :
                                          {{ selected.deductions.deducted_from_current_year }}
                                          jours</small
                                        >
                                      </v-card-text>
                                    </div>
                                    <v-card-subtitle class="text-center"
                                      >01-06-{{ currentYear }} au 31-05-{{ nextYear }}</v-card-subtitle
                                    >
                                    <v-card-subtitle class="text-center"
                                      >Au <strong class="EoleYellow--text">{{ lastMonth }}</strong></v-card-subtitle
                                    >
                                  </v-card>
                                </template>
                                <v-card>
                                  <v-card-subtitle>Enregistrez votre nouvelle valeur</v-card-subtitle>
                                  <v-card-text>
                                    <v-text-field
                                      outlined
                                      dense
                                      v-model="selected.remaining[0].currentYear"
                                      @keyup.enter="
                                        () => {
                                          saveNewHolidayData(
                                            selected.id,
                                            selected.remaining,
                                            selected.contract[0].employment_type_id,
                                          )
                                          dialogRemainingCurrentYear = false
                                        }
                                      "
                                    ></v-text-field>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      elevation="0"
                                      @click="
                                        () => {
                                          saveNewHolidayData(
                                            selected.id,
                                            selected.remaining,
                                            selected.contract[0].employment_type_id,
                                          )
                                          dialogRemainingCurrentYear = false
                                        }
                                      "
                                      >Enregistrer</v-btn
                                    >
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="4" class="d-flex">
                          <v-card-text>
                            <v-card flat outlined class="rounded-xl h-full">
                              <v-card-title style="font-size: 1.1rem">Informations personnelles </v-card-title>
                              <v-divider class="mt-n3"></v-divider>
                              <v-card-text class="flex-grow-1 no-padding text-center">
                                <v-card-text class="pa-5">
                                  <v-row>
                                    <v-col cols="12" md="12" class="text-left">
                                      N° de téléphone :
                                      <strong> {{ selected.personnalPhoneNumberFormatted }}</strong>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12" md="12" class="text-left mt-n5">
                                      Adresse email :
                                      <strong> {{ selected.personnalEmail }}</strong>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12" md="12" class="text-left mt-n5">
                                      Adresse postale :
                                      <strong>
                                        {{ selected.street }}
                                        {{ selected.zip }} {{ selected.city }}
                                      </strong>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12" md="12" class="text-left mt-n5">
                                      Situation familliale :
                                      <strong>{{ selected.marital_category }} </strong>
                                    </v-col>
                                  </v-row>
                                  <!-- <v-row>
                                    <v-col cols="12" md="12" class="text-left mt-n5">
                                      Situation familliale :
                                      <strong>{{ selected.marital_category }} </strong>
                                    </v-col>
                                  </v-row>
                                  <v-row
                                    v-if="
                                      selected.idMaritalStatus === 2 ||
                                      editedItem.idMaritalStatus === 3 ||
                                      editedItem.idMaritalStatus === 4
                                    "
                                  >
                                    <v-col cols="12" md="12" class="text-left mt-n5">
                                      Conjoint(e) :
                                      <strong
                                        >{{ selected.lawPartner.lastname }} {{ selected.lawPartner.firstname }}
                                      </strong>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12" md="12" class="text-left mt-n5">
                                      Nombre d'enfant(s) :
                                      <strong>{{ selected.childCount }} </strong>
                                    </v-col>
                                  </v-row>
                                  <v-row v-for="child in selected.childs" v-bind:key="child.id">
                                    <v-col cols="12" md="12" class="text-left mt-n5">
                                      - {{ child.lastname }} {{ child.firstname }}
                                    </v-col>
                                  </v-row>
                                  <br />
                                  <v-row>
                                    <v-col cols="12" md="12" class="text-left mt-5">
                                      Contact d'urgence :
                                      <span v-if="selected.emergencyContact.length === 0" class="EoleError--text"
                                        >Aucun contact d'urgence repertorié</span
                                      >
                                    </v-col>
                                  </v-row>
                                  <v-row v-for="contact in selected.emergencyContact" v-bind:key="contact.id">
                                    <v-col cols="12" md="12" class="text-left mt-n5">
                                      - {{ contact.lastname }} {{ contact.firstname
                                      }}<strong> N°{{ contact.phone_number }}</strong>
                                    </v-col>
                                  </v-row> -->
                                </v-card-text>
                              </v-card-text>
                            </v-card>
                          </v-card-text>
                        </v-col>
                        <v-col cols="12" md="4" class="d-flex">
                          <v-card-text>
                            <v-card flat outlined class="rounded-xl h-full">
                              <v-card-title style="font-size: 1.1rem">Informations familliales </v-card-title>
                              <v-divider class="mt-n3"></v-divider>
                              <v-card-text class="flex-grow-1 no-padding text-center">
                                <v-card-text class="pa-5">
                                  <v-row
                                    v-if="
                                      selected.idMaritalStatus === 2 ||
                                      selected.idMaritalStatus === 3 ||
                                      selected.idMaritalStatus === 4
                                    "
                                  >
                                    <v-col cols="12" md="12" class="text-left">
                                      Conjoint(e) :
                                      <strong
                                        >{{ selected.lawPartner.lastname }} {{ selected.lawPartner.firstname }}
                                      </strong>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      md="12"
                                      :class="[
                                        'text-left',
                                        selected.idMaritalStatus === 2 ||
                                        selected.idMaritalStatus === 3 ||
                                        selected.idMaritalStatus === 4
                                          ? 'mt-n5'
                                          : '',
                                      ]"
                                    >
                                      Nombre d'enfant(s) :
                                      <strong>{{ selected.childCount }} </strong>
                                    </v-col>
                                  </v-row>
                                  <v-row v-for="child in selected.childs" v-bind:key="child.id">
                                    <v-col cols="12" md="12" class="text-left mt-n5">
                                      - {{ child.lastname }} {{ child.firstname }}
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12" md="12" class="text-left mt-n5">
                                      Contact d'urgence :
                                      <span v-if="selected.emergencyContact.length === 0" class="EoleError--text"
                                        >Aucun contact d'urgence repertorié</span
                                      >
                                    </v-col>
                                  </v-row>
                                  <v-row v-for="contact in selected.emergencyContact" v-bind:key="contact.id">
                                    <v-col cols="12" md="12" class="text-left mt-n5">
                                      - {{ contact.lastname }} {{ contact.firstname }} :<strong>{{
                                        contact.phone_number_formatted
                                      }}</strong>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card-text>
                            </v-card>
                          </v-card-text>
                        </v-col>
                        <v-col cols="12" md="4" class="d-flex">
                          <v-card-text>
                            <v-card flat outlined class="rounded-xl h-full">
                              <v-card-title style="font-size: 1.1rem">Informations Véhicule </v-card-title>
                              <v-divider class="mt-n3"></v-divider>
                              <v-card-text class="flex-grow-1 no-padding text-center">
                                <v-card-text class="pa-5" v-if="selected.vehicle">
                                  <v-row>
                                    <v-col cols="12" md="12" class="text-left">
                                      Marque :
                                      <strong>{{ selected.vehicle.brand }} </strong>
                                    </v-col>
                                  </v-row>

                                  <v-row>
                                    <v-col cols="12" md="12" class="text-left mt-n5">
                                      Modèle :
                                      <strong>{{ selected.vehicle.model }} </strong>
                                    </v-col>
                                  </v-row>

                                  <v-row>
                                    <v-col cols="12" md="12" class="text-left mt-n5">
                                      Nombre de chevaux fiscaux :
                                      <strong>{{ selected.vehicle.power }} cv</strong>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                                <v-card-text v-else>
                                  Aucun véhicle enregistré pour {{ selected.firstname }}
                                </v-card-text>
                              </v-card-text>
                            </v-card>
                          </v-card-text>
                        </v-col>
                      </v-row>

                      <v-card-text>
                        <v-card class="flex-fill flex d-flex flex-column rounded-xl" outlined flat>
                          <v-card-title style="font-size: 1.1rem">Conformité utilisateur </v-card-title>
                          <v-divider class="mt-n3"></v-divider>
                          <v-card-text class="h-full">
                            <v-row class="justify-center text-center text-caption h-full mt-1" align="center">
                              <v-col cols="12" md="3">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-progress-circular
                                      v-bind="attrs"
                                      v-on="on"
                                      size="92"
                                      width="13"
                                      :value="valueHRfiles"
                                      color="EoleGreen"
                                      rotate="270"
                                    >
                                      {{ valueHRfiles }}%
                                    </v-progress-circular>
                                  </template>
                                  <span> {{ valueHRfiles }}% </span>
                                </v-tooltip>

                                <br />
                                Informations personnelles
                              </v-col>
                              <v-col cols="12" md="3">
                                <v-progress-circular size="92" width="13" rotate="270" color="EoleGreen">
                                  <!-- :value="countHR(selected.categories[0].files, selected)" -->
                                  <span>{{ selected.hrFilesComplet }}%</span>
                                </v-progress-circular>
                                <br />
                                Ressources Humaines
                              </v-col>
                              <v-col cols="12" md="3">
                                <v-progress-circular size="92" width="13" :value="0" rotate="270">
                                </v-progress-circular>
                                <br />
                                Informatique
                              </v-col>
                              <v-col cols="12" md="3">
                                <v-progress-circular size="92" width="13" :value="0" rotate="270" color="EoleGreen">
                                </v-progress-circular>
                                <br />
                                Entretien annuel <br />
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-card-text>
                      <!-- <v-col cols="12" md="4" class="d-flex">
                        <v-card outlined class="flex-fill flex d-flex flex-column">
                          <v-card-title class="justify-center">Informations familliales </v-card-title>

                          <v-card-text class="text-center">
                            <v-row>
                              <v-col class="text-left" cols="12" md="6">Situation familliale :</v-col>
                              <v-col class="text-right" cols="12" md="6">{{ selected.marital_category }} </v-col>
                              <v-col class="text-left" cols="12" md="6">Nombre d'enfants : </v-col>
                              <v-col class="text-right" cols="12" md="6"> {{ selected.childCount }} </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-text class="text-center no-padding" v-if="selected.familyMembers.length > 0">
                            <v-data-table
                              class="rounded-xl"
                              :items="selected.familyMembers"
                              :headers="familyHeaders"
                              disable-pagination
                              :items-per-page="-1"
                              hide-default-footer
                            ></v-data-table>
                          </v-card-text>
                        </v-card>
                      </v-col> -->

                      <!-- <v-col cols="12" md="4" class="d-flex">
                        <v-card outlined class="flex-fill flex d-flex flex-column">
                          <v-card-title class="justify-center">Gestion des congés </v-card-title>
                          {{ selected.remaining }}
                          <v-card-text class="no-padding">
                            <v-data-table :headers="headersHoliday" :items="selected.remaining" hide-default-footer>
                              <template v-slot:item.lastYear="props">
                                <v-edit-dialog
                                  :return-value.sync="props.item.lastYear"
                                  @save="
                                    saveNewHolidayData(
                                      selected.id,
                                      selected.remaining,
                                      selected.contract[0].employment_type_id,
                                    )
                                  "
                                  @cancel="cancelNewHolidayData"
                                  @open="openNewHolidayData"
                                  @close="closeNewHolidayData"
                                >
                                  {{ props.item.lastYear }}
                                  <template v-slot:input>
                                    <v-text-field
                                      v-model="props.item.lastYear"
                                      label="Edit"
                                      single-line
                                      counter
                                      type="number"
                                    ></v-text-field>
                                  </template>
                                </v-edit-dialog>
                              </template>
                              <template v-slot:item.currentYear="props">
                                <v-edit-dialog
                                  :return-value.sync="props.item.currentYear"
                                  @save="
                                    saveNewHolidayData(
                                      selected.id,
                                      selected.remaining,
                                      selected.contract[0].employment_type_id,
                                    )
                                  "
                                  @cancel="cancelNewHolidayData"
                                  @open="openNewHolidayData"
                                  @close="closeNewHolidayData"
                                >
                                  {{ props.item.currentYear }}
                                  <template v-slot:input>
                                    <v-text-field
                                      v-model="props.item.currentYear"
                                      label="Edit"
                                      single-line
                                      counter
                                    ></v-text-field>
                                  </template>
                                </v-edit-dialog>
                              </template>
                           
                            </v-data-table>
                          
                          </v-card-text>
                        </v-card>
                      </v-col> -->
                    </v-row>
                  </v-tab-item>
                  <v-tab-item :value="'tab-2'">
                    <v-data-table
                      class="elevation-1"
                      item-key="id"
                      :loading="loadingFiles"
                      :loading-text="`Chargement des pièces en cours...\n Merci de patienter`"
                      :page.sync="page"
                      :items-per-page="10"
                      :headers="categoriesHeaders"
                      :items="selected.categories"
                      hide-default-footer
                      @page-count="pageCount = $event"
                      :single-expand="singleExpand"
                      :expanded.sync="expanded"
                      show-expand
                      :footer-props="{
                        'items-per-page-text': 'produits par page(s)',
                      }"
                    >
                      <template v-slot:[`item.files.length`]="{ item }" class="text-center justify-center">
                        <v-chip v-if="countNullFiles(item.files) > 0" color="EoleYellow" small>
                          <v-icon left small>mdi-alert</v-icon>
                          {{ countNullFiles(item.files) }} pièces manquantes
                        </v-chip>
                        <span v-else-if="item.files.length === 0"> --- </span>
                        <v-chip v-else> <v-icon color="EoleGreen" left>mdi-check</v-icon> Dossier conforme </v-chip>
                      </template>
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" text icon>
                              <v-icon> mdi-folder-zip </v-icon>
                            </v-btn>
                          </template>
                          <span
                            >Extraire tous les fichiers de « {{ item.designation }} » pour
                            {{ selected.completeName }}</span
                          >
                        </v-tooltip>
                      </template>
                      <template v-slot:expanded-item="{ item }">
                        <td class="no-padding" :colspan="headers.length">
                          <v-data-table
                            hide-default-header
                            full-width
                            :headers="headersFiles"
                            :items="item.files"
                            item-key="id"
                            class="elevation-1 v-data-table-file"
                            :items-per-page="-1"
                            disable-pagination
                            hide-default-footer
                          >
                            <template v-slot:[`item.actions`]="{ item }">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    :disabled="item.url === null"
                                    fab
                                    icon
                                    color="EoleError"
                                    @click="downloadFile(item.path, item.name, item.extension)"
                                    target="_blank"
                                    v-on="on"
                                    v-bind="attrs"
                                  >
                                    <i v-if="item.extension === 'pdf'" class="fa-solid fa-file-pdf icon-medium"></i>
                                    <i v-else class="fa-solid fa-image icon-medium"></i>
                                  </v-btn>
                                </template>
                                <span v-if="item.url !== null"> Télécharger {{ item.name }} </span>
                              </v-tooltip>
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    fab
                                    icon
                                    v-on="on"
                                    v-bind="attrs"
                                    @click="openFileExplorer(item.id, item.name)"
                                  >
                                    <i class="fa-solid fa-file-import icon-medium"></i
                                  ></v-btn>
                                </template>
                                <span> Importer/modifier {{ item.name }} </span>
                              </v-tooltip>
                            </template>
                          </v-data-table>
                          <v-dialog v-model="dialogUploadPut" max-width="1200px">
                            <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                              <v-card-text style="background-color: #f1f4f9">
                                <v-row>
                                  <v-col cols="12" md="3" class="no-padding ml-n2">
                                    <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                                      <v-card-title>
                                        <v-img
                                          :src="require('@/assets/images/logos/anavel_logo.png')"
                                          max-height="150px"
                                          max-width="120px"
                                          alt="logo"
                                          contain
                                        ></v-img>
                                      </v-card-title>
                                      <v-card-title class="text-center justify-center muller-capitalized"
                                        >Mes Services Assurance</v-card-title
                                      >
                                      <v-card-subtitle class="text-center justify-center"
                                        >Un logiciel ANAVEL
                                      </v-card-subtitle>
                                      <v-card-text class="no-padding">
                                        <v-img
                                          :src="require('@/assets/images/logos/layer.png')"
                                          alt="logo"
                                          width="300px"
                                          class=""
                                        >
                                        </v-img>
                                      </v-card-text>
                                    </v-card>
                                  </v-col>
                                  <v-col cols="12" md="9" class="no-padding d-flex">
                                    <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                                      <v-card-title>
                                        <span class="text-h5">Ajouter un nouveau document</span>
                                      </v-card-title>
                                      <v-card-text>
                                        {{ documentPut }}
                                        <v-row>
                                          <v-col cols="12" md="6">
                                            <v-text-field
                                              @input="replaceSpacesWithUnderscoresPut"
                                              outlined
                                              label="Nom du document"
                                              v-model="documentPut.name"
                                              :value="documentPut.data.name"
                                            ></v-text-field>
                                            <v-select
                                              label="Catégorie du document"
                                              :items="['Personnel', 'Diplomes', 'Documents contractuel']"
                                              outlined
                                              v-model="documentPut.categorie"
                                            ></v-select>
                                          </v-col>
                                          <v-col cols="12" md="6">
                                            <div>
                                              <span v-if="documentPut === null && fileUrlPut === null"
                                                >Aucun document n'est pour le moment disponible.</span
                                              >
                                              <iframe
                                                v-else-if="
                                                  documentPut != null &&
                                                  documentPut.data != null &&
                                                  documentPut.data.type === 'application/pdf'
                                                "
                                                id="lddcVisualisator"
                                                style="width: 100%; height: 500px"
                                                :src="fileUrlPut"
                                              ></iframe>

                                              <v-row
                                                align="center"
                                                v-else-if="
                                                  documentPut != null &&
                                                  documentPut.data != null &&
                                                  documentPut.data.type ===
                                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                                "
                                                style="height: 225px"
                                              >
                                                <v-col cols="12" md="12">
                                                  <i class="fa-regular fa-file-word EoleBlue--text fa-8x"></i>
                                                </v-col>
                                              </v-row>
                                              <v-row
                                                align="center"
                                                v-else-if="
                                                  documentPut != null &&
                                                  documentPut.data != null &&
                                                  documentPut.data.type === 'image/png'
                                                "
                                                style="height: 225px"
                                              >
                                                <v-col cols="12" md="12">
                                                  <v-img :src="fileUrlPut" contain></v-img>
                                                </v-col>
                                              </v-row>
                                              <v-row
                                                align="center"
                                                v-else-if="
                                                  documentPut != null &&
                                                  documentPut.data != null &&
                                                  documentPut.data.type === 'image/jpeg'
                                                "
                                                style="height: 225px"
                                              >
                                                <v-col cols="12" md="12">
                                                  <v-img :src="fileUrlPut" contain></v-img>
                                                </v-col>
                                              </v-row>
                                              <v-row
                                                align="center"
                                                v-else-if="
                                                  documentPut != null &&
                                                  documentPut.data != null &&
                                                  documentPut.data.type === 'image/jpg'
                                                "
                                                style="height: 225px"
                                              >
                                                <v-col cols="12" md="12">
                                                  <v-img :src="fileUrlPut" contain></v-img>
                                                </v-col>
                                              </v-row>
                                            </div>

                                            <v-card-text v-if="documentPut.url != null">
                                              <v-row align="center"> </v-row>
                                            </v-card-text>
                                          </v-col>
                                        </v-row>
                                      </v-card-text>
                                      <v-spacer></v-spacer>
                                      <v-card-actions>
                                        <v-btn
                                          text
                                          @click="
                                            () => {
                                              dialogUploadPut = !dialogUploadPut
                                              documentPut = {
                                                name: null,
                                                data: {
                                                  name: null,
                                                  type: null,
                                                },
                                                categorie: null,
                                                fileId: null,
                                              }
                                            }
                                          "
                                        >
                                          Annuler
                                        </v-btn>
                                        <v-spacer></v-spacer>

                                        <v-btn
                                          text
                                          @click="
                                            uploadPutFile(
                                              `${selected.lastname}-${selected.firstname}`,
                                              selected.id,
                                              documentPut.fileId,
                                              item.id,
                                            )
                                          "
                                        >
                                          <v-icon left>mdi-file-plus</v-icon>
                                          Ajouter le document
                                        </v-btn>
                                      </v-card-actions>
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>

                            <!-- <v-card flat>
                              <v-card-title>
                                <span class="text-h5"
                                  >Ajout d'un document manquant à la GED de {{ selected.completeName }}</span
                                >
                              </v-card-title>
                              <v-divider></v-divider>

                              <v-card-text class="pa-5" v-if="documentPut.data != null">
                                <v-row>
                                  <v-col cols="12" sm="6" md="12">
                                    <v-card
                                      flat
                                      v-if="!!documentPut.data"
                                      class="text-center align-center justify-center"
                                    >
                                      <v-row class="pa-5">
                                        <v-col cols="12" md="5">
                                          <v-text-field
                                            @input="replaceSpacesWithUnderscoresPut"
                                            outlined
                                            label="Nom du document"
                                            v-model="documentPut.name"
                                            :value="documentPut.data.name"
                                          ></v-text-field>
                                          <v-select
                                            label="Catégorie du document"
                                            :items="['Personnel', 'Diplomes', 'Documents contractuel']"
                                            outlined
                                            v-model="documentPut.categorie"
                                          ></v-select>
                                        </v-col>
                                        <v-col cols="12" md="1">
                                          <v-divider vertical></v-divider>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                          <v-img
                                            v-if="
                                              documentPut.data.type === 'image\/jpeg' ||
                                              documentPut.data.type === 'image\/png'
                                            "
                                            contain
                                            class="mx-auto"
                                            :src="fileUrlPut"
                                          ></v-img>

                                          <iframe
                                            v-else-if="documentPut.data.type === 'application\/pdf'"
                                            id="lddcVisualisator"
                                            style="width: 100%; height: 500px"
                                            :src="fileUrlPut"
                                          >
                                          </iframe>

                                          <v-card-text class="text-center mt-2">
                                            {{ documentPut.data.name }}
                                          </v-card-text>
                                        </v-col>
                                      </v-row>
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                              <v-card-text v-else>
                                <v-card
                                  link
                                  :class="{ 'file-selected': fileSelected }"
                                  outlined
                                  @dragover.prevent
                                  v-model="documentPut.data"
                                  @drop.prevent="onDropPut($event, document.id, document.name)"
                                  class="drop-zone flex d-flex flex-column justify-center text-center"
                                >
                                  <v-card-text class="EoleBlue--text">
                                    <v-icon size="64" color="EoleBlue">mdi-cloud-upload</v-icon>
                                  </v-card-text>
                                  <v-card-text><small>Extension de fichier accepté : </small> </v-card-text>
                                  <v-card-text class="EoleBlue--text"> .pdf / .png / .jpg </v-card-text>
                                  <v-card-text class="EoleBlue--text"> Glissez et déposez un fichier ici</v-card-text>
                                </v-card>
                              </v-card-text>

                              <v-card-actions>
                                <v-btn
                                  color="EoleYellow"
                                  text
                                  @click="
                                    () => {
                                      dialogUploadPut = !dialogUploadPut
                                      documentPut = {
                                        name: null,
                                        data: null,
                                        categorie: null,
                                        fileId: null,
                                      }
                                    }
                                  "
                                >
                                  Annuler
                                </v-btn>
                                <v-spacer></v-spacer>

                                <v-btn
                                  class="white--text"
                                  color="EoleGreen"
                                  @click="
                                    uploadPutFile(
                                      `${selected.lastname}-${selected.firstname}`,
                                      selected.id,
                                      documentPut.fileId,
                                      item.id,
                                    )
                                  "
                                >
                                  <v-icon left>mdi-file-plus</v-icon>
                                  Ajouter le document
                                </v-btn>
                              </v-card-actions>
                            </v-card> -->
                          </v-dialog>
                        </td>
                        <!-- <td class="pa-5" :colspan="headers.length">
                          <v-row>
                            <v-col
                              cols="12"
                              md="4"
                              v-for="document in item.files"
                              v-bind:key="document.id"
                              class="d-flex flex-column"
                            >
                              <v-card
                                v-if="document.path != null && document.url != null"
                                outlined
                                class="d-flex flex-column"
                              >
                                <v-card-text class="EoleYellow--text text-center">
                                  {{ document.name }}
                                </v-card-text>
                                <v-card-text class="flex-grow-1 align-center">
                                  <iframe
                                    v-if="document.extension === 'pdf'"
                                    style="width: 100%; height: 200px"
                                    :src="`http://${document.url}`"
                                  >
                                  </iframe>
                                  <v-hover
                                    v-else-if="
                                      document.extension === 'png' ||
                                      document.extension === 'jpeg' ||
                                      document.extension === 'jpg'
                                    "
                                    v-slot:default="{ hover }"
                                  >
                                    <v-img
                                      style="cursor: pointer"
                                      :class="{ 'highlighted mx-auto': hover }"
                                      contain
                                      width="200"
                                      max-height="200"
                                      max-width="200"
                                      class="mx-auto"
                                      :src="`http://${document.url}`"
                                      @click="imageView(document.url)"
                                    >
                                      <template v-slot:placeholder>
                                        <v-row class="fill-height" align="center" justify="center">
                                          <v-progress-circular indeterminate color="grey lighten-5">
                                          </v-progress-circular>
                                        </v-row>
                                      </template>
                                      <v-icon v-if="hover" class="image__zoom-icon" large color="white">
                                        mdi-magnify-plus-outline</v-icon
                                      >
                                    </v-img>
                                  </v-hover>
                                </v-card-text>
                                <v-card-actions class="text-center justify-center">
                                  <v-btn
                                    text
                                    @click="downloadFile(document.path, document.name, document.extension)"
                                    download
                                    outlined
                                  >
                                    <v-icon left>mdi-download</v-icon>
                                    Télécharger
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                              <v-card
                                v-else
                                @click="dialogUploadPutClick(document.id, document.name)"
                                outlined
                                class="drop-zone-error flex d-flex flex-column"
                                @dragover.prevent
                                v-model="documentPut.data"
                                @drop.prevent="onDropPut($event, document.id, document.name)"
                                link
                                :class="{ 'file-selected-error': fileSelected }"
                              >
                                <v-card-text class="EoleYellow--text text-center">
                                  {{ document.name }}
                                </v-card-text>
                                <v-card-text class="EoleBlue--text">
                                  <v-icon size="64" color="EoleBlue">mdi-cloud-upload</v-icon>
                                </v-card-text>
                                <v-card-text
                                  ><small>Extension de fichier accepté : .pdf / .png / .jpg </small></v-card-text
                                >
                              </v-card>
                            </v-col>
                            <v-col cols="12" md="3" class="flex d-flex flex-column">
                              <v-card
                                @click="dialogUpload = !dialogUpload"
                                link
                                :class="{ 'file-selected': fileSelected }"
                                outlined
                                @dragover.prevent
                                v-model="document.data"
                                @drop.prevent="onDrop"
                                class="drop-zone flex d-flex flex-column justify-center text-center"
                              >
                                <v-card-text class="EoleBlue--text">
                                  <v-icon size="64" color="EoleBlue">mdi-cloud-upload</v-icon>
                                </v-card-text>
                                <v-card-text><small>Extension de fichier accepté : </small> </v-card-text>
                                <v-card-text class="EoleBlue--text"> .pdf / .png / .jpg</v-card-text>
                                <v-card-text class="EoleBlue--text"> Glissez et déposez un fichier ici </v-card-text>
                              </v-card>
                              <v-dialog v-model="dialogUploadPut" max-width="800px" persistent>
                                <v-card flat>
                                  <v-card-title>
                                    <span class="text-h5"
                                      >Ajout d'un document manquant à la GED de {{ selected.completeName }}</span
                                    >
                                  </v-card-title>
                                  <v-divider></v-divider>

                                  <v-card-text class="pa-5" v-if="documentPut.data != null">
                                    <v-row>
                                      <v-col cols="12" sm="6" md="12">
                                        <v-card
                                          flat
                                          v-if="!!documentPut.data"
                                          class="text-center align-center justify-center"
                                        >
                                          <v-row class="pa-5">
                                            <v-col cols="12" md="5">
                                              <v-text-field
                                                @input="replaceSpacesWithUnderscoresPut"
                                                outlined
                                                label="Nom du document"
                                                v-model="documentPut.name"
                                                :value="documentPut.data.name"
                                              ></v-text-field>
                                              <v-select
                                                label="Catégorie du document"
                                                :items="['Personnel', 'Diplomes', 'Documents contractuel']"
                                                outlined
                                                v-model="documentPut.categorie"
                                              ></v-select>
                                            </v-col>
                                            <v-col cols="12" md="1">
                                              <v-divider vertical></v-divider>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                              <v-img
                                                v-if="
                                                  documentPut.data.type === 'image\/jpeg' ||
                                                  documentPut.data.type === 'image\/png'
                                                "
                                                contain
                                                class="mx-auto"
                                                :src="fileUrlPut"
                                              ></v-img>

                                              <iframe
                                                v-else-if="documentPut.data.type === 'application\/pdf'"
                                                id="lddcVisualisator"
                                                style="width: 100%; height: 500px"
                                                :src="fileUrlPut"
                                              >
                                              </iframe>

                                              <v-card-text class="text-center mt-2">
                                                {{ documentPut.data.name }}
                                              </v-card-text>
                                            </v-col>
                                          </v-row>
                                        </v-card>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                  <v-card-text v-else>
                                    <v-card
                                      link
                                      :class="{ 'file-selected': fileSelected }"
                                      outlined
                                      @dragover.prevent
                                      v-model="documentPut.data"
                                      @drop.prevent="onDropPut($event, document.id, document.name)"
                                      class="drop-zone flex d-flex flex-column justify-center text-center"
                                    >
                                      <v-card-text class="EoleBlue--text">
                                        <v-icon size="64" color="EoleBlue">mdi-cloud-upload</v-icon>
                                      </v-card-text>
                                      <v-card-text><small>Extension de fichier accepté : </small> </v-card-text>
                                      <v-card-text class="EoleBlue--text"> .pdf / .png / .jpg </v-card-text>
                                      <v-card-text class="EoleBlue--text">
                                        Glissez et déposez un fichier ici</v-card-text
                                      >
                                    </v-card>
                                  </v-card-text>

                                  <v-card-actions>
                                    <v-btn
                                      color="EoleYellow"
                                      text
                                      @click="
                                        () => {
                                          dialogUploadPut = !dialogUploadPut
                                          documentPut = {
                                            name: null,
                                            data: null,
                                            categorie: null,
                                            fileId: null,
                                          }
                                        }
                                      "
                                    >
                                      Annuler
                                    </v-btn>
                                    <v-spacer></v-spacer>

                                    <v-btn
                                      class="white--text"
                                      color="EoleGreen"
                                      @click="
                                        uploadPutFile(
                                          `${selected.lastname}-${selected.firstname}`,
                                          selected.id,
                                          documentPut.fileId,
                                          item.id,
                                        )
                                      "
                                    >
                                      <v-icon left>mdi-file-plus</v-icon>
                                      Ajouter le document
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                              <v-dialog v-model="dialogUpload" max-width="800px">
                                <v-card flat>
                                  <v-card-title>
                                    <span class="text-h5"
                                      >Ajout d'un document à la GED de {{ selected.completeName }}</span
                                    >
                                  </v-card-title>
                                  <v-divider></v-divider>

                                  <v-card-text class="pa-5" v-if="document.data != null">
                                    <v-row>
                                      <v-col cols="12" sm="6" md="12">
                                        <v-card
                                          flat
                                          v-if="!!document.data"
                                          class="text-center align-center justify-center"
                                        >
                                          <v-row class="pa-5">
                                            <v-col cols="12" md="5">
                                              <v-text-field
                                                @input="replaceSpacesWithUnderscores"
                                                outlined
                                                label="Nom du document"
                                                v-model="document.name"
                                                :value="document.data.name"
                                              ></v-text-field>
                                              <v-select
                                                label="Catégorie du document"
                                                :items="['Personnel', 'Diplomes', 'Documents contractuel']"
                                                outlined
                                                v-model="document.categorie"
                                              ></v-select>
                                            </v-col>
                                            <v-col cols="12" md="1">
                                              <v-divider vertical></v-divider>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                              <v-img
                                                v-if="
                                                  document.data.type === 'image\/jpeg' ||
                                                  document.data.type === 'image\/png'
                                                "
                                                contain
                                                class="mx-auto"
                                                :src="fileUrl"
                                              ></v-img>

                                              <iframe
                                                v-else-if="document.data.type === 'application\/pdf'"
                                                id="lddcVisualisator"
                                                style="width: 100%; height: 500px"
                                                :src="fileUrl"
                                              >
                                              </iframe>

                                              <v-card-text class="text-center mt-2">
                                                {{ document.data.name }}
                                              </v-card-text>
                                            </v-col>
                                          </v-row>
                                        </v-card>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                  <v-card-text v-else>
                                    <v-card
                                      link
                                      :class="{ 'file-selected': fileSelected }"
                                      outlined
                                      @dragover.prevent
                                      v-model="document.data"
                                      @drop.prevent="onDrop"
                                      class="drop-zone flex d-flex flex-column justify-center text-center"
                                    >
                                      <v-card-text class="EoleBlue--text">
                                        <v-icon size="64" color="EoleBlue">mdi-cloud-upload</v-icon>
                                      </v-card-text>
                                      <v-card-text><small>Extension de fichier accepté : </small> </v-card-text>
                                      <v-card-text class="EoleBlue--text"> .pdf / .png / .jpg </v-card-text>
                                      <v-card-text class="EoleBlue--text">
                                        Glissez et déposez un fichier ici</v-card-text
                                      >
                                    </v-card>
                                  </v-card-text>

                                  <v-card-actions>
                                    <v-btn color="EoleYellow" text @click="dialogUpload = !dialogUpload">
                                      Annuler
                                    </v-btn>
                                    <v-spacer></v-spacer>

                                    <v-btn
                                      class="white--text"
                                      color="EoleGreen"
                                      @click="
                                        uploadPDF(`${selected.lastname}-${selected.firstname}`, selected.id, item.id)
                                      "
                                    >
                                      <v-icon left>mdi-file-plus</v-icon>
                                      Ajouter le document
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </v-col>
                          </v-row>
                        </td> -->
                      </template>
                    </v-data-table>
                  </v-tab-item>
                  <v-tab-item :value="'tab-3'">test </v-tab-item>
                  <v-tab-item :value="'tab-4'">
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="1" class="text-h5 text-center" align="start"></v-col>
                        <v-col cols="12" md="5" class="text-h5 text-center" align="start">{{
                          selected.completeName
                        }}</v-col>
                        <v-col cols="12" md="5" class="text-h5 text-center" align="center">
                          {{
                            selected.services
                              .map(service =>
                                service.manager.map(
                                  manager => `${manager.lastname_manager} ${manager.firstname_manager}`,
                                ),
                              )
                              .join(", ")
                          }}
                        </v-col>
                        <v-col cols="12" md="1" class="text-h5 text-right" align="end">
                          <v-menu left right transition="slide-x-transition" close-on-content-click>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on" style="background-color: white">
                                <i class="fa-solid fa-bars"></i>
                              </v-btn>
                            </template>

                            <v-list>
                              <v-list-item @click="postMaintenance(selected.maintenance)" link>
                                <v-list-item-title> <v-icon left>mdi-save</v-icon>Sauvegarder </v-list-item-title>
                              </v-list-item>
                              <v-list-item @click="addMaintenanceQuestion(selected.maintenance)" link>
                                <v-list-item-title>
                                  <v-icon left>mdi-plus</v-icon>Ajouter une question
                                </v-list-item-title>
                              </v-list-item>

                              <v-divider class="my-2"></v-divider>
                              <v-list-item class="EoleGreen--text" link>
                                <v-list-item-title>
                                  <v-icon left color="EoleGreen">mdi-play</v-icon>Démarrer l'entretien
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-col>
                      </v-row>
                      <v-timeline
                        v-for="(maintenance, index) in selected.maintenance"
                        v-bind:key="index"
                        v-if="selected.maintenance.length > 0"
                      >
                        <draggable v-model="selected.maintenance">
                          <v-timeline-item color="red lighten-2" right>
                            <template v-slot:icon>
                              <v-avatar>
                                <img
                                  :src="
                                    require(`@/assets/images/avatars/picture_profil/${selected.services.map(service =>
                                      service.manager.map(manager => `${manager.firstname_manager.toLowerCase()}`),
                                    )}.png`)
                                  "
                                />
                              </v-avatar>
                            </template>
                            <v-card outlined class="rounded-xl" elevation="3">
                              <v-row class="mr-1">
                                <v-col cols="12" class="text-right">
                                  <v-btn
                                    fab
                                    icon
                                    color="EoleError"
                                    @click="removeMaintenanceQuestion(selected.maintenance, index)"
                                    ><v-icon>mdi-close</v-icon></v-btn
                                  >
                                </v-col>
                              </v-row>
                              <v-card-title class="text-h5">
                                <v-text-field outlined v-model="maintenance.question" dense></v-text-field>
                              </v-card-title>
                              <v-card-text>
                                <v-textarea outlined dense v-model="maintenance.answer_manager"></v-textarea>
                              </v-card-text>
                            </v-card>
                          </v-timeline-item>
                          <v-timeline-item color="red lighten-2" left>
                            <template v-slot:icon>
                              <v-avatar>
                                <img :src="avatarUrl" />
                              </v-avatar>
                            </template>
                            <v-card outlined class="rounded-xl" elevation="3">
                              <v-card-title class="text-h5"> Réponse de {{ selected.firstname }} </v-card-title>
                              <!-- <v-card-text>
                            <v-textarea outlined dense v-model="maintenance.answer_collaborator"></v-textarea>
                          </v-card-text> -->
                              <v-card-text>
                                La réponse n'est pas visible à ce stade. Merci de cliquer sur le bouton "Démarrer
                                l'entretien"
                              </v-card-text>
                            </v-card>
                          </v-timeline-item>
                        </draggable>
                        <!-- <v-timeline-item color="red lighten-2" large right>
                      <template v-slot:icon>
                        <v-avatar>
                          <img
                            :src="
                              require(`@/assets/images/avatars/picture_profil/${selected.services.map(service =>
                                service.manager.map(manager => `${manager.firstname_manager.toLowerCase()}`),
                              )}.png`)
                            "
                          />
                        </v-avatar>
                      </template>
                      <v-card outlined>
                        <v-card-title class="text-h5"> Qu'est-ce qui vous a déplu ?</v-card-title>
                        <v-card-text>
                          <v-textarea outlined dense></v-textarea>
                        </v-card-text>
                      </v-card>
                    </v-timeline-item>
                    <v-timeline-item color="red lighten-2" large left>
                      <template v-slot:icon>
                        <v-avatar>
                          <img :src="avatarUrl" />
                        </v-avatar>
                      </template>
                      <v-card outlined>
                        <v-card-title class="text-h5"> Ce qui m'a déplu :</v-card-title>
                        <v-card-text>
                          <v-textarea outlined dense></v-textarea>
                        </v-card-text>
                      </v-card>
                    </v-timeline-item>
                    <v-timeline-item color="red lighten-2" large right>
                      <template v-slot:icon>
                        <v-avatar>
                          <img
                            :src="
                              require(`@/assets/images/avatars/picture_profil/${selected.services.map(service =>
                                service.manager.map(manager => `${manager.firstname_manager.toLowerCase()}`),
                              )}.png`)
                            "
                          />
                        </v-avatar>
                      </template>
                      <v-card outlined>
                        <v-card-title class="text-h5"> Qu'est-ce qui vous a manqué ?</v-card-title>
                        <v-card-text>
                          <v-textarea outlined dense></v-textarea>
                        </v-card-text>
                      </v-card>
                    </v-timeline-item>
                    <v-timeline-item color="red lighten-2" large left>
                      <template v-slot:icon>
                        <v-avatar>
                          <img :src="avatarUrl" />
                        </v-avatar>
                      </template>
                      <v-card outlined>
                        <v-card-title class="text-h5"> Ce qui m'a manqué : </v-card-title>
                        <v-card-text>
                          <v-textarea outlined dense></v-textarea>
                        </v-card-text>
                      </v-card>
                    </v-timeline-item> -->
                      </v-timeline>
                    </v-card-text>
                  </v-tab-item>
                  <v-tab-item
                    :value="'tab-5'"
                    v-show="selected.contract.length > 0 && selected.contract[0].contract_type_id === 2"
                  >
                    Entretien forfait :)
                    <v-timeline>
                      <v-timeline-item v-for="n in 3" :key="n" color="red lighten-2" large>
                        <template v-slot:opposite>
                          <span>Tus eu perfecto</span>
                        </template>
                        <v-card class="elevation-2">
                          <v-card-title class="text-h5"> Lorem ipsum </v-card-title>
                          <v-card-text>
                            Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet nec ut, sed
                            euismod convenire principes at. Est et nobis iisque percipit, an vim zril disputando
                            voluptatibus, vix an salutandi sententiae.
                          </v-card-text>
                        </v-card>
                      </v-timeline-item>
                    </v-timeline>
                  </v-tab-item>
                  <v-tab-item :value="'tab-7'">
                    <v-data-table
                      class="elevation-1"
                      item-key="id"
                      :loading="loadingFiles"
                      :loading-text="`Chargement des pièces en cours...\n Merci de patienter`"
                      :page.sync="page"
                      :items-per-page="10"
                      :headers="trainingHeaders"
                      :items="selected.training"
                      hide-default-footer
                      @page-count="pageCount = $event"
                      :single-expand="singleExpand"
                      :expanded.sync="expanded"
                      show-expand
                      :footer-props="{
                        'items-per-page-text': 'produits par page(s)',
                      }"
                    >
                      <template v-slot:no-data>
                        <v-alert color="error" text class="ma-2">
                          <div class="d-flex align-start">
                            <v-icon color="error"> mdi-alert-circle-outline </v-icon>

                            <div class="ms-3">
                              <p class="text-base font-weight-medium mb-1">
                                Impossible d'accéder aux données. Merci de réessayer ultérieurement.
                              </p>
                            </div>
                          </div>
                        </v-alert>
                        <v-btn color="primary" class="mb-2">Réessayer</v-btn>
                      </template>

                      <template v-slot:expanded-item="{ headers, item }">
                        <v-dialog v-model="dialogUploadDocument" max-width="800px">
                          <v-card flat>
                            <v-card-title>
                              <span class="text-h5">Import d'un fichier</span>
                            </v-card-title>
                            <v-divider></v-divider>

                            <v-card-text class="pa-5">
                              <v-row>
                                <v-col cols="12" sm="6" md="12">
                                  <v-card flat class="text-center align-center justify-center">
                                    <v-row class="pa-5">
                                      <v-col cols="12" md="12">
                                        <v-row>
                                          <v-col cols="12" md="6">
                                            <v-text-field
                                              dense
                                              outlined
                                              label="Nom du document"
                                              v-model="document.name"
                                            ></v-text-field>
                                            <v-text-field
                                              dense
                                              prepend-inner-icon="mdi-clock"
                                              outlined
                                              type="time"
                                              v-model="document.time_passed"
                                              label="Durée de la formation"
                                            ></v-text-field>
                                          </v-col>
                                          <v-col cols="12" md="6">
                                            <div>
                                              <span v-if="document === null && fileUrl === null"
                                                >Aucun document n'est pour le moment disponible.</span
                                              >
                                              <v-img
                                                v-else-if="
                                                  (document != null &&
                                                    document.data != null &&
                                                    document.data.type === 'image/jpeg') ||
                                                  document.data.type === 'image/png'
                                                "
                                                contain
                                                class="mx-auto"
                                                :src="fileUrl"
                                              ></v-img>
                                              <iframe
                                                v-else-if="
                                                  document != null &&
                                                  document.data != null &&
                                                  document.data.type === 'application/pdf'
                                                "
                                                id="lddcVisualisator"
                                                style="width: 100%; height: 500px"
                                                :src="fileUrl"
                                              ></iframe>

                                              <v-row
                                                align="center"
                                                v-else-if="
                                                  document != null &&
                                                  document.data != null &&
                                                  document.data.type ===
                                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                                "
                                                style="height: 225px"
                                              >
                                                <v-col cols="12" md="12">
                                                  <i class="fa-regular fa-file-word EoleBlue--text fa-8x"></i>
                                                </v-col>
                                              </v-row>
                                            </div>

                                            <v-card-text v-if="document.url != null">
                                              <v-row align="center"> </v-row>
                                            </v-card-text>
                                          </v-col>
                                        </v-row>
                                      </v-col>
                                    </v-row>
                                  </v-card>
                                </v-col>
                              </v-row>
                            </v-card-text>

                            <v-card-actions>
                              <v-btn
                                color="EoleYellow"
                                text
                                @click="
                                  () => {
                                    dialogUploadDocument = !dialogUploadDocument
                                    document = {
                                      name: '',
                                      data: {
                                        name: null,
                                        type: null,
                                      },
                                    }
                                  }
                                "
                              >
                                Annuler
                              </v-btn>
                              <v-spacer></v-spacer>
                              <v-btn @click="postAccountTraining(item)" color="EoleGreen" class="white--text"
                                >Ajouter un nouveau document
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>

                        <td class="pa-5" :colspan="headers.length">
                          <v-row>
                            <v-col
                              cols="12"
                              md="3"
                              v-for="(document, index) in item.files"
                              v-bind:key="document.id"
                              class="d-flex"
                            >
                              <v-card outlined elevation="3" class="rounded-xl flex-fill flex d-flex flex-column">
                                <v-card-text class="flex-grow-1">
                                  <v-row>
                                    <v-col cols="12" md="6" class="text-left">
                                      <v-chip color="EoleGreen" outlined>
                                        <v-icon left>mdi-clock-outline</v-icon> {{ document.hour }}:{{
                                          document.minute
                                        }}
                                      </v-chip>
                                    </v-col>
                                    <v-col cols="12" md="6" class="text-right">
                                      <v-menu right transition="slide-x-transition" close-on-content-click>
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            style=""
                                            class="justify-center text-center"
                                          >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                          </v-btn>
                                        </template>

                                        <v-list>
                                          <v-list-item :href="`${document.url}`" target="_blank">
                                            <v-list-item-title>
                                              <div><v-icon left>mdi-download</v-icon>Télécharger</div>
                                            </v-list-item-title>
                                          </v-list-item>
                                          <v-list-item>
                                            <v-list-item-title>
                                              <div><v-icon left>mdi-pencil</v-icon>Modifier</div>
                                            </v-list-item-title>
                                          </v-list-item>

                                          <v-list-item
                                            color="EoleError"
                                            link
                                            @click="deleteFileTraining(item, index)"
                                            target="blank"
                                          >
                                            <v-list-item-title
                                              ><v-icon color="EoleError" left>mdi-delete</v-icon
                                              >Supprimer</v-list-item-title
                                            >
                                          </v-list-item>
                                        </v-list>
                                      </v-menu>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                                <div v-if="document.url != null" class="flex-grow-1">
                                  <v-card-text>
                                    <v-img
                                      v-if="document.extension === 'jpg' || document.extension === 'png'"
                                      class=""
                                      contain
                                      max-width="200"
                                      :src="`${document.url}`"
                                    ></v-img>

                                    <iframe
                                      v-else-if="document.extension === 'pdf'"
                                      style="width: 100%; height: 200px"
                                      :src="`${document.url}`"
                                    >
                                    </iframe>
                                  </v-card-text>
                                </div>
                                <v-card-text class="align-center">
                                  {{ document.name }}
                                </v-card-text>
                                <v-card-actions class="d-flex justify-center">
                                  <v-row class="justify-center">
                                    <v-col cols="12" md="12" class="text-center">
                                      <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-icon v-bind="attrs" v-on="on" right size="32">mdi-information</v-icon>
                                        </template>
                                        <span> Document importé le : {{}}</span>
                                      </v-tooltip>
                                    </v-col>
                                  </v-row>
                                </v-card-actions>
                              </v-card>
                            </v-col>
                            <v-col cols="12" md="3" class="flex d-flex flex-column">
                              <v-card
                                outlined
                                @click="uploadNewTraining(item)"
                                height="200"
                                class="text-center justify-center flex d-flex flex-column rounded-xl"
                                elevation="3"
                              >
                                <v-row align-content="center">
                                  <v-col cols="12" md="12" align-self="center">
                                    <v-card-text class="align-center">
                                      <v-icon size="64" color="EoleBlue">mdi-plus-circle</v-icon>
                                    </v-card-text>
                                    <v-card-text> Ajouter un nouvel élèment de formation</v-card-text>
                                  </v-col>
                                </v-row>
                              </v-card>
                            </v-col>
                          </v-row>
                        </td>
                      </template>

                      <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip v-bind="attrs" v-on="on">Heure passée : {{ item.total_hours_display }}/30h </v-chip>
                          </template>
                          <span>Heure manquante avant le 31/12 {{ item.time_missing }}</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-tab-item>
                  <v-tab-item :value="'tab-8'">
                    <v-data-table
                      :expanded.sync="expandedCost"
                      single-expand
                      show-expand
                      :headers="headersCosts"
                      :items="selected.costs"
                      item-key="id"
                      class="elevation-1"
                      :items-per-page="-1"
                      disable-pagination
                      hide-default-footer
                    >
                      <template v-slot:expanded-item="{ headers, item }">
                        <td class="pa-5" :colspan="headers.length">
                          <v-row v-if="item.documents.length > 0">
                            <v-col
                              cols="12"
                              md="4"
                              v-for="(document, indexDocument) in item.documents"
                              v-bind:key="indexDocument"
                            >
                              <v-card outlined elevation="3" class="rounded-xl">
                                <v-card-title>{{ document.name }}</v-card-title>
                                <v-card-text v-if="document.type === 'application/pdf'">
                                  <iframe style="height: 300px" :src="document.url"></iframe>
                                </v-card-text>
                                <v-card-text
                                  v-else-if="document.type === 'image/png' || document.type === 'image/jpeg'"
                                >
                                  <v-img :src="document.url" max-width="300px"></v-img>
                                </v-card-text>
                              </v-card>
                            </v-col>
                          </v-row>
                          <v-row v-else> Aucun document disponible </v-row>
                        </td>
                      </template>

                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-toolbar-title
                            style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
                          >
                            FRAIS DE L'ENTREPRISE</v-toolbar-title
                          >
                          <v-spacer></v-spacer>
                          <v-menu right transition="slide-x-transition" close-on-content-click>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>

                            <v-list>
                              <v-list-item>
                                <v-list-item-title> </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-toolbar>
                      </template>
                      <template v-slot:[`item.designation`]="{ item }"> {{ item.designation }}</template>
                      <template v-slot:[`item.informations`]="{ item }">
                        <ul v-if="item.data != null" class="text-left">
                          <li>
                            Marque du véhicule : <strong>{{ item.data[0].vehicleBrand }}</strong>
                          </li>
                          <li>
                            Modèle du véhicule : <strong>{{ item.data[0].vehicleModel }}</strong>
                          </li>
                          <li>
                            Puissance du véhicule : <strong>{{ item.data[0].powerTax }}cv</strong>
                          </li>
                          <li>
                            Barème gouvernementale pris en compte :
                            <strong>{{ item.data[0].scale }}€/km</strong>
                          </li>
                          <li>
                            Nombre de kilométres aller-retour :
                            <strong>{{ item.data[0].kilometers }}kms</strong>
                          </li>
                        </ul>
                      </template>

                      <template v-slot:[`item.price`]="{ item }">
                        <v-chip v-if="item.price != 0"> {{ item.price }}€</v-chip>
                        <v-chip v-else class="text-decoration-line-through"> {{ item.price }}€</v-chip>
                      </template>
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-menu right transition="slide-x-transition" close-on-content-click>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" style="" class="justify-center text-center">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item @click="editItem(item)">
                              <v-list-item-title>
                                <div><v-icon left>mdi-plus</v-icon>Modifier</div>
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item
                              color="EoleError"
                              link
                              @click="deleteService(item.id_service_manager)"
                              target="blank"
                            >
                              <v-list-item-title>
                                <v-icon color="EoleError" left>mdi-delete</v-icon>Supprimer
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>

                      <template v-slot:no-data>
                        Aucune note de frais n'est enregistrée pour le moment.
                        <!-- <v-alert color="error" text class="ma-2">
                      <div class="d-flex align-start">
                        <v-icon color="error"> mdi-alert-circle-outline </v-icon>

                        <div class="ms-3">
                          <p class="text-base font-weight-medium mb-1">
                            Impossible d'accéder aux données. Merci de réessayer ultérieurement.
                          </p>
                        </div>
                      </div>
                    </v-alert> -->
                      </template>
                    </v-data-table>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </v-card>
          </v-card>
        </v-scroll-y-transition>
      </v-col>
    </v-row>
    <v-row v-else>
      <office-candidate></office-candidate>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue"
import Toast from "vue-toastification"
// Import the CSS or use your own!
import "vue-toastification/dist/index.css"
import config from "@/views/config/config"
import appbar from "@/layouts/Content.vue"
import emailjs from "@emailjs/browser"
import { init } from "@emailjs/browser"
init("user_gUunL4KW2ImPaGngWHYhP")
// Import component
import Loading from "vue-loading-overlay"
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css"
import CustomeLoader from "@/layouts/components/LoaderCustom.vue"

import moment from "moment"
moment.locale("fr")
import FileSaver from "file-saver"
import JSZip from "jszip"
import { saveAs } from "file-saver"

import OfficeCandidate from "./OfficeCandidate.vue"

Vue.use(Toast, {
  maxToasts: 2,
  newestOnTop: true,
})
import imageViewer from "vue-image-viewer"

import * as XLSX from "xlsx"

Vue.use(imageViewer)

import draggable from "vuedraggable"

const pause = ms => new Promise(resolve => setTimeout(resolve, ms))

export default {
  data: () => ({
    filterCollaborator: "",
    lastMonth: moment().subtract(1, "month").endOf("month").format("DD/MM/YYYY"),
    headersFiles: [
      {
        text: "Nom du fichier",
        align: "left",
        sortable: true,
        value: "name",
      },
      {
        text: "Date de téléversement",
        value: "format_date_upload",
      },
      {
        text: "Actions",
        value: "actions",
      },
    ],
    dialogRemainingLastYear: false,
    dialogCompensatoryCurrentYear: false,
    dialogRemainingCurrentYear: false,
    searchEtablishment: null,
    agency: null,
    editedRemaining: "",
    dialogEditRemaining: false,
    lastYear: moment().subtract(1, "year").format("YYYY"),
    currentYear: moment().format("YYYY"),
    nextYear: moment().add(1, "year").format("YYYY"),
    showCandidate: false,
    headersHoliday: [
      {
        text: "",
        value: "name",
      },
      {
        text: moment().subtract(1, "year").format("YYYY"),
        value: "lastYear",
      },
      {
        text: moment().format("YYYY"),
        value: "currentYear",
      },
    ],
    nowFormat: moment().format("Do MMMM YYYY"),
    dialogUploadDocument: false,
    searchCity: null,
    cityItems: [],
    searchCityProfessionnal: [],
    cityItemsProfessionnal: [],
    stepperAccount: 0,
    expandedCost: [],
    now: moment().format("MMM YYYY"),
    loadingActionCampain: false,
    loadingActionZip: false,
    headersCosts: [
      {
        text: "Catégorie de frais",
        align: "start",
        sortable: true,
        value: "designation",
      },
      {
        text: "Informations complémentaires",
        value: "informations",
      },
      {
        text: "Montant (en €)",
        value: "price",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    desactivateData: {
      idAccountLeaveReason: null,
      dateApproval: null,
      dateAgreementApproval: null,
      dateEnd: null,
    },
    test: [
      {
        name: "Complétude des données",
      },
      {
        name: "information personnelle",
      },
      {
        name: "information familliale",
      },
    ],
    reasonLeave: [
      {
        id: 1,
        designation: "Licenciement",
      },
      {
        id: 2,
        designation: "Licenciement économique",
      },
      {
        id: 3,
        designation: "Licenciement pour faute",
      },
      {
        id: 4,
        designation: "Licenciement pour faute lourde",
      },
      {
        id: 5,
        designation: "Rupture conventionnelle",
      },
      {
        id: 6,
        designation: "Démission",
      },
      {
        id: 7,
        designation: "Fin de contrat",
      },
    ],
    expanded: [],
    singleExpand: true,
    page: 1,
    pageCount: 0,
    categoriesHeaders: [
      {
        text: "Catégorie",
        align: "start",
        sortable: true,
        value: "designation",
      },
      {
        text: "Conformité",
        value: "files.length",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    trainingHeaders: [
      {
        text: "Année",
        align: "start",
        sortable: true,
        value: "year",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    categoriesInformationHeaders: [
      {
        text: "",
        align: "start",
        sortable: true,
        value: "name",
      },

      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    documentPutId: null,
    documentPutName: null,
    dialogUploadPut: false,
    fileUrl: null,
    fileUrlPut: null,
    fileSelected: true,
    document: {
      name: null,
      data: {
        name: null,
        type: null,
      },
      categorie: null,
    },
    documentPut: {
      name: null,
      data: {
        name: null,
        type: null,
      },
      categorie: null,
      fileId: null,
    },
    dialogUpload: false,
    pdf: null,
    avatarUrlMini: null,
    avatarUrl: null,
    imgError: false,
    editedItemContract: {
      idTypeContract: null,
      idTypeEmployment: null,
      idTypeJob: null,
      idTypeQualification: null,
    },
    dialogAddContractToAccount: false,
    typeEmployments: [],
    typeJobs: [],
    typeContracts: [],
    typeQualifications: [],
    services: [],
    showPhone: false,
    showEmail: false,
    missingData: [],
    showInformations: false,
    showHR: false,
    showInformatical: false,
    showAnnual: false,
    tab: null,
    HRfilesAvailableCount: 0,
    HRfilesNotAvailableCount: 0,
    HRfiles: [
      {
        id: 0,
        name: "Pièce d'identité en cours de validité",
        available: false,
        loading: false,
      },
      {
        id: 1,
        name: "Relevé d'identité bancaire",
        available: false,
        loading: false,
      },
      {
        id: 2,
        name: "Attestation de sécurité sociale",
        available: true,
        loading: false,
      },
      {
        id: 3,
        name: "Décision unilatérale de l'employeur santé",
        available: false,
        loading: false,
      },
      {
        id: 4,
        name: "Décision unilatérale de l'employeur prévoyance",
        available: false,
        loading: false,
      },
      {
        id: 5,
        name: "Notice d'information santé",
        available: false,
        loading: false,
      },
      {
        id: 6,
        name: "Notice d'information prévoyance",
        available: false,
        loading: false,
      },
      {
        id: 7,
        name: "Contrat de travail",
        available: true,
        loading: false,
      },

      {
        id: 8,
        name: "Attestation d'honorabilité",
        available: false,
        loading: false,
      },
      {
        id: 9,
        name: "Formations",
        available: false,
        loading: false,
      },
      {
        id: 10,
        name: "Charte informatique",
        available: false,
        loading: false,
      },
    ],
    imagesViews: [],
    itemsInformatical: [
      {
        id: 0,
        name: "Souris",
        quantity: 4,
      },
      {
        id: 1,
        name: "Ordinateur fixe",
        quantity: 1,
      },
      {
        id: 2,
        name: "Ordinateur portable",
        quantity: 1,
      },
      {
        id: 3,
        name: "Écran",
        quantity: 1,
      },
      {
        id: 4,
        name: "Imprimante",
        quantity: 1,
      },
      {
        id: 5,
        name: "Téléphone fixe",
        quantity: 1,
      },
      {
        id: 6,
        name: "Téléphone portable",
        quantity: 1,
      },
      {
        id: 7,
        name: "Casque",
        quantity: 1,
      },
      {
        id: 8,
        name: "Clé USB",
        quantity: 1,
      },
    ],
    itemInformaticalAttribute: [],
    usersDisabledCount: 0,
    usersEnabledCount: 0,
    usersUnavailableCount: 0,
    usersUnavailable: [],
    score: 0,
    fileCount: 3,
    fileMax: 4,
    saveFile: false,
    fab: false,
    valueHRfiles: 75,
    agencyName: null,
    load: false,
    ipError: false,
    ip: "",
    ipLocation: "",
    /* test purpose*/
    active: [],
    avatar: null,
    open: [],
    users: [],
    usersDisabled: [],
    fallback:
      "https://images.unsplash.com/photo-1560391243-e6431f0fa114?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1567&q=80",
    /* end test purpose */
    passwordValidation: null,
    lastnameErrorMessage: "",
    firstnameErrorMessage: "",
    emailErrorMessage: "",
    phoneNumberErrorMessage: "",
    validityFormPassword: true,
    password: "",
    showPassword: false,
    passwordValidationMessage: null,
    passwordErrorMessage: "",
    basicRules: [v => !!v || "Ce champ est requis"],
    phoneNumberRules: [
      v => !!v || "N° de téléphone requis",
      v => v.length === 10 || "Un numéro de téléphone Français est composé de 10 chiffres",
    ],
    passwordRules: {
      required: value => !!value || "Mot de passe requis.",
      min: v => v.length >= 12 || "Minimum 12 caractères",
    },
    emailRules: [
      v => !!v || "Une adresse électronique est requise",
      v => /.+@.+\..+/.test(v) || "L'adresse électronique doit être valide : example@gmail.com",
    ],
    ipInfo: [],
    snackbar: {
      text: "",
      color: "",
      icon: "",
      enable: false,
    },
    waitTimeout: false,
    date: null,
    time: null,
    page: 1,
    pageCount: 0,
    loading: false,
    fetch: false,
    states: [
      {
        id: 1,
        name: "administrateur",
      },
      {
        id: 2,
        name: "utilisateur",
      },
    ],
    dialog: false,
    dialogDelete: false,
    dialogIp: false,
    headers: [
      {
        text: "",
        value: "phoneNumber",
        sortable: false,
      },
      {
        text: "COLLABORATEUR",
        align: "start",
        sortable: false,
        value: "completeName",
      },
      {
        text: "QUALITÉ",
        value: "statut",
      },
      {
        text: "EMAIL",
        value: "email",
      },
      {
        text: "DERNIÈRE CONNEXION",
        value: "lastConnection",
      },
      {
        text: "ADRESSE IP",
        value: "lastIPConnected",
      },
      {
        text: "ACTIONS",
        value: "actions",
        sortable: false,
      },
    ],

    editedIndex: -1,
    editedItem: {
      id: null,
      firstname: "",
      lastname: "",
      lastConnection: "",
      lastIPConnected: "",
      statut: "utilisateur",
      email: "",
      phoneNumber: "",
      serviceChoose: "",
      idTypeContract: null,
      idTypeEmployment: null,
      idTypeJob: null,
      idTypeQualification: null,
      familyMembers: [],
      idMaritalStatus: 1,
    },
    defaultItem: {
      id: null,
      firstname: "",
      lastname: "",
      lastConnection: "",
      lastIPConnected: "",
      statut: "",
      email: "",
      phoneNumber: "",
      serviceChoose: "",
      idTypeContract: null,
      idTypeEmployment: null,
      idTypeJob: null,
      idTypeQualification: null,
    },
    loadingFiles: false,
    loadedFiles: false,
    loadingUserData: false,
    familyHeaders: [
      {
        text: "Lien de parenté",
        value: "designation",
      },
      {
        text: "Nom",
        value: "lastname",
      },
      {
        text: "Prénom",
        value: "firstname",
      },
    ],
    dialogUploadFile: false,
    fileUrl: null,
    fileCategories: [],
  }),

  computed: {
    getActiveAgencies() {
      return this.agency.etablishment.filter(agency => agency.in_activity == 1)
    },
    familyOptions() {
      if (
        this.editedItem.idMaritalStatus === 3 ||
        this.editedItem.idMaritalStatus === 2 ||
        this.editedItem.idMaritalStatus === 4
      ) {
        return [
          {
            id: 1,
            designation: "Conjoint(e)",
          },
          {
            id: 2,
            designation: "Enfant",
          },
        ]
      } else {
        this.editedItem.familyMembers.forEach((element, index) => {
          if (element.id_account_link === 1) {
            this.editedItem.familyMembers.splice(index, 1)
          }
        })

        return [
          {
            id: 2,
            designation: "Enfant",
          },
        ]
      }
    },
    formTitle() {
      return this.editedIndex === -1
        ? "Nouveau collaborateur"
        : "Modification pour " + this.editedItem.lastname + " " + this.editedItem.firstname
    },
    items() {
      return [
        {
          id: 1,
          name: `Mon équipe en activité (${this.users.length})`,
          children: this.users,
          open: false,
        },
        {
          id: 2,
          name: `Collaborateurs ayant quitté l'entreprise (${this.usersDisabled.length})`,
          children: this.usersDisabled,
          open: false,
        },
      ]
    },
    selected() {
      this.imagesViews = []
      if (!this.active.length) return undefined

      const id = this.active[0]

      //get firstname of user selected

      const user = this.users.find(user => user.id === id)
      const userDisable = this.usersDisabled.find(user => user.id === id)
      this.loadingFiles = true
      if (user) {
        this.getImg(this.users.find(user => user.id === id).lastname, this.users.find(user => user.id === id).firstname)
        Promise.all(
          this.fileCategories.map(category =>
            this.fetchFilesByCategorieId(id, category.id)
              .then(files => {
                category.files = files
                if (!user.categories) {
                  user.categories = []
                }
                user.categories.push(category)

                // // Traitement des images
                // files.forEach(file => {
                //   if (file.extension === "png" || file.extension === "jpg") {
                //     this.imagesViews.push({
                //       name: file.name,
                //       url: `http://${file.url}`,
                //     })
                //   }
                // })

                return category
              })
              .catch(error => {
                console.error("Erreur lors de la récupération des fichiers", error)
              }),
          ),
        )
          .then(() => {
            this.loadingFiles = false
          })
          .catch(error => {
            console.error("Erreur lors du traitement des catégories", error)
          })

        // this.loadingFiles = false
        // refresh component
        this.$forceUpdate()

        // user.categories.forEach(async category => {
        //   // await pause(1000)
        //   category.files.forEach(file => {
        //     if (file.extension === "png" || file.extension === "jpg") {
        //       this.imagesViews.push({
        //         name: file.name,
        //         url: `http://${file.url}`,
        //       })
        //     }
        //   })
        // })
      } else if (userDisable) {
        this.getImg(
          this.usersDisabled.find(user => user.id === id).lastname,
          this.usersDisabled.find(user => user.id === id).firstname,
        )
        this.loadingFiles = true
        Promise.all(
          this.fileCategories.map(category =>
            this.fetchFilesByCategorieId(id, category.id)
              .then(files => {
                category.files = files
                if (!userDisable.categories) {
                  userDisable.categories = []
                }
                userDisable.categories.push(category)

                // // Traitement des images
                // files.forEach(file => {
                //   if (file.extension === "png" || file.extension === "jpg") {
                //     this.imagesViews.push({
                //       name: file.name,
                //       url: `http://${file.url}`,
                //     })
                //   }
                // })

                return category
              })
              .catch(error => {
                console.error("Erreur lors de la récupération des fichiers", error)
              }),
          ),
        )
          .then(() => {
            this.loadingFiles = false
          })
          .catch(error => {
            console.error("Erreur lors du traitement des catégories", error)
          })

        // userDisable.categories.forEach(category => {
        //   category.files.forEach(file => {
        //     if (file.extension === "png" || file.extension === "jpg") {
        //       this.imagesViews.push({
        //         name: file.name,
        //         url: `http://${file.url}`,
        //       })
        //     }
        //   })
        // })
      } else {
        // Handle the case where the user with the specified 'id' was not found
        console.error(`User with id ${id} not found.`)
      }

      return this.users.find(user => user.id === id) || this.usersDisabled.find(user => user.id === id)
    },
  },

  watch: {
    searchEtablishment() {
      this.editedItem.mainWorkplaceStreet = this.agency.etablishment.find(
        element => element.id === this.searchEtablishment,
      ).street
      this.editedItem.mainWorkplaceCity = this.agency.etablishment.find(
        element => element.id === this.searchEtablishment,
      ).city
      this.searchCityProfessionnal = this.agency.etablishment.find(
        element => element.id === this.searchEtablishment,
      ).zip
    },
    searchCityProfessionnal(val) {
      //verify if is number
      if (/^\d{5}$/.test(val)) {
        try {
          fetch("https://geo.api.gouv.fr/communes?codePostal=" + val)
            .then(res => res.clone().json())
            .then(res => {
              this.cityItemsProfessionnal = res.map(item => item.nom)
              this.editedItem.mainWorkplaceZip = val
            })
            .catch(err => {
              console.log(err)
            })

          this.successSelectCity = true
          this.successSelectCityMessage = `Nous avons trouvé ${data.length} villes ou communes ayant comme code postal ${val}`
        } catch (e) {
          this.errorSelectCity = true
          this.errorSelectCityMessage = "Une erreur est survenue"
        }
      } else {
        this.errorSelectCity = true
        this.errorSelectCityMessage = "Le code postal doit être composé de 5 chiffres"
      }
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    searchCity(val) {
      //verify if is number
      if (/^\d{5}$/.test(val)) {
        try {
          fetch("https://geo.api.gouv.fr/communes?codePostal=" + val)
            .then(res => res.clone().json())
            .then(res => {
              this.cityItems = res.map(item => item.nom)
              this.editedItem.zip = val
            })
            .catch(err => {
              console.log(err)
            })

          this.successSelectCity = true
          this.successSelectCityMessage = `Nous avons trouvé ${data.length} villes ou communes ayant comme code postal ${val}`
        } catch (e) {
          this.errorSelectCity = true
          this.errorSelectCityMessage = "Une erreur est survenue"
        }
      } else {
        this.errorSelectCity = true
        this.errorSelectCityMessage = "Le code postal doit être composé de 5 chiffres"
      }
    },
  },

  async created() {
    // document.addEventListener("contextmenu", event => event.preventDefault())
    this.initialize()
    this.agencyName = localStorage.getItem("agency")
    this.HRfilesAvailableCount = this.HRfiles.filter(file => file.available === true).length
    this.fetchServices()
    this.fetchAgency().finally(() => {
      this.fetchFileCategories()
      this.fetchUsers()
        .then(() => {})
        .finally(() => {
          this.open = [1]
        })
      this.fetchType()
    })

    // this.fetchUsers()
    //   .then(() => {})
    //   .finally(() => {
    //     this.open = [1]
    //   })
    // this.fetchType()
  },
  components: {
    appbar,
    Loading,
    CustomeLoader,
    draggable,
    OfficeCandidate,
  },

  methods: {
    filterItems(item) {
      let search = this.filterCollaborator.toLowerCase()
      return [
        {
          id: 1,
          name: `Mon équipe en activité (${this.users.length})`,
          children: item.filter(user => user.completeName.toLowerCase().includes(search)),
          open: false,
        },
        {
          id: 2,
          name: `Collaborateurs ayant quitté l'entreprise (${this.usersDisabled.length})`,
          children: this.usersDisabled.filter(user => user.completeName.toLowerCase().includes(search)),
          open: false,
        },
      ]
    },
    openFileExplorer(fileId, fileName) {
      const input = document.createElement("input")
      input.type = "file"
      input.accept = "image/png, image/jpeg, application/pdf"
      input.addEventListener("change", event => {
        // this.fileSelected = true
        this.dialogUploadPut = true

        this.documentPut.data = event.target.files[0]

        this.fileUrlPut = URL.createObjectURL(event.target.files[0])
        this.documentPut.name = fileName
        // this.documentPut.data.type = event.target.files[0].type
        this.documentPut.fileId = fileId

        console.log(this.documentPut)
        // this.documentPut.data.name = event.target.files[0].name
      })
      input.click()
    },
    getAgency() {
      this.agency.etablishment.forEach(element => {
        if (parseInt(element.id) === parseInt(this.editedItem.etablishment)) {
          this.editedItem.mainWorkplaceStreet = element.street
          this.editedItem.mainWorkplaceCity = element.city
          this.editedItem.mainWorkplaceZip = element.zip
          // Mettez à jour la valeur de mainWorkplaceZip dans le composant v-autocomplete
          this.editedItem.mainWorkplaceZip = element.zip
        }
      })
    },
    async fetchAgency() {
      this.agency = []

      let headers = new Headers()
      let agencyId = localStorage.getItem("agency_id")

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      try {
        let response = await fetch(`${config.apiUri}/agencies/${agencyId}`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        if (data.code === 1) {
          this.agency = data.agency[0]
        }
      } catch (e) {
        console.log(e)
      }
    },
    async saveNewHolidayData(id, remaining, idEmploymentType) {
      let remainPaid = remaining[0]
      let remainRecoveryWorking
      let remainCompensatory

      idEmploymentType == 2 ? (remainCompensatory = remaining[1]) : (remainRecoveryWorking = remaining[1])

      try {
        const formData = new FormData()
        formData.append("id_account", id)
        formData.append("id_employment_type", idEmploymentType)
        formData.append("remain_paid_leave", remainPaid.currentYear)
        formData.append("remain_paid_leave_last_year", remainPaid.lastYear)
        idEmploymentType == 2 ? formData.append("compensatory_package_leave", remainCompensatory.currentYear) : null
        idEmploymentType == 2
          ? formData.append("compensatory_package_leave_last_year", remainCompensatory.lastYear)
          : null
        idEmploymentType != 2 ? formData.append("recovery_working_time_leave", remainRecoveryWorking.currentYear) : null
        idEmploymentType != 2
          ? formData.append("recovery_working_time_leave_last_year", remainRecoveryWorking.lastYear)
          : null
        formData.append("place", "setNewBalance")
        fetch(`${config.apiUri}/accounts/${id}/holidays`, {
          method: "POST",
          body: formData,
        }).then(response => {
          if (response.ok) {
            this.$toast.success("Le système des congés à bien été mis à jour.", {
              timeout: 2000,
              position: "bottom-right",
            })
          } else {
            this.$toast.error("Erreur lors de la sauvegarde des questions de l'entretien")
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    cancelNewHolidayData() {
      this.snack = true
      this.snackColor = "error"
      this.snackText = "Canceled"
    },
    openNewHolidayData() {
      this.snack = true
      this.snackColor = "info"
      this.snackText = "Dialog opened"
    },
    closeNewHolidayData() {},
    removeMaintenanceQuestion(array, index) {
      array.splice(index, 1)
    },
    async postMaintenance(maintenance) {
      const idAccount = this.selected.id
      try {
        const formData = new FormData()
        formData.append("idAccount", idAccount)
        formData.append("maintenance", JSON.stringify(maintenance))
        formData.append("place", "addMaintenance")
        fetch(`${config.apiUri}/accounts/${idAccount}`, {
          method: "POST",
          body: formData,
        }).then(response => {
          if (response.ok) {
            this.$toast.success("Vous avez sauvegardé les questions de l'entretien", {
              timeout: 2000,
              position: "bottom-right",
            })
          } else {
            this.$toast.error("Erreur lors de la sauvegarde des questions de l'entretien")
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    addMaintenanceQuestion(array) {
      array.push({
        question: "",
        answer_manager: "",
        answer_collaborator: "",
      })
    },
    async deleteFileTraining(training, index) {
      const idAccount = this.selected.id
      training.files.splice(index, 1)

      try {
        const formData = new FormData()
        formData.append("idAccount", idAccount)
        formData.append("idTraining", training.id)
        formData.append("files", JSON.stringify(training.files))
        formData.append("place", "deleteFileTraining")
        fetch(`${config.apiUri}/accounts/${idAccount}`, {
          method: "POST",

          body: formData,
        }).then(response => {
          if (response.ok) {
            this.$toast.success("Document retiré avec succès")
            this.initialize()
            this.fetchUsers()
          } else {
            this.$toast.error("Erreur lors de la supression du document")
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    uploadNewTraining(training) {
      try {
        const input = document.createElement("input")
        input.type = "file"
        input.accept =
          "image/png, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        input.addEventListener("change", event => {
          this.document.data = event.target.files[0]
          this.fileUrl = URL.createObjectURL(this.document.data)
          this.dialogUploadDocument = true
          // this.uploadFile(event, type, area, subType)
        })
        input.click()
      } catch (err) {
        console.log(err)
      }
    },
    addLine(array) {
      array.push({
        maritalStatus: null,
        firstname: null,
        lastname: null,
        createClient: true,
      })

      this.$nextTick(() => {
        const inputElement = document.getElementById("input-family-" + (array.length - 1))
        if (inputElement) {
          inputElement.focus()
        }
      })
    },
    removeUrlPrefix(url) {
      // replace the ged url to : C:\xampp\htdocs\documents\

      // after all add http://api.eole.local/api/php/pages/download.php?path= before the url
      return url.replace(`${config.ged}`, `${config.apiUri}/php/pages/download.php?path=C:/xampp/htdocs/documents/`)
    },
    async generateAndDownloadZip() {
      this.loadingActionZip = true
      // Créez une instance JSZip
      const zip = new JSZip()

      // Liste de liens vers les fichiers PDF avec les répertoires souhaités
      let pdfLinks = [
        // Ajoutez ici d'autres liens vers les PDF avec les répertoires souhaités
      ]

      this.users.forEach(user => {
        let csvData = []

        // Générez un fichier XLSX en mémoire
        let wb = XLSX.utils.book_new()

        if (user.costs.length > 0) {
          let total = 0
          user.costs.forEach(cost => {
            csvData.push([cost.designation, cost.price])
            cost.documents.forEach(document => {
              pdfLinks.push({
                // replace all "/" to "\"
                url: this.removeUrlPrefix(document.url),
                pathOnServer: this.removeUrlPrefix(document.url),
                folder: `${user.firstname} ${user.lastname}`,
              })
            })
            total += parseInt(cost.price)
          })
          csvData.push(["Total", total])
          let ws = XLSX.utils.aoa_to_sheet(csvData)
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1")
          let xlsxBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
          })
          zip.file(
            `${user.firstname} ${user.lastname}/compte_frais-${user.firstname} ${user.lastname}.xlsx`,
            xlsxBuffer,
          )
        }
      })

      // Ajoutez le fichier CSV à l'archive ZIP

      // Fonction pour télécharger un fichier PDF avec fetch et l'ajouter à l'archive ZIP dans le répertoire spécifié
      const downloadAndAddToZip = async ({ url, folder }) => {
        const response = await fetch(url)
        const pdfBlob = await response.blob()
        const fileName = url.split("/").pop()
        zip.file(`${folder}/${fileName}`, pdfBlob) // Crée un répertoire et ajoute le fichier dedans
        // ajouter un fichier dans ce repertoire
      }

      // Téléchargez chaque fichier PDF et ajoutez-le à l'archive avec le répertoire correspondant
      await Promise.all(pdfLinks.map(downloadAndAddToZip))

      // Générez l'archive au format blob
      const zipBlob = await zip.generateAsync({
        type: "blob",
      })

      // Utilisez file-saver pour télécharger l'archive
      saveAs(zipBlob, "documents.zip")
      this.loadingActionZip = false
    },

    async fakeAction() {
      this.loadingActionCampain = true

      for (const user of this.users) {
        await new Promise(resolve => setTimeout(resolve, 1100)) // Pause de 4,5 secondes

        try {
          await emailjs.send("service_5p49t7p", "template_p1eamen", {
            to_name: user.firstname,
            to_email: user.email,
          })
        } catch (e) {
          console.log(e)
        }
      }
      this.loadingActionCampain = false
    },
    // async fetchCostByUserId() {
    //   this.users.forEach(async user => {
    //     try {
    //       const response = await fetch(`${config.apiUri}/accounts/${user.id}/costs`, {
    //         method: "GET",
    //       })
    //       const data = await response.json()

    //       user.costs = data.costs
    //     } catch (e) {
    //       console.log(e)
    //     }
    //   })
    // },
    imageView(documentUrl) {
      let index = this.imagesViews.findIndex(image => image.url === `http://${documentUrl}`)

      this.$imageViewer.index(index)
      this.$imageViewer.show()
    },
    dialogUploadPutClick(fileId, fileName) {
      this.dialogUploadPut = true
      this.documentPut.fileId = fileId
      this.documentPut.name = fileName
    },
    // async downloadFile(path) {
    //   try {
    //     const response = await fetch(`http://${path}`, { method: "GET", mode: "no-cors" })
    //     const fileBlob = await response.blob()
    //     const file = new File([fileBlob], "nomDuFichier.png", { type: "image/png" })
    //     FileSaver.saveAs(file)
    //   } catch (err) {
    //     console.error(err)
    //   }
    // },
    async downloadFile(path, documentName, extension) {
      let blobConstruct = null
      let headers = new Headers()

      headers.append("Content-Type", "image/jpeg")
      headers.append("Accept", "image/jpeg")
      headers.append("Origin", "*")

      try {
        const response = await fetch(`${config.apiUri}/php/pages/download.php?path=${path}`, {
          method: "GET",
          headers: headers,
        })

        blobConstruct = await response.blob()

        FileSaver.saveAs(blobConstruct, `${documentName}.${extension}`)
      } catch (e) {
        console.log(e)
      }
    },

    replaceSpacesWithUnderscores() {
      this.document.name = this.document.name.replace(/ /g, "_")
    },
    replaceSpacesWithUnderscoresPut() {
      this.documentPut.name = this.documentPut.name.replace(/ /g, "_")
    },
    renderPdf(document) {
      pdfjs.getDocument(document).then(pdf => {
        pdf.getPage(1).then(page => {
          const scale = 0.5
          const viewport = page.getViewport({
            scale: scale,
          })
          const canvas = this.$refs.canvas
          const context = canvas.getContext("2d")
          canvas.height = viewport.height
          canvas.width = viewport.width
          page.render({
            canvasContext: context,
            viewport: viewport,
          })
        })
      })
    },

    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes"

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
    onDropPut(e, fileId, documentName) {
      e.preventDefault()
      this.documentPut.data = e.dataTransfer.files[0]

      if (this.documentPut.fileId === null && this.documentPut.name === null) {
        this.documentPut.fileId = fileId
        this.documentPut.name = documentName.replace(/ /g, "_")
      }

      if (this.documentPut.fileId != null && this.documentPut.name != null) {
        this.documentPut.name = this.documentPut.name.replace(/ /g, "_")

        let realSize = this.formatBytes(this.documentPut.data.size)

        if (this.documentPut.data.size > 1000000) {
          this.$toast.error(
            `Votre fichier est trop volumineux (${realSize}). \n Veuillez choisir un fichier de moins de 1 Mo.`,
            {
              position: "bottom-right",
              timeout: 3000,
            },
          )
          this.documentPut = {
            name: null,
            data: null,
            categorie: null,
            fileId: null,
          }
        } else {
          switch (this.documentPut.data.type) {
            case "application/pdf":
            case "image/png":
            case "image/jpeg":
              this.fileSelected = true
              this.fileUrlPut = URL.createObjectURL(this.documentPut.data)
              this.dialogUploadPut = true

              break
            default:
              this.documentPut = {
                name: null,
                data: null,
                categorie: null,
                fileId: null,
              }
              this.fileSelected = false
              this.$toast.error(
                `Votre fichier n'est pas au bon format. \n Veuillez choisir un fichier au format .pdf, .png ou .jpeg.`,
                {
                  position: "bottom-right",
                  timeout: 3000,
                },
              )
          }
        }
      }
    },

    onDrop(e) {
      e.preventDefault()
      this.document.data = e.dataTransfer.files[0]
      let realSize = this.formatBytes(this.document.data.size)

      if (this.document.data.size > 1000000) {
        this.$toast.error(
          `Votre fichier est trop volumineux (${realSize}). \n Veuillez choisir un fichier de moins de 1 Mo.`,
          {
            position: "bottom-right",
            timeout: 3000,
          },
        )
        this.document = {
          name: null,
          data: null,
          categorie: null,
        }
      } else {
        switch (this.document.data.type) {
          case "application/pdf":
            this.fileSelected = true
            // this.renderPdf(this.document.data)

            // view pdf in iframe

            this.fileUrl = URL.createObjectURL(this.document.data)
            this.dialogUpload = true

            break
          case "image/png":
          case "image/jpeg":
            this.fileSelected = true
            this.fileUrl = URL.createObjectURL(this.document.data)
            this.dialogUpload = true

            break
          default:
            this.fileSelected = false
            this.$toast.error(
              `Votre fichier n'est pas au bon format. \n Veuillez choisir un fichier au format .pdf, .png ou .jpeg.`,
              {
                position: "bottom-right",
                timeout: 3000,
              },
            )
        }
      }
    },
    async sendEmailLostPassword(email) {
      let response
      try {
        response = await fetch(`${config.apiUri}/php/pages/accounts.php?email=${email}`, {
          method: "GET",
        })

        const data = await response.json()

        if (data.code === 1) {
          this.$toast.success("Vous allez recevoir un email pour changer votre mot de passe d'ici quelques instants.")
          try {
            emailjs.send("service_5p49t7p", "template_v6w11ri", {
              to_name: "test",
              //from_name: email,
              to_email: data.account.email,
              link: `${config.appUri}/auth/login?id=${data.account.id}&token=${data.account.token}`,
            })
          } catch (error) {
            console.log(error)
          }
        } else {
          this.$toast.error("Cet email n'est lié à aucun compte. Veuillez réessayer.")
        }
      } catch (err) {
        console.log(err)
      }
    },
    async uploadPutFile(username, id, fileId, categorieId) {
      const formData = new FormData()
      formData.append("document", this.documentPut.data)
      formData.append("userId", id)
      formData.append("documentName", this.documentPut.name)
      formData.append("username", username.toLowerCase().replace(" ", ""))
      formData.append("fileId", fileId)
      formData.append("method", "PUT")
      formData.append("categorieId", categorieId)
      formData.append("agencyName", localStorage.getItem("agency").toLowerCase())
      formData.append("place", "account")

      try {
        const res = await fetch(`${config.apiUri}/upload`, {
          method: "POST",
          body: formData,
        })
        if (res.ok) {
          this.initialize()
          this.fetchUsers()
          this.dialogUploadPut = false
        } else {
          alert("Erreur lors de l'upload du PDF")
        }
      } catch (err) {
        console.error(err)
        alert("Erreur lors de l'upload du PDF")
      }
    },

    async postAccountTraining(training) {
      let agencyName = localStorage.getItem("agency").toLowerCase().replace(" ", "-")
      let username = this.selected.completeName.toLowerCase().replace(" ", "-")
      let extension = this.document.data.name.split(".").pop()

      training.files.push({
        name: this.document.name,
        url: `${config.ged}/${agencyName}/users/${username}/trainings/${this.document.name}.${extension}`,
        type: this.document.data.type,
        extension: extension,
        hour: this.document.time_passed.split(":")[0],
        minute: this.document.time_passed.split(":")[1],
      })

      let idAccount = this.selected.id
      try {
        const formData = new FormData()
        formData.append("idAccount", idAccount)
        formData.append("idTraining", training.id)
        formData.append("files", JSON.stringify(training.files))
        formData.append("place", "putTraining")
        fetch(`${config.apiUri}/accounts/${idAccount}`, {
          method: "POST",

          body: formData,
        }).then(response => {
          if (response.ok) {
            this.uploadTraining(this.selected.completeName)
            this.$toast.success("Formation ajoutée avec succès")
            this.initialize()
            this.fetchUsers()
          } else {
            this.$toast.error("Erreur lors de l'ajout de la formation")
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    async uploadTraining(username) {
      const formData = new FormData()
      formData.append("document", this.document.data)
      formData.append("documentName", this.document.name)
      formData.append("username", username.toLowerCase().replace(" ", "-"))
      formData.append("agencyName", localStorage.getItem("agency").toLowerCase())
      formData.append("place", "addTraining")

      try {
        const res = await fetch(`${config.apiUri}/upload`, {
          method: "POST",
          body: formData,
        })
        if (res.ok) {
          this.initialize()
          this.fetchUsers()
          this.dialogUpload = false

          this.document = {
            name: "",
            data: {
              name: null,
              type: null,
            },
            categorie: null,
          }
        } else {
          alert("Erreur lors de l'upload du PDF")
        }
      } catch (err) {
        console.error(err)
        alert("Erreur lors de l'upload du PDF")
      }
    },
    onImgError() {
      this.imgError = true
    },

    async uploadPDF(username, id, categorieId) {
      const formData = new FormData()
      formData.append("document", this.document.data)
      formData.append("userId", id)
      formData.append("documentName", this.document.name)
      formData.append("username", username.toLowerCase().replace(" ", ""))
      formData.append("categorieId", categorieId)
      formData.append("agencyName", localStorage.getItem("agency").toLowerCase())
      formData.append("place", "account")

      try {
        const res = await fetch(`${config.apiUri}/upload`, {
          method: "POST",
          body: formData,
        })
        if (res.ok) {
          this.initialize()
          this.fetchUsers()
          this.dialogUpload = false

          this.document = {
            name: "",
            data: null,
            categorie: null,
          }
        } else {
          alert("Erreur lors de l'upload du PDF")
        }
      } catch (err) {
        console.error(err)
        alert("Erreur lors de l'upload du PDF")
      }
    },
    onImgError() {
      this.imgError = true
    },

    // get image in folder asset for avatar and display error if not found
    getImg(lastname, firstname) {
      this.imgError = false

      try {
        let name = `${lastname.toLowerCase()}-${firstname.toLowerCase()}`
        let agency = localStorage.getItem("agency").toLowerCase().replace(/ /g, "_")
        let pictureProfilPng = `${config.ged}/${agency}/users/${name}/profil.png`

        let pictureProfilJpg = `${config.ged}/${agency}/users/${name}/profil.jpg`

        let img = new Image()
        img.onload = () => {
          this.avatarUrl = img.src
        }
        img.onerror = () => {
          this.imgError = true
          this.avatarUrl = null
        }

        // Test pour l'image .png
        img.src = pictureProfilPng

        // Si l'image .png n'est pas disponible, tester pour l'image .jpg
        setTimeout(() => {
          if (this.avatarUrl == null) {
            img.src = pictureProfilJpg
          }
        }, 500)
      } catch (error) {
        this.imgError = true
        this.avatarUrl == null
      }
    },
    async getImg2(lastname, firstname) {
      try {
        const name = `${lastname.toLowerCase().replace(" ", "")}-${firstname.toLowerCase()}`
        const agency = localStorage.getItem("agency").toLowerCase().replace(/ /g, "_")
        const pictureProfilPng = `${config.ged}/${agency}/users/${name}/profil.png`
        const pictureProfilJpg = `${config.ged}/${agency}/users/${name}/profil.jpg`

        // Create a Promise to handle image loading
        const loadImage = src => {
          return new Promise((resolve, reject) => {
            const img = new Image()
            img.onload = () => resolve(img.src)
            img.onerror = () => reject(null) // Failed to load image
            img.src = src
          })
        }

        // Attempt to load the PNG image
        let result = await loadImage(pictureProfilPng)

        // If the PNG image fails to load, try the JPG image
        if (!result) {
          result = await loadImage(pictureProfilJpg)
        }

        return result // Return the URL or null if no image is found
      } catch (error) {
        console.error("Error fetching avatar:", error)
        return null // Return null in case of any errors
      }
    },
    insertFile(documentId) {
      this.HRfiles.forEach(file => {
        if (file.id === documentId) {
          file.loading = true
          setTimeout(() => {
            file.loading = false
            file.available = !file.available
            this.HRfilesAvailableCount = this.HRfiles.filter(file => file.available === true).length
          }, 3000)
        }
      })
    },

    async disabled(data) {
      let headers = new Headers()

      headers.append("Content-Type", "application/json charset=UTF-8")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")
      try {
        let response
        if (data.status === 1) {
          if (this.desactivateData.idAccountLeaveReason === 5) {
            response = await fetch(
              `${config.apiUri}/php/pages/accounts.php?changeStatus=true&idAccount=${data.id}&disabled=${data.status}&dateEnd=${this.desactivateData.dateEnd}&idAccountLeaveReason=${this.desactivateData.idAccountLeaveReason}&dateApproval=${this.desactivateData.dateApproval}&dateAgreementApproval=${this.desactivateData.dateAgreementApproval}`,
              {
                mode: "cors",
                method: "PUT",
                headers: headers,
              },
            )
          } else {
            response = await fetch(
              `${config.apiUri}/php/pages/accounts.php?changeStatus=true&idAccount=${data.id}&disabled=${data.status}&dateEnd=${this.desactivateData.dateEnd}&idAccountLeaveReason=${this.desactivateData.idAccountLeaveReason}`,
              {
                mode: "cors",
                method: "PUT",
                headers: headers,
              },
            )
          }
        } else {
          response = await fetch(
            `${config.apiUri}/php/pages/accounts.php?changeStatus=true&idAccount=${data.id}&disabled=${data.status}`,
            {
              mode: "cors",
              method: "PUT",
              headers: headers,
            },
          )
        }

        if (data.status === 0) {
          this.$toast.success(`Le compte à bien été activé.`, {
            position: "bottom-right",
            timeout: 3000,
          })
        } else {
          this.$toast.success(`Le compte à bien été désactivé.`, {
            position: "bottom-right",
            timeout: 3000,
          })
        }
        this.initialize()
        this.fetchUsers()
      } catch (e) {
        console.log(e)
        this.ipLocation = `Une erreur est survenue.`
      }
    },
    copyClipboardIp() {
      let copyText = document.getElementById("ipInformation")

      try {
        copyText.select()
        navigator.clipboard.writeText(copyText.value)

        this.$toast.success(`Le texte a été copié dans votre presse-papier.`, {
          position: "bottom-right",
          timeout: 3000,
        })
      } catch (e) {
        this.$toast.error(`Impossible de copier le texte dans votre presse-papier.`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async fetchType() {
      let headers = new Headers()

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      await pause(1500)

      try {
        let response = await fetch(`${config.apiUri}/type_contract`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const data = await response.json()

        if (data.code === 1) {
          data.type_employments.forEach(employment => {
            this.typeEmployments.push(employment)
          })
          data.type_contracts.forEach(contract => {
            this.typeContracts.push(contract)
          })
          data.type_jobs.forEach(job => {
            this.typeJobs.push(job)
          })
          data.type_qualifications.forEach(qualification => {
            this.typeQualifications.push(qualification)
          })
        }
      } catch (e) {
        console.log(e)
        this.$toast.error("Impossible d'accéder aux données lié aux comptes.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async fetchServices() {
      this.services = []
      this.nodeChart = []
      this.fetchServicesState = true
      let headers = new Headers()
      let idAgency = localStorage.getItem("agency_id")

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      await pause(1500)

      try {
        let response

        response = await fetch(`${config.apiUri}/agencies/${idAgency}/services`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        if (data.code === 1) {
          data.services.forEach(element => {
            this.services.push({
              id_service: element.id_service_manager,
              name: element.name,
            })
          })
        }
      } catch (e) {
        console.log(e)
        this.$toast.error("Impossible d'accéder aux données lié aux services.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },

    async fetchFileCategories() {
      let headers = new Headers()
      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      await pause(1500)

      try {
        let response = await fetch(`${config.apiUri}/filecategories`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const data = await response.json()

        data.categories.forEach(categorie => {
          this.fileCategories.push(categorie)
        })
      } catch (e) {
        console.log(e)
        this.$toast.error("Impossible d'accéder aux données lié aux comptes.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },

    async fetchFilesByCategorieId(userId, categorieId) {
      let files = []

      let headers = new Headers()
      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      await pause(1500)

      try {
        let response = await fetch(`${config.apiUri}/accounts/${userId}/files/${categorieId}`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const data = await response.json()

        data.forEach(file => {
          files.push(file)
        })
      } catch (e) {
        console.log(e)
        this.$toast.error("Impossible d'accéder aux données lié aux comptes.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }

      return files
    },

    async fetchFiles(userId) {
      let files = []

      let headers = new Headers()
      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      await pause(1500)

      try {
        let response = await fetch(`${config.apiUri}/accounts/${userId}/files`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const data = await response.json()

        data.forEach(file => {
          files.push(file)
        })
      } catch (e) {
        console.log(e)
        this.$toast.error("Impossible d'accéder aux données lié aux comptes.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }

      return files
    },
    countNullFiles(files) {
      let nullFile = 0

      files.forEach(file => {
        if (file.url === null) {
          nullFile++
        }
      })

      return nullFile
    },
    countHR(files, selected) {
      let length = files.length
      let fileAccept = 0

      files.forEach(file => {
        if (file.url != null) {
          fileAccept++
        }
      })

      let result = (fileAccept / length) * 100
      selected.hrFilesComplet = Math.round(result, 2)

      return Math.round(result, 2)
    },
    async fetchIp(ip) {
      this.load = true

      this.ipError = false
      this.ipInfo = []
      const token = "d94b113c642ae8"
      let headers = new Headers()

      headers.append("Content-Type", "application/json charset=UTF-8")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      try {
        let response = await fetch(`https://ipinfo.io/${ip}?token=${token}`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const data = await response.json()

        this.ipInfo = JSON.stringify(data, null, "\t")

        this.ip = data.ip

        if (!!data.city) {
          this.ipLocation = `${data.city}, ${data.region} (${data.country})`
        } else {
          this.ipError = true
          this.ipLocation = "Adresse non géolocalisable"
        }
        this.load = false
      } catch (e) {
        console.log(e)
        this.ipLocation = `Position inconnu`
      }
    },
    validateFormPassword(id) {
      if (this.password.length >= 12 && this.password === this.passwordValidation) {
        if (this.$refs.form.validate()) {
          this.save(id)
        }
      } else {
        this.passwordErrorMessage = "Les mots de passes doivent correspondre"
      }
    },
    getCurrentDate() {
      var today = new Date()
      var date = today.getDate() + "/" + (today.getMonth() + 1) + "/" + today.getFullYear()
      var time = today.getHours() + ":" + today.getMinutes()
      this.date = date
      this.time = time
    },
    formatPhoneNumber(phoneNumberString) {
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "")
      var match = cleaned.match(/^(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/)
      if (match) {
        return match[1] + " " + match[2] + " " + match[3] + " " + match[4] + " " + match[5]
      }
      return null
    },
    async fetchUsers() {
      this.usersUnavailable = []
      this.usersUnavailableCount = 0
      this.usersEnabledCount = 0
      this.usersDisabledCount = 0
      this.loading = true
      this.waitTimeout = true
      this.loadingUserData = true
      const now = moment().format("YYYY-MM-DD")

      // await pause(1500)

      try {
        let headers = new Headers()
        let userId = localStorage.getItem("user_id")

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        let response
        if (localStorage.getItem("master_account_linked") != null) {
          let masterAccountId = localStorage.getItem("master_account_linked")
          response = await fetch(`${config.apiUri}/users/${masterAccountId}/account`, {
            mode: "cors",
            method: "GET",
            headers: headers,
          })
        } else {
          response = await fetch(`${config.apiUri}/users/${userId}/account`, {
            mode: "cors",
            method: "GET",
            headers: headers,
          })
        }

        const data = await response.json()

        this.getCurrentDate()
        localStorage.setItem("update_time", this.date + " " + this.time)

        // Créez un tableau de promesses pour récupérer les avatars de manière asynchrone
        const avatarPromises = data.map(async element => {
          const avatarUrl = await this.getImg2(element.lastname, element.firstname)
          return {
            element,
            avatarUrl,
          }
        })

        // Utilisez Promise.all pour attendre que toutes les promesses d'avatar soient résolues
        const avatarResults = await Promise.all(avatarPromises)

        avatarResults.forEach(({ element, avatarUrl }) => {
          moment(now).diff(moment(element.entry_date).format("YYYY-MM-DD"), "days") >= 6
            ? (element.career = true)
            : (element.career = false)

          element.current_month_costs.forEach(cost => {
            cost.documents.length === 0 ? (cost.documents = []) : (cost.documents = JSON.parse(cost.documents))
            cost.data != null ? (cost.data = JSON.parse(cost.data)) : (cost.data = null)
          })
          element.child = 0

          let remainingArray = []

          if (element.remaining.length > 0) {
            remainingArray = [
              {
                name: "Congés payés",
                lastYear: element.remaining[0].remain_paid_leave_last_year,
                currentYear: element.remaining[0].remain_paid_leave,
              },
            ]
          }

          if (element.contract.length > 0) {
            if (parseInt(element.contract[0].employment_type_id) === 2) {
              remainingArray.push({
                name: "JFC",
                lastYear: element.remaining[0].compensatory_package_leave_last_year,
                currentYear: element.remaining[0].compensatory_package_leave,
              })
            } else {
              remainingArray.push({
                name: "RTT",
                lastYear: element.remaining[0].recovery_working_time_leave_last_year,
                currentYear: element.remaining[0].recovery_working_time_leave,
              })
            }
          } else {
            remainingArray.push({
              name: "RTT",
              lastYear: element.remaining[0].recovery_working_time_leave_last_year,
              currentYear: element.remaining[0].recovery_working_time_leave,
            })
          }

          let hasChildren = false
          let emergencyContact = []
          let lawPartner = {}
          let childs = []
          if (element.family.length > 0) {
            element.family.forEach(member => {
              member.phone_number_formatted = this.formatPhoneNumber(member.phone_number)
              member.id_account_family_link = parseInt(member.id_account_family_link)
              member.is_emergency = parseInt(member.is_emergency)

              if (parseInt(member.id_account_family_link) === 1) {
                lawPartner = member
              }
              if (parseInt(member.id_account_family_link) === 2) {
                childs.push(member)
                element.child++
                hasChildren = true
              }

              if (parseInt(member.is_emergency) === 1) {
                emergencyContact.push(member)
              }
            })
          }

          // element.training.forEach(training => {
          //   training.total_hours = moment("00:00", "HH:mm")
          //   training.files === null ? (training.files = []) : (training.files = JSON.parse(training.files))
          //   training.files.forEach(file => {
          //     training.total_hours.add(parseInt(file.hour), "hours")
          //     console.log(parseInt(file.hour))
          //     // convert "01" to "1"

          //     training.total_hours.add(parseInt(file.minute), "minutes")
          //     training.total_hours_display = moment(training.total_hours).format("HH:mm")
          //   })
          //   // training.time_missing = moment(mandatoryHours.hours())
          //   //   .subtract(training.time_passed, "hours")
          //   //   .add(5, "hours")
          //   //   .format("HH:mm")
          // })
          // console.log(element.maintenance)
          // console.log("elem", element.maintenance.information)

          // find idEtab by city name
          let etablishment = {}
          this.agency.etablishment.forEach(etablishment => {
            if (etablishment.city === element.main_workplace_city) {
              etablishment = {
                id: etablishment.id,
                city: etablishment.city,
              }
            }
          })

          if (element.deductions != null) {
            element.deductions = {
              deducted_from_last_year: parseFloat(element.deductions.deducted_from_last_year),
              deducted_from_current_year: parseFloat(element.deductions.deducted_from_current_year),
              deducted_recovery: parseFloat(element.deductions.deducted_recovery),
              deducted_compensatory: parseFloat(element.deductions.deducted_compensatory),
            }
          }

          // if (!element.vehicle) {
          //   element.vehicle = {
          //     power: "",
          //     brand: "",
          //     model: "",
          //   }
          // }

          element.vehicle != false ? (element.ownVehicle = true) : (element.ownVehicle = false)

          if (parseInt(element.disabled) === 0) {
            this.users.push({
              id: element.id,
              firstname: element.firstname,
              lastname: element.lastname,
              lastConnection: moment(element.connection_date).calendar(), // Aujourd’hui à 11:46
              lastIPConnected: element.last_ip,
              statut: parseInt(element.account_state_id),
              email: element.email,
              phoneNumberProfessional: element.phone_number,
              phoneNumberProfessionalFormatted: this.formatPhoneNumber(element.phone_number),
              completeName: `${element.lastname} ${element.firstname}`,
              agency: element.agency,
              // color: "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0"),
              color: "#001F47",
              initial: element.firstname[0] + element.lastname[0],
              dbl: parseInt(element.disabled),
              services: element.services,
              contract: element.contract,
              serviceChoose: element.services.length > 0 ? element.services[0].id_service_manager : 0,
              idTypeContract: element.contract.length > 0 ? element.contract[0].contract_type_id : 0,
              idTypeEmployment: element.contract.length > 0 ? element.contract[0].employment_type_id : 0,
              idTypeJob: element.contract.length > 0 ? element.contract[0].job_type_id : 0,
              idTypeQualification: element.contract.length > 0 ? element.contract[0].qualification_type_id : 0,
              street: element.street,
              city: element.city,
              zip: element.zip,
              costs: element.current_month_costs,
              avatarUrl: avatarUrl,
              entryDate: element.entry_date,
              entryDateDisplay: moment(element.entry_date).format("Do MMMM YYYY"),
              idLeaveReason: parseInt(element.id_leave_reason),
              leaveReason: element.leave_reason,
              endAgreementApprovalDate: element.end_agreement_approval_date,
              endAgreementApplicationDate: element.end_agreement_application_date,
              endDate: element.end_date,
              endDateDisplay: moment(element.end_date).format("Do MMMM YYYY"),
              familyMembers: element.family,
              childCount: element.child,
              marital_category: element.marital_category,
              idMaritalStatus: parseInt(element.id_marital_category),
              idService: element.services.length > 0 ? element.services[0].id_service_manager : null,
              training: element.training,
              remaining: remainingArray,
              maintenance:
                element.maintenance.information != undefined ? JSON.parse(element.maintenance.information) : [],
              mainWorkplaceStreet: element.main_workplace_street,
              mainWorkplaceCity: element.main_workplace_city,
              mainWorkplaceZip: element.main_workplace_zip,
              personnalEmail: element.personnal_email,
              personnalPhoneNumber: element.personnal_phone_number,
              personnalPhoneNumberFormatted: this.formatPhoneNumber(element.personnal_phone_number),
              idCollectiveAgreement: parseInt(element.id_collective_agreement),
              emergencyContact: emergencyContact,
              lawPartner: lawPartner,
              childs: childs,
              hasChildren: hasChildren,
              is_manager: element.is_manager,
              deductions: element.deductions,
              vehicle: element.vehicle,
              vehiclePower: element.vehicle.power,
              vehicleBrand: element.vehicle.brand,
              vehicleModel: element.vehicle.model,
              isTelework: parseInt(element.is_telework),
              isAffiliatedMutual: parseInt(element.is_affiliated_mutual),
              ownVehicle: element.ownVehicle,
              // etablishment: etablishment,
            })
          } else {
            this.usersDisabled.push({
              id: element.id,
              firstname: element.firstname,
              lastname: element.lastname,
              lastConnection: moment(element.connection_date).calendar(), // Aujourd’hui à 11:46
              lastIPConnected: element.last_ip,
              statut: parseInt(element.account_state_id),
              email: element.email,
              phoneNumber: element.phone_number,
              completeName: `${element.lastname} ${element.firstname}`,
              agency: element.agency,
              // color: "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0"),
              color: "#001F47",
              initial: element.firstname[0] + element.lastname[0],
              dbl: parseInt(element.disabled),
              services: element.services,
              contract: element.contract,
              serviceChoose: element.services.length > 0 ? element.services[0].id_service_manager : 0,
              idTypeContract: element.contract.length > 0 ? element.contract[0].contract_type_id : 0,
              idTypeEmployment: element.contract.length > 0 ? element.contract[0].employment_type_id : 0,
              idTypeJob: element.contract.length > 0 ? element.contract[0].job_type_id : 0,
              idTypeQualification: element.contract.length > 0 ? element.contract[0].qualification_type_id : 0,
              street: element.street,
              city: element.city,
              zip: element.zip,
              costs: element.current_month_costs,
              avatarUrl: avatarUrl,
              entryDate: element.entry_date,
              entryDateDisplay: moment(element.entry_date).format("Do MMMM YYYY"),
              idLeaveReason: parseInt(element.id_leave_reason),
              leaveReason: element.leave_reason,
              endAgreementApprovalDate: element.end_agreement_approval_date,
              endAgreementApplicationDate: element.end_agreement_application_date,
              endDate: element.end_date,
              endDateDisplay: moment(element.end_date).format("Do MMMM YYYY"),
              familyMembers: element.family,
              childCount: element.child,
              marital_category: element.marital_category,
              idMaritalStatus: element.id_marital_category,
              idService: element.services.length > 0 ? element.services[0].id_service_manager : null,
              training: element.training,
              remaining: remainingArray,
              maintenance:
                element.maintenance.information != undefined ? JSON.parse(element.maintenance.information) : [],

              mainWorkplaceStreet: element.main_workplace_street,
              mainWorkplaceCity: element.main_workplace_city,
              mainWorkplaceZip: element.main_workplace_zip,
              personnalEmail: element.personnal_email,
              personnalPhoneNumber: element.personnal_phone_number,
              idCollectiveAgreement: element.id_collective_agreement,
              emergencyContact: emergencyContact,
              lawPartner: lawPartner,
              childs: childs,
              hasChildren: hasChildren,
              is_manager: element.is_manager,
              deductions: element.deductions,
              vehicle: element.vehicle,
              vehiclePower: element.vehicle.power,
              vehicleBrand: element.vehicle.brand,
              vehicleModel: element.vehicle.model,
              isTelework: element.is_telework,
              isAffiliatedMutual: element.is_affiliated_mutual,
              // etablishment: etablishment,
            })
          }
          if (parseInt(element.available) === 0 && parseInt(element.disabled) === 0) {
            this.usersUnavailableCount++
            this.usersUnavailable.push({
              id: element.id,
              firstname: element.firstname,
              lastname: element.lastname,
            })
          }
          parseInt(element.disabled) === 0 ? this.usersEnabledCount++ : this.usersDisabledCount++
        })
        // this.fetchFileCategories()

        if (this.users.length > 0) {
          this.users.forEach(element => {
            let etablishment = this.agency.etablishment.find(
              etablishment => etablishment.city === element.mainWorkplaceCity,
            )

            if (etablishment != undefined) {
              element.etablishment = {
                id: etablishment.id,
                city: etablishment.city,
              }
            }

            // this.fetchFileCategories()
            //   .then(categories => {
            //     categories.forEach(categorie => {
            //       categorie.files = this.fetchFilesByCategorieId(element.id, categorie.id).then(files => {
            //         categorie.files = files
            //       })
            //     })
            //     element.categories = categories
            //   })
            //   .finally(() => {
            //     pause(4000).then(() => {
            //       this.loadedFiles = true
            //       this.loadingFiles = false
            //       this.loading = false
            //     })
            //   })
            //   .catch(e => {
            //     console.log(e)
            //   })

            this.loading = false
            this.fetch = true
            this.waitTimeout = false

            // element.files = this.fetchFiles(element.id).then(files => {
            //   element.files = files
            //   element.hrFilesComplet = this.countHR(element.files)
            // })
            this.usersDisabled.forEach(element => {
              // this.fetchFileCategories()
              //   .then(categories => {
              //     categories.forEach(categorie => {
              //       categorie.files = this.fetchFilesByCategorieId(element.id, categorie.id).then(files => {
              //         categorie.files = files
              //       })
              //     })
              //     element.categories = categories
              //   })
              //   .finally(() => {
              //     pause(4000).then(() => {
              //       this.loadedFiles = true
              //       this.loadingFiles = false
              //       this.loading = false
              //     })
              //   })
              //   .catch(e => {
              //     console.log(e)
              //   })
            })
          })
        } else {
          this.waitTimeout = false

          this.loadingFiles = false
        }

        if (this.usersDisabled.length > 0) {
          this.usersDisabled.forEach(element => {
            let etablishment = this.agency.etablishment.find(
              etablishment => etablishment.city === element.mainWorkplaceCity,
            )

            if (etablishment != undefined) {
              element.etablishment = {
                id: etablishment.id,
                city: etablishment.city,
              }
            }
          })
        }

        this.open = [1]

        // this.loading = false
        // this.fetch = true
        // this.waitTimeout = false

        // this.fetchServices()

        // await pause(3000)

        // this.loadedFiles = true
        // this.loadingFiles = false
        // this.fetchCostByUserId()
      } catch (e) {
        console.log(e)
        this.loading = false
        this.fetch = false
        this.$toast.error("Impossible d'accéder aux données de l'équipe.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },

    getLength() {
      return this.users.length
    },
    initialize() {
      this.users = []
      this.usersDisabled = []
    },
    editedItemDisabled(item) {
      this.editedIndex = this.usersDisabled.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    editItem(item) {
      console.log("Collaborateur", item)
      this.editedIndex = this.users.indexOf(item)
      console.log("Index", this.editedIndex)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm(id) {
      this.users.splice(this.editedIndex, 1)

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        fetch(`${config.apiUri}/accounts/${id}`, {
          mode: "cors",
          method: "DELETE",
          headers: headers,
        })
          .then(res => res.json()) // or res.json()
          .then(() => {
            this.$toast.info(`Le compte a correctement été supprimé.`, {
              position: "bottom-right",
              timeout: 3000,
            })
          })
      } catch (e) {
        this.$toast.error(`Une erreur est survenue.\nImpossible de supprimer le compte`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }

      this.closeDelete()
      this.initialize()

      this.fetchUsers()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    async put(idUser, data) {
      const formData = new FormData()
      formData.append("place", "putAccount")
      formData.append("id", idUser)
      formData.append("lastname", data.lastname)
      formData.append("firstname", data.firstname)
      formData.append("idState", data.grade)
      formData.append("email", data.email)
      formData.append("phoneNumber", data.phoneNumber)
      formData.append("idServiceManager", data.idServiceManager)
      formData.append("idTypeContract", data.idTypeContract)
      formData.append("idTypeJob", data.idTypeJob)
      formData.append("idTypeEmployment", data.idTypeEmployment)
      data.idCollectiveAgreement === 3
        ? formData.append("idTypeQualification", 8)
        : formData.append("idTypeQualification", data.idTypeQualification)
      formData.append("entryDate", data.entryDate)
      formData.append("idService", data.idService)
      formData.append("street", data.street)
      formData.append("city", data.city)
      formData.append("zip", data.zip)
      formData.append("idMaritalStatus", data.idMaritalStatus)
      formData.append("mainWorkplaceZip", data.mainWorkplaceZip)
      formData.append("mainWorkplaceCity", data.mainWorkplaceCity)
      formData.append("mainWorkplaceStreet", data.mainWorkplaceStreet)
      formData.append("personnalPhoneNumber", data.personnalPhoneNumber)
      formData.append("personnalEmail", data.personnalEmail)
      formData.append("idCollectiveAgreement", data.idCollectiveAgreement)
      formData.append("ownVehicle", data.ownVehicle || data.ownVehicle === 1 ? 1 : 0)

      if (data.ownVehicle) {
        formData.append("vehicleBrand", data.vehicleBrand)
        formData.append("vehicleModel", data.vehicleModel)
        formData.append("vehiclePower", data.vehiclePower)
      }
      formData.append("isTelework", data.isTelework ? 1 : 0)
      formData.append("isAffiliatedMutual", data.isAffiliatedMutual ? 1 : 0)

      data.familyMembers.length > 0 ? formData.append("familyMembers", JSON.stringify(data.familyMembers)) : null

      try {
        const response = await fetch(`${config.apiUri}/php/pages/accounts.php`, {
          mode: "cors",
          method: "POST",
          body: formData,
        })
        this.$toast.info(`Vous venez de modifier le compte ${data.lastname} ${data.firstname} avec succès.`, {
          position: "bottom-right",
          timeout: 3000,
        })
        this.stepperAccount = 0

        this.initialize()

        this.fetchUsers()
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible de modifier le compte`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },

    sendmail(data) {
      try {
        emailjs.send("service_5p49t7p", "template_w0zk5rq", {
          to_name: data.lastname + " " + data.firstname,
          //from_name: email,
          to_email: data.email,
        })
      } catch (error) {
        console.log(error)
      }
    },

    async postContract(data, idAccount) {
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json charset=UTF-8")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `${config.apiUri}/type_contract?createContract=true&id=${idAccount}&idTypeContract=${data.idTypeContract}&idTypeJob=${data.idTypeJob}&idTypeEmployment=${data.idTypeEmployment}&idTypeQualification=${data.idTypeQualification}`,
          {
            mode: "cors",
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
          },
        )
        this.initialize()
        this.fetchUsers()
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible de configurer le contrat de travail du compte.`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },

    async post(data) {
      try {
        let agencyId = localStorage.getItem("agency_id")
        const formData = new FormData()
        formData.append("place", "addAccount")
        formData.append("lastname", data.lastname)
        formData.append("firstname", data.firstname)
        formData.append("idState", 2)
        formData.append("email", data.email)
        formData.append("phoneNumber", data.phoneNumber)
        formData.append("idMasterAccount", data.idMasterAccount)
        formData.append("password", data.password)
        formData.append("agencyId", agencyId)
        formData.append("idServiceManager", data.idService)
        formData.append("idTypeContract", data.idTypeContract)
        formData.append("idTypeJob", data.idTypeJob)
        formData.append("idTypeEmployment", data.idTypeEmployment)
        data.idCollectiveAgreement === 3
          ? formData.append("idTypeQualification", 8)
          : formData.append("idTypeQualification", data.idTypeQualification)
        formData.append("entryDate", data.entryDate)
        data.serviceChoose != "" ? formData.append("idService", data.idService) : null
        formData.append("street", data.street)
        formData.append("city", data.city)
        formData.append("zip", data.zip)
        formData.append("idMaritalStatus", data.idMaritalStatus)
        formData.append("mainWorkplaceZip", data.mainWorkplaceZip)
        formData.append("mainWorkplaceCity", data.mainWorkplaceCity)
        formData.append("mainWorkplaceStreet", data.mainWorkplaceStreet)
        formData.append("personnalPhoneNumber", data.personnalPhoneNumber)
        formData.append("personnalEmail", data.personnalEmail)
        formData.append("idCollectiveAgreement", data.idCollectiveAgreement)
        formData.append("vehicleBrand", data.vehicleBrand)
        formData.append("vehicleModel", data.vehicleModel)
        formData.append("vehiclePower", data.vehiclePower)
        formData.append("isTelework", data.isTelework ? 1 : 0)
        formData.append("isAffiliatedMutual", data.isAffiliatedMutual ? 1 : 0)

        data.familyMembers.length > 0 ? formData.append("familyMembers", JSON.stringify(data.familyMembers)) : null

        const response = await fetch(`${config.apiUri}/php/pages/accounts.php`, {
          mode: "cors",
          method: "POST",
          body: formData,
        })

        this.sendmail(data)

        this.stepperAccount = 0

        this.$toast.success(
          `Vous avez ajouté un nouveau compte.\n${data.lastname.toUpperCase()} ${data.firstname.toUpperCase()} peut désormais se connecter.`,
          {
            position: "bottom-right",
            timeout: 3000,
          },
        )

        this.initialize()
        this.fetchUsers()
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible d'ajouter un nouveau compte`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },

    save(idUser) {
      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], this.editedItem)
        this.put(idUser, {
          lastname: this.editedItem.lastname,
          firstname: this.editedItem.firstname,
          grade: this.editedItem.statut,
          email: this.editedItem.email,
          phoneNumber: this.editedItem.phoneNumberProfessional,
          idServiceManager: this.editedItem.serviceChoose,
          idTypeContract: this.editedItem.idTypeContract,
          idTypeJob: this.editedItem.idTypeJob,
          idTypeEmployment: this.editedItem.idTypeEmployment,
          idTypeQualification: this.editedItem.idTypeQualification,
          familyMembers: this.editedItem.familyMembers,
          entryDate: this.editedItem.entryDate,
          idService: this.editedItem.idService,
          street: this.editedItem.street,
          city: this.editedItem.city,
          zip: this.editedItem.zip,
          idMaritalStatus: this.editedItem.idMaritalStatus,
          mainWorkplaceZip: this.editedItem.mainWorkplaceZip,
          mainWorkplaceCity: this.editedItem.mainWorkplaceCity,
          mainWorkplaceStreet: this.editedItem.mainWorkplaceStreet,
          personnalPhoneNumber: this.editedItem.personnalPhoneNumber,
          personnalEmail: this.editedItem.personnalEmail,
          idCollectiveAgreement: this.editedItem.idCollectiveAgreement,
          vehicleBrand: this.editedItem.vehicleBrand,
          vehicleModel: this.editedItem.vehicleModel,
          vehiclePower: this.editedItem.vehiclePower,
          isTelework: this.editedItem.isTelework,
          isAffiliatedMutual: this.editedItem.isAffiliatedMutual,
          ownVehicle: this.editedItem.ownVehicle,
        })
      } else {
        this.users.push(this.editedItem)
        this.post({
          lastname: this.editedItem.lastname,
          firstname: this.editedItem.firstname,
          email: this.editedItem.email,
          phoneNumber: this.editedItem.phoneNumberProfessional,
          idMasterAccount:
            localStorage.getItem("linked") != null
              ? localStorage.getItem("master_account_linked")
              : localStorage.getItem("user_id"),
          password: this.password,
          idServiceManager: this.editedItem.serviceChoose,
          serviceChoose: this.editedItem.serviceChoose,
          idTypeContract: this.editedItem.idTypeContract,
          idTypeJob: this.editedItem.idTypeJob,
          idTypeEmployment: this.editedItem.idTypeEmployment,
          idTypeQualification: this.editedItem.idTypeQualification,
          familyMembers: this.editedItem.familyMembers,
          entryDate: this.editedItem.entryDate,
          idService: this.editedItem.idService,
          street: this.editedItem.street,
          city: this.editedItem.city,
          zip: this.editedItem.zip,
          idMaritalStatus: this.editedItem.idMaritalStatus,
          mainWorkplaceZip: this.editedItem.mainWorkplaceZip,
          mainWorkplaceCity: this.editedItem.mainWorkplaceCity,
          mainWorkplaceStreet: this.editedItem.mainWorkplaceStreet,
          personnalPhoneNumber: this.editedItem.personnalPhoneNumber,
          personnalEmail: this.editedItem.personnalEmail,
          idCollectiveAgreement: this.editedItem.idCollectiveAgreement,
          vehicleBrand: this.editedItem.vehicleBrand,
          vehicleModel: this.editedItem.vehicleModel,
          vehiclePower: this.editedItem.vehiclePower,
          isTelework: this.editedItem.isTelework,
          isAffiliatedMutual: this.editedItem.isAffiliatedMutual,
          ownVehicle: this.editedItem.ownVehicle,
        })
      }
      this.close()
    },

    upRH() {
      this.valueHRfiles += 10

      if (this.valueHRfiles > 100) {
        this.valueHRfiles = 0
      }
    },
  },
}
</script>
<style lang="scss">
.v-data-table-file tr > td {
  width: calc(100% / 3); /* Remplacez 'nombre_de_colonnes' par le nombre réel de colonnes */
}
.row-name {
  margin-top: 100px !important;
}
.profilSeparator {
  position: absolute;
  padding: 0;
  top: 113px;
}
.fa-icone {
  font-size: 1.2rem;
}

.highlighted:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
}

.highlighted:hover:before {
  visibility: visible;
}

.image__zoom-icon {
  // position icon in middle of image
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
}

.drop-zone {
  /* styles de base */
}

.drop-zone:hover {
  /* styles lorsque la souris est sur la zone de dépôt */
  background-color: #f0f0f0;
  border: 2px dashed #333;
}

.drop-zone.file-selected:hover {
  /* styles lorsque l'utilisateur a déjà sélectionné un fichier et qu'il s'apprête à le déposer */
  background-color: #f0f0f0;
  border: 2px dashed #333;
}

.drop-zone-error {
  /* styles de base */
}

.drop-zone-error:hover {
  /* styles lorsque la souris est sur la zone de dépôt */
  background-color: #f0f0f0;
  border: 2px dashed #c1272d;
}

.drop-zone-error.file-selected:hover {
  /* styles lorsque l'utilisateur a déjà sélectionné un fichier et qu'il s'apprête à le déposer */
  background-color: #f0f0f0;
  border: 2px dashed #333;
}

.profilTabs {
  position: absolute;
  top: 45px;
  left: 115px;
}
// if media screen is more than 1250px width then apply the following styles
@media screen and (min-width: 2000px) {
  .profilBadge {
    position: absolute;
    top: 150px;
    z-index: 0;
    width: 0%;
  }
}

@media screen and (min-width: 1500px) {
  .profilBadge {
    position: absolute;
    top: 45px;
    z-index: 0;
    width: 0%;
  }
}

.profilBannerAccount {
  // background-image: url("../../../../src/assets/images/profils/agence.png");
  height: 100%;
  width: 100%;
}

.no-padding {
  padding: 0 !important;
}

.v-treeview:hover {
  cursor: pointer;
}

.v-progress-circular {
  font-size: 12px;
}

// hide button in list
.no-bullets {
  list-style: none;
  padding: 0;
  margin: 0;
}

.item-explain {
  -webkit-line-clamp: 4 !important;
}
</style>
